import React from 'react';

import {
    BrowserRouter,
    Switch,
    Route,
    Link,
    withRouter
} from "react-router-dom";

import Orders from './Orders'
import Wishlist from './Wishlist'
import PersonalInfo from './PersonalInfo'
import Addresses from './Addresses'
import EditAddress from './EditAddress'
import AddAddress from './AddAddress'

import AuthService from '../AuthService'
import ReactInputMask from 'react-input-mask';


class SigninJuridicalPerson extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            legalname: null,
            cnpj: props.cpfCnpj || '',
            ie: null,
            phone: ''
        }



    }

    componentWillMount() {
        this.updateOnChange();
    }



    handleChange = (field) => {
        return (e) => {

            let state = {};

            switch (field) {
                //case 'nascimento':
                //state[field] = e.target.value.toLocaleString('pt-BR');
                //break;

                case 'cnpj':
                    state[field] = e.target.value.replace(/\D/g, '');
                    break;
                default:
                    state[field] = e.target.value;

            }

            this.setState(state, this.updateOnChange(field));
        }
    }

    updateOnChange = (field) => {
        return () => {
            this.props.onChange(this.state, field)
        }

    }

    render() {
        return (
            <>
                <div class="col-12 text-center">
                    <h2 class="mb-8 ml-0">Dados da Empresa</h2>
                </div>
                <div class='col-12 col-md-6'>
                    <div class="form-group">
                        <label for="accountFirstName">
                            <b style={{ textTransform: 'uppercase', fontSize: '14px' }}>*Razão social</b>
                        </label>
                        <input class="form-control form-control-sm" id="accountFirstName" type="text" onChange={this.handleChange('name')} placeholder="Insira a razão social da empresa" />
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label for="accountCNPJ">
                            <b style={{ textTransform: 'uppercase', fontSize: '14px' }}>*CNPJ</b>
                        </label>
                        <ReactInputMask id="accountCNPJ" type="text" name="cnpj" className="form-control" mask="99.999.999/9999-99" placeholder="Insira o número do CNPJ" defaultValue={this.state.cnpj || ''} onChange={this.handleChange('cnpj')} />
                        {/* <input class="form-control form-control-sm" id="accountCNPJ" type="text" value={this.state.cnpj} onChange={this.handleChange('cnpj')} placeholder="Insira o CNPJ da empresa" /> */}
                    </div>
                </div>

                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label for="accountIE">
                            <b style={{ textTransform: 'uppercase', fontSize: '14px' }}>Inscrição estadual</b>
                        </label>
                        <input class="form-control form-control-sm" id="accountIE" type="text" value={this.state.ie} onChange={this.handleChange('ie')} placeholder="Insira a inscrição estadual da empresa" />
                    </div>
                </div>

                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label for="accountNomeFantasia">
                            <b style={{ textTransform: 'uppercase', fontSize: '14px' }}>*Nome fantasia</b>
                        </label>
                        <input class="form-control form-control-sm" id="accountNomeFantasia" type="text" value={this.state.legalname} onChange={this.handleChange('legalname')} placeholder="Insira o nome fantasia da empresa" />
                    </div>
                </div>

            </>
        );
    }
}

export default SigninJuridicalPerson;