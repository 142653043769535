import React from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";

import PageService from './PageService';

class CustomPage extends React.Component {

	constructor(props) {
		super(props);

    this.state = {
      page: null
    }

	}


  componentDidMount(){

    PageService.getPageByUrl({url: this.props.match.params.page})
    .then(response => {
			this.setState({page: response.data});
		})

  }

  render() {
    return this.state.page && <section className='py-8'>
      <div className='container' dangerouslySetInnerHTML={{__html: this.state.page.content}}></div>
    </section>;
  }
}

export default CustomPage;