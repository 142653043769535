import React from 'react';
import ReactDOM from 'react-dom';

import {
	BrowserRouter,
	Switch,
	Route,
	Link,
	useParams
  } from "react-router-dom";

function ModalWindowClass() {



	this.open = (component , props) => {		

		let _container = document.createElement("div");
		_container.className = "modal fade";
		_container.style.backgroundColor = 'rgba(0,0,0,.4)';
		_container.style.display = 'block';
		//

		document.body.appendChild(_container);
		//document.getElementById('modalwindow_container').appendChild(_container);

		return new Promise((resolve , reject) => {
	
			props.modal_window = {
				close: (data) => {
		
					_container.classList.remove('show');			
			
					setTimeout (() => {
						ReactDOM.unmountComponentAtNode(_container);
						_container.style.display = 'none';
						resolve(data);
					} , 200);
				}
			}

			let element = (
				React.createElement(component , props)				
			);

			ReactDOM.render(element, _container , () => {			
				setTimeout (() => {
					_container.classList.add('show');
				})					
			});
		
		});
	}

}

const ModalWindow = new ModalWindowClass



export default ModalWindow;



