import React from 'react';

import { Router , Link , Redirect } from "react-router-dom";

import CatalogService from './CatalogService'
import Clipboard from '../Clipboard'

class ShareCatalogWindow extends React.Component {


	constructor(props) {
		super(props);

		this.state = {
			catalogs: []
		}
	}


	componentDidMount() {

		CatalogService.getCatalogs(this.props.product_id)
		.then(response => {

			// this.selectedCatalogs = response.data.filter( catalog => catalog.has_item ).map(catalog => catalog.id);

			this.setState({catalogs: response.data})
		})

	}


	handleCloseWindow(e){
		this.props.modal_window.close(false);
	}

	handleCatalogCheckbox(e){

		let catalog_id = parseInt(e.target.value);
		let catalog_index = this.selectedCatalogs.indexOf(catalog_id);

		if(catalog_index === -1){
			this.selectedCatalogs.push(catalog_id);
		}else{
			this.selectedCatalogs.splice(catalog_index, 1);
		}

	}

	handleChangeCatalogName(e){
		this.catalogName = e.target.value;
	}

	handleCreateCatalogClick(){

		CatalogService.createCatalog(this.catalogName)
		.then(response => {
			this.setState({catalogs: response.data})
		});

    }
    
    shareCopyToClipboard = (e) => {
        Clipboard.copy(`https://${process.env.REACT_APP_BASE_URL}/catalog/${this.props.catalog.id}`);
      };
    
        shareWhatsApp(){
        // let message = encodeURIComponent(`Confira nosso catálogo ${this.props.catalog.name}: https://${process.env.REACT_APP_BASE_URL}/catalog/${this.props.catalog.id}`);
        // return `https://api.whatsapp.com/send?text=${message}&source=&data=&app_absent=`;
        }
    
      shareEmail(){
    
      }
    
      shareSMS(){
    
      }


	render() {
		return (
			<div class="modal-dialog modal-dialog-centered modal-x2" role="document">
			  	<div class="modal-content catalog_modal p-7">
				  <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleCloseWindow.bind(this)}>
						<i className="fe fe-x" aria-hidden="true"></i>
				</button>
				<h5 className="text-center">Compartilhar Catálogo</h5>
				<div className="row">
				  	<ul className="catalog_list">
					  	{this.state.catalogs.map(catalog => {
						  	return (<li>{catalog.name} -
                              <a class="btn btn-xs btn-circle btn-light font-size-xxxs text-gray-350 ml-2" href={this.shareWhatsApp()} target="_blank">
                              <img src={process.env.REACT_APP_BASE_URL + "/img/whatsapp.svg"} width="24px" height="24px"></img>                
                            </a>
                            <button class="btn btn-xs btn-circle btn-light font-size-xxxs text-gray-350 ml-2"  onClick={this.shareCopyToClipboard}>
                              <img src={process.env.REACT_APP_BASE_URL + "/img/copy.svg"} width="24px" height="24px"></img> 
                            </button>
                            <button disabled class="btn btn-xs btn-circle btn-light font-size-xxxs text-gray-350 ml-2"  onClick={this.shareSMS}>
                              <img src={process.env.REACT_APP_BASE_URL + "/img/sms.svg"} width="24px" height="24px"></img> 
                            </button>
                            <button disabled class="btn btn-xs btn-circle btn-light font-size-xxxs text-gray-350 ml-2"  onClick={this.shareEmail}>
                              <img src={process.env.REACT_APP_BASE_URL + "/img/email.svg"} width="24px" height="24px"></img> 
                            </button> 
                            </li>)
					  	})}					  
				  	</ul>
				</div>				
				</div>
			</div>	
		)
	}
}

export default ShareCatalogWindow;

