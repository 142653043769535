import React, { useState } from 'react';

import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
	useParams
} from "react-router-dom";

import BudgetService from './BudgetService'
import PageEvent from '../PageEvent'

import ModalWindow from '../ModalWindow'
import FindCustomer from './FindCustomer'

class BudgetSidenav extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			sideNavOpen: false,
			budget: {
				subtotal: 0,
				items: []
			},
			customer: {id: null}
		}

	}

	componentDidMount(){

		PageEvent.addEventListener(PageEvent.events.CART_CLICK , (open) => {
			this.setState({sideNavOpen: open});
		});

		PageEvent.addEventListener(PageEvent.events.BUDGET_UPDATE , (budget) => {
			this.setState({budget : budget});
		});

	}

	handleFindCustomer = () => {
		ModalWindow.open(FindCustomer  , {})	
	}

	handleQuantityChange = (item) => {
		return (e) => {
			let quantity = parseInt(e.target.value);
			BudgetService.updateBudgetProduct({item: item , quantity: quantity});
		}
	}

	handleRemoveItem = (item) => {
		return (e) => {
			BudgetService.removeBudgetProduct({item: item});
		}
	}

	handleCartProductsUpdate = (budget) => {
		this.setState({budget : budget});
	}

	handleCustomerChange = (e) => {
		BudgetService.setCurrentCustomer(e.target.value);
	}

	handleCloseSidenav = (e) => {
		this.setState({sideNavOpen: false});
	}

	render() {
		return (
			<div className={'modal fixed-right fade ' + (this.state.sideNavOpen ? 'show' : '')} id="modalShoppingCart" tabindex="-1" role="dialog" style={{ display: this.state.sideNavOpen ? 'block' : 'none', paddingRight: '17px', backgroundColor: 'rgba(17,17,17,.3)' }} aria-modal="true">
				<div className="modal-dialog modal-dialog-vertical" role="document">

					<div className="modal-content">


						<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleCloseSidenav}>
							<i className="fe fe-x" aria-hidden="true"></i>
						</button>


						<div className="modal-header line-height-fixed font-size-lg">
							<strong className="mx-auto">Orçamento ({this.state.budget.items.length})</strong>
						</div>

						<div className="modal-header line-height-fixed font-size-lg">
							{BudgetService.getCurrentCustomer().external_id} - {BudgetService.getCurrentCustomer().fullname}
							<a onClick={this.handleFindCustomer} href="#">
                				<i class="fe fe-search"></i>
              				</a>
						</div>

						<ul className="list-group list-group-lg list-group-flush">

							{this.state.budget.items.map((product) => {
								return (<li className="list-group-item">
									<div className="row align-items-center">
										<div className="col-4">


											<a href="#">
												<img className="img-fluid" src={process.env.REACT_APP_BASE_URL + (product.grid.cover_medium || '/img/no-picture.jpg')} alt="..." />
											</a>

										</div>
										<div className="col-8">


											<p className="font-size-sm font-weight-bold mb-6">
												<a className="text-body" href="#">{product.code} - {product.name}</a> <br />
												<span className="text-muted">Ref: {product.grid.code}</span><br/>
												<span className="text-muted">Cor: {product.grid.color_name}</span><br/>
												<span className="text-muted">Tam: {product.grid.size_name}</span><br/>
												<span className="text-muted">R$ {product.price.toFixed(2)}</span><br/>
												<span className="text-muted">x{product.quantity} R$ {product.subtotal.toFixed(2)}</span>
											</p>


											<div className="d-flex align-items-center">


												<input type="number" value={product.quantity} onChange={this.handleQuantityChange.bind(this)(product)} style={{width: '80px'}} />


												<a className="font-size-xs ml-auto" href="#!" onClick={this.handleRemoveItem.bind(this)(product)}>
													<i className="fe fe-x"></i> Remover
												</a>

											</div>

										</div>
									</div>
								</li>)
							})}
						</ul>


						<div className="modal-footer line-height-fixed font-size-sm bg-light mt-auto">
							<strong>Subtotal</strong> <strong className="ml-auto">R$ {this.state.budget.subtotal.toFixed(2)}</strong>
						</div>


						<div className="modal-body">
							{ BudgetService.getCurrentCustomer().id ?
								<Link to={`/checkout/${BudgetService.getCurrentCustomer().id}`} className="btn btn-block btn-dark" onClick={this.handleCloseSidenav}>Checkout</Link>
								:
								<span style={{color: 'red', textAlign: 'center'}}>Selecione um cliente para continuar</span>
							}
							{/*<Link to="/cart" className="btn btn-block btn-outline-dark" href="#!">Ver Carrinho</Link>*/}
						</div>

					</div>

					

				</div>
			</div>
		);
	}
}

export default BudgetSidenav;