import api from '../api';


class CatalogServiceClass{


	constructor() {

	}

	deactivateCatalog = (catalog_id) => {
		return api.get(`catalog/deactivate/${catalog_id}`);
	}


	getAvailableCatalogs = () => {
		return api.get(`catalog/available`)
	}

	getCatalogs = (product_id) => {
		return api.get(`catalog/list?product_id=${product_id || ''}`)
	}

	getCatalog = (catalog_id, token) => {
		if (token.t != null){
			return api.get(`catalog/details/${catalog_id}?t=${token.t}`);
		} else {
			return api.get(`catalog/details/${catalog_id}`);
		}
		
	}

	addProduct = (data) => {
		return api.post("catalog/add" , data)
	}

	createCatalog = (name) => {
		return api.post("catalog/create" , {name: name})
	}
}

const CatalogService = new CatalogServiceClass

export default CatalogService