import React, { useState } from 'react';

import './css/theme.css';
import './css/feather.css';
import './css/theme.b2b.css';

import './css/theme.pargan.scss';
import './css/theme.skenn.scss';

import {
    BrowserRouter,
    Switch,
    Route,
    Link,
    useParams
} from "react-router-dom";


import NavBar from './NavBar';
import NavBarMenu from './NavBarMenu';

import Search from './Search';
import AuthForm from './AuthForm';
import Home from './Home';
import HomeTeste from './HomeTeste';
import Cart from './Cart';
import Checkout from './Checkout/Checkout';
import Footer from './Footer';
import PrivacyPoliticy from './PrivacyPoliticy'
import ProductPage from './ProductPage';
import CatalogPage from './CatalogPage';

import Category from './Category';
import CartBudget from './CartBudget';

import Signin from './Account/Signin';
import ForgotPassword from './Account/ForgotPassword'
import RecoverPassword from './Account/RecoverPassword'
import CreatePass from './Account/CreatePass'

import Account from './Account/Account'
import ScrollToTop from './ScrollToTop'

import CustomPage from './CustomPage'
import ToastContainer from './ToastContainer'

import WeekNews from './WeekNews';
import { Toaster } from 'react-hot-toast';

function SigninSuccess() {
    return (
        <section class="pt-7 pb-12">
            <div class="container">
                <div class="row pt-10">
                    <div class="col-12 text-center">
                        <h3>Cadastrado com sucesso!</h3>
                        <h6>Seus dados foram enviados para validação, após esse procedimento você receberá novas instruções em seu email.</h6>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-3">
                        {/* Mensagem */}
                    </div>
                </div>
            </div>
        </section>
    );
}

function App() {


    const offsetLimit = 141;

    const navbar_menu_id = "navbar-fashion";
    const navbar_id = "navbar-main";

    var stickyNavBar = false;//(window.pageYOffset || window.scrollTop) > offsetLimit;
    var prevScroll = 0;




    window.onscroll = (oEvent) => {

        let NavBar = document.getElementById(navbar_id);

        let scrollTop = (window.pageYOffset || window.scrollTop);

        let shouldBeSticky = stickyNavBar ? (scrollTop > 0) : (scrollTop > NavBar.offsetHeight);


        if (shouldBeSticky != stickyNavBar) {
            stickyNavBar = shouldBeSticky;

            let NavBarMenu = document.getElementById(navbar_menu_id);

            if (stickyNavBar) {
                NavBar.style.display = 'none';

                NavBarMenu.style.position = 'sticky';
                NavBarMenu.style.top = '0px';
            } else {

                NavBar.style.display = 'flex';

                NavBarMenu.style.position = 'relative';
                NavBarMenu.style.top = null;

            }

        }

        prevScroll = scrollTop;
    }



    return (
        <BrowserRouter>
            <div className={process.env.REACT_APP_ENVIRONMENT} id="Okay">
                <AuthForm />

                <Toaster
                    position="top-center"
                    reverseOrder={false}
                />

                <NavBar />
                <NavBarMenu />

                <ScrollToTop>
                    <div style={{ minHeight: '680px' }}>
                        <Switch>
                            <Route path="/procurar" component={Search} />
                            <Route path="/categoria/:category_id" component={Category} />
                            <Route path="/produto/:product_id" component={ProductPage} />
                            <Route path="/cart" component={Cart} />
                            <Route path="/checkout" component={Checkout} />
                            <Route path="/catalog/:catalog_id" component={CatalogPage} />
                            <Route path="/account/*" component={Account} />
                            <Route path="/novidades" exact component={WeekNews} />
                            <Route path="/page/:page" component={CustomPage} />

                            <Route path="/cadastro" component={Signin} />
                            <Route path="/esqueci-senha" component={ForgotPassword} />
                            <Route path="/recuperar-senha" component={RecoverPassword} />
                            <Route path="/confirmar-senha" component={CreatePass} />
                            <Route path="/sucesso-cadastro" component={SigninSuccess} />
                            <Route path="/home-teste" component={HomeTeste} />
                            <Route path="/" component={Home} />

                        </Switch>
                    </div>
                </ScrollToTop>
                <CartBudget />
                <ToastContainer />
                <PrivacyPoliticy />
                <Footer />
            </div>
        </BrowserRouter>
    );
}

export default App;
