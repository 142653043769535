import React from 'react';

import {
  BrowserRouter,
  Switch,
  Route,
  Link,
} from "react-router-dom";

class Addresses extends React.Component {
  render() {
    return (
      <div class="row">
        <div class="col-12 col-lg-6">

          <div class="card card-lg bg-light mb-8">
            <div class="card-body">

              <h6 class="mb-6">
                Shipping Address
              </h6>

              <p class="text-muted mb-0">
                Daniel Robinson <br />
                3997 Raccoon Run <br />
                Kingston <br />
                45644 <br />
                United States <br />
                6146389574
              </p>

              <div class="card-action card-action-right">

                <Link to="/account/editar-endereco" class="btn btn-xs btn-circle btn-white-primary">
                  <i class="fe fe-edit-2"></i>
                </Link>

                <button class="btn btn-xs btn-circle btn-white-primary">
                  <i class="fe fe-x"></i>
                </button>

              </div>

            </div>
          </div>

        </div>

        <div class="col-12">

          <Link to="/account/adicionar-endereco" class="btn btn-block btn-lg btn-outline-border">
            Adicionar <i class="fe fe-plus"></i>
          </Link>

        </div>

      </div>
    );
  }
}

export default Addresses;