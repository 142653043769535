import React from 'react';
import BudgetService from '../Budget/BudgetService';

import BudgetCard from '../Budget/BudgetCard';

export default class MyCatalogs extends React.Component {
    constructor(props){
        super(props);
    
        this.state = {
          budgets: []
        }
      }

    componentDidMount() {
        this.loadBudgets();
    }

    loadBudgets(){
        BudgetService.getMyBudgets()
        .then(response => {
            this.setState({budgets: response.data});
        });
    }

    onCloseBudget(budget_id){
        
        BudgetService.closeBudget(budget_id)
        .then((response) => {
            this.loadBudgets();
        })
    }

    render() {
        
        if(this.state.budgets.length == 0){
            return <p style={{textAlign: 'center'}}>Você não fez nenhum orçamento.</p>
          }

        return (
            <>
                {
                    this.state.budgets.map((budget) =>{
				        return <BudgetCard budget={budget} onCloseBudget={this.onCloseBudget.bind(this)}/>
			        })
                }
            </>
        );
    }
}