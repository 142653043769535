import { withRouter } from "react-router-dom";
import React from 'react';
import PageEvent from "./PageEvent";

class ScrollToTop extends React.Component {
    componentDidUpdate(prevProps) {
      if (this.props.location !== prevProps.location) {
        window.scrollTo(0, 0);
        let navbar_fashion = document.getElementById('navbar-fashion');
        navbar_fashion.querySelector("button.navbar-toggler").classList.add('collapsed');
        navbar_fashion.querySelector("div#navbarFashionBottomCollapse").classList.remove('show');
      }
    }
  
    render() {
      return this.props.children
    }
  }
  
export default withRouter(ScrollToTop)