import React from 'react';

import {
  BrowserRouter,
  Switch,
  Route,
  Link,
  useParams,
  withRouter
} from "react-router-dom";

import Clipboard from '../Clipboard'

import ConfirmDialog from '../ConfirmDialog'
import ModalWindow from '../ModalWindow'

import CatalogService from '../Catalog/CatalogService'
import ToastService from '../ToastService'

class CatalogCard extends React.Component {

	constructor(props) {
      super(props);             

	}

  componentWillMount(){


  }

  shareCopyToClipboard = (e) => {
    Clipboard.copy(`${process.env.REACT_APP_BASE_URL}/catalog/${this.props.catalog.id}?t=${this.props.catalog.share_code + 'l'}`);
    ToastService.sendMessage('Link Copiado para a Área de Transferencia' , null , 5000);
  };

  shareWhatsApp(){
    let message = encodeURIComponent(`Confira nosso catálogo ${this.props.catalog.name}: ${process.env.REACT_APP_BASE_URL}/catalog/${this.props.catalog.id}?t=${this.props.catalog.share_code + 'w'}`);
    return `https://api.whatsapp.com/send?text=${message}&source=&data=&app_absent=`;
  }

  shareEmail(){

  }

  shareSMS(){

  }

  deleteCatalog = catalogId => {
    return () => {
      ModalWindow.open(ConfirmDialog  , {
        title: 'Confirmar Exclusão',
        message: 'Deseja realmente excluir esse catálogo?',
        onConfirm: () => this.onDeleteConfirm(catalogId)
      })	
    }
  }


  onDeleteConfirm = (catalogId) => {
    CatalogService.deactivateCatalog(catalogId)
    .then(response => {
      this.props.onDelete(catalogId)
    });

  }


  	render(){

      let deleteButton = this.props.catalog.salesman_id == null ? 
      null :
      <div class="col-6 col-lg-3">
        <button class="btn btn-sm btn-block btn-outline-red"  onClick={this.deleteCatalog(this.props.catalog.id)}>
          Excluir
        </button>
      </div> 


    return (
      <div class="card card-lg mb-5 border">
      <div class="card-body pb-0">



        <div class="card card-sm">
          <div class="card-body bg-light">
            <div class="row">
              <div class="col-6 col-lg-3">


                <h6 class="heading-xxxs text-muted">Código</h6>


                <p class="mb-lg-0 font-size-sm font-weight-bold">
                  {this.props.catalog.id}
                </p>


              </div>
              <div class="col-6 col-lg-3">


                <h6 class="heading-xxxs text-muted">Nome:</h6>


                <p class="mb-lg-0 font-size-sm font-weight-bold">{this.props.catalog.name}</p>

              </div>  

              <div class="col-6 col-lg-3">
                <Link class="btn btn-sm btn-block btn-outline-dark"  to={`/catalog/${this.props.catalog.id}`}>
                  Visualizar
                </Link>
              </div>

              {deleteButton}

            </div>
          </div>
        </div>

      </div>
      <div class="card-footer">
        <div class="row align-items-center">

          <div class="pl-8 pr-8 row">
            <p class="mb-0">
              <a class="btn btn-xs font-size-xxxs text-gray-350 ml-2" href={this.shareWhatsApp()} target="_blank">
                <img src={process.env.REACT_APP_BASE_URL + "/icons/whatsapp.svg"} width="24px" height="24px"></img>                
              </a>
              <button class="btn btn-xs font-size-xxxs text-gray-350 ml-2"  onClick={this.shareCopyToClipboard}>
                <img src={process.env.REACT_APP_BASE_URL + "/icons/copy.svg"} width="24px" height="24px"></img> 
              </button>
              <button disabled class="btn btn-xs  font-size-xxxs text-gray-350 ml-2"  onClick={this.shareSMS}>
                <img src={process.env.REACT_APP_BASE_URL + "/icons/chat.svg"} width="24px" height="24px"></img> 
              </button>
              <button disabled class="btn btn-xs font-size-xxxs text-gray-350 ml-2"  onClick={this.shareEmail}>
                <img src={process.env.REACT_APP_BASE_URL + "/icons/email.svg"} width="24px" height="24px"></img> 
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
    );
  }
}

export default CatalogCard;