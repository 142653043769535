import React from 'react';

import HomeProductCard from './HomeProductCard'
import ProductService from './ProductService'

import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'

import Look from './Look'

class HomeLooks extends React.Component {

	constructor(props) {
        super(props);      
        
        this.state = {
            looks: []
        }

    }

    componentDidMount(){


        ProductService.getLooks()
        .then(
			(result) => {            
                this.setState({looks : result.data});
			},
			(error) => {
			  	console.log(error)
			}
        )
    }

    render() {

        if(this.state.looks.length == 0) return null

        return (
            <section class="pb-12 mt-11">                
                <h2 class="mb-4 text-center">Confira nossos Looks!</h2>
                <div class="container">
                    <AliceCarousel items={this.state.looks} buttonsDisabled={true} touchTrackingEnabled={false}>
                        {this.state.looks.map((look, index) => {
                            return (<Look key={index} look={look} />)
                        })}
                    </AliceCarousel>
                </div>
            </section>
        )
    }

}


export default HomeLooks;