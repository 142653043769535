import React from 'react';
import { Link } from "react-router-dom";

import HomeProductCard from './HomeProductCard'
import HomeBanners from './HomeBanners';

class HomeProductSection extends React.Component {

	constructor(props) {
        super(props);              
    }

    componentDidMount(){

    }

    render() {
        const { section, show_price } = this.props;

        return (
            <section class="home__section">
                <div class="container mb-11 pt-lg-10 pt-md-3 pb-10" >
                    <div class="row justify-content-center">
                        <div class="col-12 col-md-10 col-lg-8 col-xl-6">
                            <h2 class="mb-6 text-center">{section.titulo}</h2>
                        </div>
                    </div>
                    {
                        
                        section.banners.length > 0 && <HomeBanners section={section} />
                    }
                    <div class="row">
                        {section.products.map((product, index) => {
                            return <HomeProductCard key={index} product={product} show_price={show_price}/> 
                        })}                                 
                    </div>
                    <div class="row">
                        <div class="col-12">

                            <div class="mt-3 text-center">
                                {
                                    section.link !== null &&
                                    (section.link.indexOf('http') === 0 
                                    ?
                                    <a class="link-underline" href={section.link} target="_blank">Ver mais {section.titulo}</a>
                                    :
                                    <Link class="link-underline" to={section.link}>Ver mais {section.titulo}</Link>
                                    )
                                }
                            </div>

                        </div>
                    </div>
                </div>
            </section>      
        )
    }

}


export default HomeProductSection;