import React from 'react';
import _ from 'lodash'

import AuthService from './AuthService'

import {
	BrowserRouter,
	Switch,
	Route,
	Link,
	useParams
} from "react-router-dom";

class HomeProductCard extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			showOpts: false,
			grid: {},
			cover: {},

			size: null,
			sizes: [],

			colors: [],
			show_price: null
		}
	}

	componentDidMount() {
		this.setupProduct();


	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.product.id !== this.props.product.id) {
			this.setupProduct();
		}
	}


	setupProduct() {
		let first_grid = null;
		let newGrid = [];

		for (var i = 0; i < this.props.product.grid.length; i++) {
			if (this.props.product.grid[i].num_images > 0) {
				newGrid.push(this.props.product.grid[i]);
			}
		}


		//this.props.product.grid = newGrid;
		if (newGrid.length > 0) {
			first_grid = newGrid[0];
		} else {
			first_grid = this.props.product.grid[0];
		}

		// console.log('first_grid' , first_grid , this.props.product)

		this.updateColor(first_grid.color_id);
		this.updateAvailableColors();
	}

	updateAvailableColors() {

		let available_colors = _.uniqBy(this.props.product.grid.map(grid => {
			return { id: grid.color_id, name: grid.color_name, rgb: grid.color_rgb, css: grid.color_css }
		}), 'id');

		this.setState({ colors: available_colors });
	}

	updateColor(color_id) {

		let selected_grid = this.props.product.grid.filter(grid => grid.color_id == color_id)[0]
		this.setState({ grid: selected_grid })

		//Updating Picture based on selected color
		let cover = this.props.product.covers.filter(cover => cover.color_id == color_id)
		if (cover[0]) {
			this.setState({ cover: cover[0].medium });
		} else {
			this.setState({ cover: '/img/no-picture.jpg' });
		}

		//Updating sizes from new selected color
		let available_sizes = this.props.product.grid
			.filter(grid => grid.color_id == selected_grid.color_id)
			.map(grid => {
				return { id: grid.size_id, name: grid.size_name }
			});

		this.setState({ sizes: available_sizes })

	}

	handleColorClick = (color_id) => {
		return () => this.updateColor(color_id)
	}

	productMouseEnter = (e) => this.setState({ showOpts: true })

	productMouseLeave = (e) => this.setState({ showOpts: false })

	handleImageClick(e) {
		this.setState({ showOpts: !this.state.showOpts });
	}

	renderPrice() {
		switch (this.props.show_price) {
			case null:
				return null;

			case true:
				return <a href="javascript:;">{this.props.product.active ? `R$ ${this.props.product.price.toFixed(2)}` : 'Indisponível'}</a>

			case false:
				return <a href="javascript:;" onClick={() => AuthService.requestLogin()}>Ver Preço</a>
		}

	}

	render() {
		return (
			<div class="col-6 col-md-6 col-lg-3" >

				<div class="card mb-7" data-toggle="card-collapse" style={{ overflowY: 'hidden' }} onMouseEnter={this.productMouseEnter} onMouseLeave={this.productMouseLeave}>

					<div class="badge card-badge card-badge-left text-uppercase" style={{ backgroundColor: this.props.product.badge[1] || process.env.REACT_APP_DEFAULT_BADGEBC, color: this.props.product.badge[2] || process.env.REACT_APP_DEFAULT_BADGETC }}>
						{this.props.product.badge[0]}
					</div>

					<Link to={{
						pathname: `/produto/${this.props.product.id}`,
						product: this.props.product,
						look: this.props.look || false
					}} class="card-img flickity-enabled" id="productOneImg" tabindex="0" style={{ minHeight: '23vh', height: '250px' }}>
						<img class="card-img-top" src={process.env.REACT_APP_BASE_URL + this.state.cover} alt="..." style={{ height: '100%', objectFit: 'cover' }} />
					</Link>

					<div class="card-collapse-parent" style={{ transform: this.state.showOpts ? 'translateY(-109px)' : '' }}>

						<div class="card-body px-0 pb-0 bg-white">
							<div class="row no-gutters">
								<div class="col">

									{/*<Link class="d-block font-weight-bold text-body" to={{
							pathname: `/produto/${this.props.product.id}` , 
							product: this.props.product , 
							look: this.props.look || false
						}} >{this.props.product.name.substring(0,19)}</Link> */}

									<a class="d-block font-weight-bold text-body">
										{
											this.props.product.product_name != null ?
												this.props.product.product_name
												:
												this.props.product.name.substring(0, 19)
										}
									</a>

									<a class="font-size-xs text-muted" href="#">
										{this.props.product.category_name}
									</a>

								</div>
								<div class="col-auto">

									<div class="font-size-sm font-weight-bold text-muted">
										{this.renderPrice()}
									</div>

								</div>
							</div>
						</div>


						<div className='card-collapse collapse show'>
							<div class="card-footer px-0 bg-white">
								<form>
									<div class="mb-1">
										{this.state.colors.map((color, index) => {
											return (<div key={index} class="custom-control custom-control-inline custom-control-color">
												<a href="javascript:" className={(color.id == this.state.grid.color_id) ? 'custom-control-label custom-control-label2 custom-control-label-active' : 'custom-control-label custom-control-label2'} onClick={this.handleColorClick(color.id)} style={{ background: color.css, color: 'transparent' }}></a>
											</div>)
										})}


									</div>
									<div>
										{this.state.sizes.map(function (size, index) {
											return (<div key={index} class="custom-control custom-control-inline custom-control-text font-size-xs">
												<input type="radio" id="productOneSizeOne" name="sizeRadio" class="custom-control-input" />
												<label class="custom-control-label" htmlFor="productOneSizeOne">{size.name}</label>
											</div>)
										})}
									</div>
								</form>
							</div>
						</div>

					</div>

				</div>

			</div>
		)
	}


}


export default HomeProductCard;