import api from '../api';

import PageEvent from '../PageEvent'
import ToastService from '../ToastService'

class BudgetServiceClass{



	constructor() {
		this.customers = false;
		
		this.current_customer = {
			id: false
		};

		this.details = {
			subtotal: 0,
			items: []
		};

		if(localStorage.getItem('customer')){
			this.setCurrentCustomer(localStorage.getItem('customer'))
		}
	}

	/** Itens */
	


	getCurrentCustomer = () => {
		return this.current_customer;
	}

	setCurrentCustomer = (customer_id) => {

		this.validateCustomer(customer_id)
		.then((response) => {
			localStorage.setItem('customer' , customer_id);
			this.current_customer = response.data;
			this.updateAllBudgetProducts();
		});

	}

	//teste//
	// unsetCurrentCustomer = () => {
	// 	console.log("unset");
	// 	this.current_customer = {};
	// 	localStorage.setItem('customer' , '');
	// 	this.updateAllBudgetProducts();

	// }


	closeBudget = (budget_id) => {
		return api.post("budget/close" , {budget_id: budget_id})	
	}

	getBudget = () => {
		return this.details;
	}

	createLocal = (external_id , email) => {
		return api.post("budget/create-local" ,{external_id: external_id , email: email})
	}


	validateCustomer = (customer_id) => {
		return api.post("budget/validate-customer" ,{customer_id: customer_id})
	}

	/**
	 * Save Customer
	 * 
	 * @param {Customer} customer 
	 */
	saveCustomer = (customer) => {
		
		return new Promise((resolve , reject) => {

			api.post("budget/save-customer" ,customer)
			.then(
				(result) => {										
					resolve(result.data);
				}
			).catch(
				(error) => {
					reject(error);
				}
			)
		});
	}

	getCustomer = (customer_id) => {	
		return api.get(`budget/customer/details/${customer_id}`);
	}

	findCustomer = (data) => {	
		return api.post("budget/find-customer" ,data);
	}

	findAddress = (cep) => {	
		return api.post("budget/find-address" ,{cep: cep});
	}

	loadStoreCustomers = () => {
		return api.get("budget/store-customers");
	}


	/**
	 * Checkout
	 */
	orderCheckout = (payment_data) => {

		payment_data.customer_id = this.getCurrentCustomer().id;

		return api.post("budget/checkout" , payment_data);
	}

	/**
	 * Update Budget with current products
	 */
	updateAllBudgetProducts = () => {

		if(!this.current_customer.id){
			return;
		}


		api.get(`budget/details/${this.current_customer.id}`)
		.then(
			(result) => {
				this.details = result.data;
				PageEvent.triggerEvent(PageEvent.events.BUDGET_UPDATE , this.details);
			}
		).catch(
			(error) => {
			  	console.log(error)
			}
		)

	}


	/**
	 * Remove Product from Budget
	 * 
	 * @param {*} product 
	 */
	removeBudgetProduct = ( item ) => {

		api.post("budget/remove" , item)
		.then(
			(result) => {
				this.details = result.data;
				PageEvent.triggerEvent(PageEvent.events.BUDGET_UPDATE , this.details);
			}
		).catch(
			(error) => {
			  	console.log(error)
			}
		)


	}	

	/**
	 * Update Product from Budget
	 * 
	 * @param {*} product 
	 */
	updateBudgetProduct = ( item ) => {

		api.post("budget/update" , item)
		.then(
			(result) => {
				this.details = result.data;
				PageEvent.triggerEvent(PageEvent.events.BUDGET_UPDATE , this.details);
			}
		).catch(
			(error) => {
				  //alert(error.response.data.message);
			}
		)



	}

	/**
	 * Add item do Budget
	 * 
	 * @param {Produto} item 
	 */
	addBudgetProduct = (item) => {
		
		item.customer_id = this.getCurrentCustomer().id;

		if (!item.customer_id){
			ToastService.sendMessage('Por favor, selecione um cliente' , 'toast-error' , 2000);	
			return;
		}

		api.post("budget/add" ,item)
		.then(
			(result) => {
				this.details = result.data;
				PageEvent.triggerEvent(PageEvent.events.BUDGET_UPDATE , this.details);
				ToastService.sendMessage('Peça Adicionada ao carrinho' , 'toast-success' , 2000);	
			}
		).catch(
			(error) => {				  
				  ToastService.sendMessage(error.response.data.message , 'toast-error');
			}
		)

		
	}

	getMyBudgets = () => {
		return api.get(`budget/open`);
	}


}

const BudgetService = new BudgetServiceClass

export default BudgetService