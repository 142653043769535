import React from 'react';
import _ from 'lodash'

import ModalWindow from './ModalWindow'
import ProductQuickView from './ProductQuickView'

import AuthService from './AuthService'
import WishlistService from './WishlistService'

import CatalogWindow from './Catalog/CatalogWindow'

import {
	BrowserRouter,
	Switch,
	Route,
	Link,
	useParams
} from "react-router-dom";


class SearchProductCard extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			showOpts: false,
			grid: {},
			cover: {},
			hover: {},

			size: null,
			sizes: [],

			colors: [],

			usertype: false
		}
	}


	componentDidMount() {
		this.setupProduct();

		AuthService.getUser()
			.then(user => {
				this.setState({ usertype: user.type })
			});
	}

	componentDidUpdate(prevProps, prevState) {

		if (prevProps.product.id !== this.props.product.id) {
			this.setupProduct();
		}
	}

	setupProduct() {

		let first_grid = null;

		let newGrid = [];

		for (var i = 0; i < this.props.product.grid.length; i++) {
			if (this.props.product.grid[i].num_images > 0) {
				newGrid.push(this.props.product.grid[i]);
			}
		}

		//this.props.product.grid = newGrid;


		first_grid = this.props.product.grid[0];

		// console.log('first_grid' , this.props.product)

		this.updateColor(first_grid.color_id);
		this.updateAvailableColors();
	}

	updateAvailableColors() {

		let available_colors = _.uniqBy(this.props.product.grid.map(grid => {
			return { id: grid.color_id, name: grid.color_name, rgb: grid.color_rgb, css: grid.color_css }
		}), 'id');

		this.setState({ colors: available_colors });
	}

	updateColor(color_id) {

		let selected_grid = this.props.product.grid.filter(grid => grid.color_id == color_id)[0]
		this.setState({ grid: selected_grid })

		//Updating Picture based on selected color
		let cover = this.props.product.covers.filter(cover => cover.color_id == color_id)
		if (cover[0]) {
			this.setState({ cover: cover[0].medium });
		} else {
			this.setState({ cover: '/img/no-picture.jpg' });
		}

		let hover = this.props.product.hovers.filter(hover => hover.color_id == selected_grid.color_id)
		if (hover[0]) {
			this.setState({ hover: hover[0].medium })
		} else if (cover[0]) {
			this.setState({ hover: cover[0].medium });
		} else {
			this.setState({ hover: '/img/no-picture.jpg' });
		}



		//Updating sizes from new selected color
		let available_sizes = this.props.product.grid
			.filter(grid => grid.color_id == selected_grid.color_id)
			.map(grid => {
				return { id: grid.size_id, name: grid.size_name }
			});

		this.setState({ sizes: available_sizes })

	}

	handleColorClick = (color_id) => {
		return () => this.updateColor(color_id)
	}

	productMouseEnter = (e) => this.setState({ showOpts: true })

	productMouseLeave = (e) => this.setState({ showOpts: false })

	addToWishlist = () => {
		WishlistService.addToWishlist(this.props.product.id)
	}

	handleCatalogClick = (e) => {

		ModalWindow.open(CatalogWindow, { product_id: this.props.product.id })

	}

	handleQuickViewClick = (e) => {
		//ModalWindow.open(<ProductQuickView product={this.props.product} />)	
		ModalWindow.open(ProductQuickView, { product: this.props.product, hideImages: true })
	}

	renderCardActions() {

		switch (this.state.usertype) {
			case 'user':
				return (
					<span class="card-action">
						<button class="btn btn-xs btn-circle btn-white-primary" onClick={this.handleCatalogClick}>
							<i class="fe fe-book-open"></i>
						</button>
					</span>
				);

			case 'customer':
				return (
					<span class="card-action">
						<button class="btn btn-xs btn-circle btn-white-primary" data-toggle="button" onClick={this.addToWishlist}>
							<i class="fe fe-heart"></i>
						</button>
					</span>
				);

			default:
				return null;
		}

	}

	renderPrice() {
		if (this.props.show_price) {
			return (<div class="font-weight-bold text-muted">{this.props.product.active ? `R$ ${this.props.product.price.toFixed(2)}` : 'Indisponível'}</div>);
		} else {
			return (<a href="javascript:;" onClick={() => AuthService.requestLogin()}>Ver Preço</a>);
		}
	}

	render() {
		return (<div class="col-6 col-md-4">


			<div class="card mb-7">



				<div class="badge card-badge card-badge-left text-uppercase" style={{ backgroundColor: this.props.product.badge[1] || process.env.REACT_APP_DEFAULT_BADGEBC, color: this.props.product.badge[2] || process.env.REACT_APP_DEFAULT_BADGETC }}>
					{this.props.product.badge[0]}
				</div>


				<div class="card-img">


					<Link class="card-img-hover" to={`/produto/${this.props.product.id}`}>
						<img class="card-img-top card-img-back" src={process.env.REACT_APP_BASE_URL + this.state.hover} alt="..." />
						<img class="card-img-top card-img-front" src={process.env.REACT_APP_BASE_URL + this.state.cover} alt="..." />
					</Link>



					<div class="card-actions">
						{this.renderCardActions()}
					</div>

				</div>


				<div class="card-body px-0" >


					<div class="font-size-xs">
						<a class="text-muted" href="shop.html">{this.props.product.category_name}</a>
					</div>


					<div class="font-weight-bold">
						<Link class="text-body" to={`/produto/${this.props.product.id}`}>
							{
								this.props.product.product_name != null ?
									this.props.product.product_name
									:
									this.props.product.name.substring(0, 19)
							}
						</Link>
					</div>

					<div class="font-weight-bold text-muted">
						{this.state.grid.color_name}
					</div>

					{this.renderPrice()}


					<div class="card-footer px-0 bg-white" style={{ padding: '.7em' }}>
						<div class="mb-1">
							{this.state.colors.map((color) => {
								return (<div class="custom-control custom-control-inline custom-control-color">
									<a href="javascript:" className={(color.id == this.state.grid.color_id) ? 'custom-control-label custom-control-label2 custom-control-label-active' : 'custom-control-label custom-control-label2'} onClick={this.handleColorClick(color.id)} style={{ background: color.css, color: 'transparent' }}></a>
								</div>)
							})}
						</div>
					</div>

				</div>

			</div>

		</div>
		)
	}




}


export default SearchProductCard;
