
function PageEventClass(){

    this.events = {
		CART_CLICK: 'CART_CLICK',
		CART_UPDATE: 'CART_UPDATE',
		BUDGET_UPDATE: 'BUDGET_UPDATE',
		LOGIN: 'LOGIN',
		LOGOUT: 'LOGOUT',
		CUSTOMER_CHANGE: 'CUSTOMER_CHANGE',
		ROUTE_CHANGE: 'ROUTE_CHANGE',
    }

    /**
	 * Listeners
	 */
	this.listeners = [];


	/**
	 * 
	 * @param {*} callback 
	 * @param {*} event
	 */
	this.addEventListener = (event , callback) => {

		if(Object.values(this.events).includes(event)){
			this.listeners.push({callback: callback , event : event});
		}		
	}

	/**
	 * trigger event @param evento passing @param value to the callback
	 * 
	 * @param {string} event 
	 * @param {mixed} value 
	 */
	this.triggerEvent = (event , value) => {

		for(let i = 0;i < this.listeners.length;i++){
			let _listener = this.listeners[i];

			if(_listener.event == event){
				_listener.callback(value);
			}
		}
	}


}

const PageEvent = new PageEventClass

export default PageEvent
