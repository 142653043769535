import React from 'react';
import _ from 'lodash'

import { Redirect, withRouter } from "react-router-dom";

import ReactImageMagnify from 'react-image-magnify';
import { ImageWithZoom } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import PrismaZoom from 'react-prismazoom';

import AliceCarousel from 'react-alice-carousel';

import ProductService from './ProductService';

import CartService from './CartService'
import BudgetService from './Budget/BudgetService'
import AuthService from './AuthService'
import WishlistService from './WishlistService'

import CatalogWindow from './Catalog/CatalogWindow'
import ModalWindow from './ModalWindow'

import Clipboard from './Clipboard';

import ToastService from './ToastService';
import MobileView from './Context/MobileView';



const queryString = require('query-string');


class ProductDetails extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            grid: {},

            cover: {},
            covers: [],


            sizes: [],

            images: [],
            image: {},

            stock: [],

            quantity: 1,
            usertype: false,

            show_price: false,
            colors: [],
        }


    }


    imagesLoaded = false;
    stockLoaded = false;
    renderCount = 0;

    componentWillMount() {
        this.setupProduct();

        AuthService.getUser()
            .then((user) => {
                this.setState({ usertype: user.type });
                this.setState({ show_price: true });
            });
    }

    componentDidUpdate(prevProps) {

        if (prevProps.product && prevProps.product.id != this.props.product?.id) {
            this.imagesLoaded = false;
            this.stockLoaded = false;
            this.renderCount = 0;
            this.setupProduct();
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        let _should_update = (this.imagesLoaded && this.stockLoaded);
        if (_should_update) {
            //this.imagesLoaded = false;
            //this.stockLoaded = false;
        }
        return _should_update;
    }

    setupProduct() {
        //Selecionando primeira Grade
        let first_grid = null;

        let newGrid = [];

        for (var i = 0; i < this.props.product?.grid.length; i++) {
            if (this.props.product?.grid[i].num_images > 0) {
                newGrid.push(this.props.product?.grid[i]);
            }
        }

        //this.props.product?.grid = newGrid;

        if (newGrid.length > 0) {
            first_grid = newGrid[0];
        } else {
            first_grid = this.props.product?.grid[0];
        }


        this.setState({ grid: first_grid });

        //Tamanhos disponiveis
        this.setState({ sizes: _.orderBy(this.props.product?.available_sizes, 'name') });


        if (!this.props.product?.images || this.props.product?.images.length === 0) {
            this.loadImages();
        } else {
            this.setState({ image: this.props.product?.images[0] });
            this.setState({ images: this.props.product?.images });

            let image = this.props.product?.images.filter(img => {
                return img.color_id == first_grid.color_id;
            })[0];

            if (image) {
                this.setState({ image: image });
            } else {
                this.setState({ image: { id: null, large: '/img/no-picture.jpg' } });
            }

            this.imagesLoaded = true;
        }

        this.loadStock();

        let available_colors = _.uniqBy(this.props.product?.grid.map(grid => {
            return { id: grid.color_id, name: grid.color_name, rgb: grid.color_rgb, css: grid.color_css }
        }), 'id');

        this.setState({ colors: available_colors });

        //dados para consulta
        // console.log(
        // 	{
        // 		estoque: this.state.stock[this.state.grid?.id] || 0,
        // 		codigo_grupo: this.props.product?.external_id,
        // 		ref_grupo: this.props.product?.code,
        // 		codigo_cor: this.state.grid?.color_id,
        //         producto: this.props.product
        // 	}
        // )

    }


    loadImages() {

        ProductService.getImages(this.props.product?.id)
            .then((result) => {
                
                this.imagesLoaded = true;

                this.setState({ images: result.data });

                let image = result.data.filter(img => {
                    return img.color_id == this.state.grid?.color_id;
                })[0];

                if (image) {
                    this.setState({ image: image });
                } else {
                    this.setState({ image: { id: null, large: '/img/no-picture.jpg' } });
                }


            },
                (error) => {
                    console.log(error)
                })
    }

    loadStock() {
        ProductService.getStock(this.props.product?.id, queryString.parse(window.location.search)).then((result) => {
            this.stockLoaded = true;
            let { data } = result;
            // get all stock with value > 0
            let stock = Object.keys(data).filter(key => data[key] > 0).reduce((obj, key) => {
                obj[key] = data[key];
                return obj;
            }, {});
            // console.log('stock', stock);
            this.setState({ stock: stock });
        }, (error) => {
            this.stockLoaded = true;
            this.forceUpdate();
        });
    }

    handleImageClick = (image) => {
        return (e) => {
            this.setState({ image: { id: image.id, large: image.large } })

        }
    }

    updateColor = (color_id) => {

        //Tamanho da cor atualmente selecionada
        let current_size_id = this.state.grid?.size_id;

        //Filtrando as "grades(grid)" da mesma cor
        let grids_available = this.props.product?.grid.filter(grid => {
            return grid.color_id == color_id;
        })
            .sort((g1, g2) => { //Ordenando baseado no tamanho já selecionado

                if (g1.size_id == current_size_id && g2.size_id == current_size_id) {
                    return 0;
                }

                if (g1.size_id == current_size_id) {
                    return -1;
                }

                if (g2.size_id == current_size_id) {
                    return 1;
                }
            });

        if (grids_available[0]) {

            let available_grid = grids_available[0];
            this.setState({ grid: available_grid });

            let image = this.state.images.filter(img => {
                return img.color_id == available_grid.color_id
            });

            if (image[0]) {
                this.setState({ image: { id: image[0].id, large: image[0].large } });
            } else {
                this.setState({ image: { id: 0, large: '/img/no-picture.jpg' } });
            }

        }


    }

    handleSizeClick = (size_id) => {

        return (e) => {

            let grid = this.props.product?.grid.filter(grid => {
                return grid.color_id == this.state.grid?.color_id && grid.size_id == size_id;
            });


            if (grid[0]) {
                this.setState({ grid: grid[0] });
            }
        }
    }

    handleGridClick = (grid) => {
        return (e) => {

            if (this.state.stock[grid.id] > 0) {
                this.setState({ grid: grid })
            }
        }
    }

    handleCoverClick = (cover) => {
        return (e) => {
            this.setState({ cover: cover.file });
            this.updateColor(cover.color_id);
        }
    }

    handleColorClick = (color) => {
        return (e) => {
            //this.setState({cover: cover.file});
            this.updateColor(color.id);
        }
    }

    handleChangeQuantity = (e) => {

        let value = parseInt(e.target.value);
        let max = parseInt(e.target.max);

        if (value < 0) {
            value = 0;
        } else if (value > max) {
            value = max;
        }

        this.setState({ quantity: value });

    }



    handleAddCartClick = () => {

        if (!this.state.usertype) {
            return AuthService.requestLogin();
        }

        if (this.state.quantity <= 0) {
            return;
        }

        var data = {
            product: this.props.product?.id,
            grid: this.state.grid?.id,
            quantity: this.state.quantity
        }

        AuthService.getUser()
            .then((user) => {
                switch (user.type) {
                    case 'customer':
                        CartService.addCartProduct(data);
                        break;

                    case 'user':
                        BudgetService.addBudgetProduct(data);
                        break;
                }
            })


    }

    getStockAvailable = () => {

        let grid_stock = this.state.stock.filter((stock) => {
            return stock.cd_produto == this.state.grid?.id;
        })[0];

        if (grid_stock && grid_stock.qt_saldo > 0) {
            return grid_stock.qt_saldo;
        }

        return 0;
    }

    renderImages() {
        if (this.props.hideImages) {
            return;
        }

        return (

            <div class="group-pallete-color-img">
                <div class="scroll-itens-product">
                    <div class="itens-thumb">
                        {this.state.images.map((image, index) => {
                            return (
                                <div key={index} class="item-product-line-modal" style={{ display: image.color_id == this.state.grid?.color_id ? 'block' : 'none' }}>
                                    <img
                                        className={image.id == this.state.image.id ? 'custom-control-images active' : 'custom-control-images'}
                                        src={process.env.REACT_APP_BASE_URL + image.medium}
                                        onClick={this.handleImageClick(image)}
                                    />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>


        )



    }

    renderCarousel() {
        return (
            <>
                <div class="col-xl-3 col-md-3 pr-1 mt-1 ml-md-0">
                    {this.renderImages()}
                </div>
                <div class='col-lg-9 col-md-9 pl-0'>
                    <div class="card">
                        <div class="badge card-badge text-uppercase" style={{ backgroundColor: this.props.product?.badge[1] || process.env.REACT_APP_DEFAULT_BADGEBC, color: this.props.product?.badge[2] || process.env.REACT_APP_DEFAULT_BADGETC }}>
                            {this.props.product?.badge[0]}
                        </div>
                        <div tabindex="0">
                            <ReactImageMagnify {...{
                                //enlargedImageContainerStyle: { zIndex: 9999 },
                                smallImage: {
                                    alt: '...',
                                    imageClassName: 'card-img-top',
                                    // enlargedImageStyle: { zIndex: 999 },
                                    isFluidWidth: true,
                                    src: process.env.REACT_APP_BASE_URL + this.state.image.large
                                },
                                largeImage: {
                                    src: process.env.REACT_APP_BASE_URL + this.state.image.large,
                                    width: 1345,
                                    height: 1792,
                                    // enlargedImageStyle: { zIndex: 998 },
                                },
                                enlargedImagePosition: 'over',
                            }} />

                            {this.renderWishlist()}
                        </div>
                    </div>
                </div>
            </>
        )
    }

    renderMobileCarousel() {


        let filteredImages = this.state.images.filter((image) => {
            if (image.color_id == this.state.grid?.color_id) {
                return image;
            }
        });

        if (filteredImages.length == 0) {
            filteredImages = [{ id: null, large: '/img/no-picture.jpg' }]
        }

        return (
            <div>


                <AliceCarousel items={filteredImages} buttonsDisabled={true} >
                    {filteredImages.map((image, index) => {
                        //console.log(process.env.REACT_APP_BASE_URL + image.large)
                        return (
                            <div key={index} >

                                <div class="badge card-badge text-uppercase" style={{ backgroundColor: this.props.product?.badge[1] || process.env.REACT_APP_DEFAULT_BADGEBC, color: this.props.product?.badge[2] || process.env.REACT_APP_DEFAULT_BADGETC }}>
                                    {this.props.product?.badge[0]}
                                </div>
                                <PrismaZoom allowTouchEvents={true} maxZoom={2}>
                                    <img src={process.env.REACT_APP_BASE_URL + image.large} style={{ width: '100%', objectFit: 'contain' }} />
                                </PrismaZoom>
                            </div>
                        )
                    })}
                </AliceCarousel>
            </div>
        )

    }



    handleCatalogClick = (e) => {

        ModalWindow.open(CatalogWindow, { product_id: this.props.product?.id })

    }

    addToWishlist = () => {
        WishlistService.addToWishlist(this.props.product?.id)
    }

    renderWishlistCatalog() {

        if (this.state.usertype == 'customer') {

            return (
                <button class="btn btn-red btn-block mb-2" onClick={this.addToWishlist}>
                    Wishlist <i class="fe fe-heart ml-2"></i>
                </button>
            )
        }
    }

    renderShareOptions() {

        let shareoptions = [];

        shareoptions.push(
            <a class="btn btn-xs font-size-xxxs text-gray-350 ml-2" href={this.shareWhatsApp()} target="_blank" alt="Compartilhar com WhatsApp">
                <img src={"/icons/whatsapp.svg"} width="24px" height="24px"></img>
            </a>
        );

        shareoptions.push(
            <button class="btn btn-xs font-size-xxxs text-gray-350 ml-2" alt="Copiar Link" onClick={this.shareCopyToClipboard}>
                <img src={"/icons/copy.svg"} width="24px" height="24px"></img>
            </button>
        );

        if (this.state.usertype == 'user') {

            shareoptions.push(
                <button disabled class="btn btn-xs font-size-xxxs text-gray-350 ml-2" alt="SMS">
                    <img src={"/icons/chat.svg"} width="24px" height="24px"></img>
                </button>
            );

            shareoptions.push(
                <button disabled class="btn btn-xs font-size-xxxs text-gray-350 ml-2" alt="Email">
                    <img src={"/icons/email.svg"} width="24px" height="24px"></img>
                </button>
            );

        }

        return (
            <p class="mb-0 mt-5">
                {shareoptions}
            </p>
        )
    }

    shareCopyToClipboard = (e) => {
        Clipboard.copy(`${process.env.REACT_APP_BASE_URL}/produto/${this.props.product?.id}?t=${this.props.product?.share_code + 'l'}`);
        ToastService.sendMessage('Link Copiado para a Área de Transferencia', null, 5000);
    };

    shareWhatsApp() {
        let message = encodeURIComponent(`Confira essa ${this.props.product?.name}: ${process.env.REACT_APP_BASE_URL}/produto/${this.props.product?.id}?t=${this.props.product?.share_code + 'w'}`);
        return `https://api.whatsapp.com/send?text=${message}&source=&data=&app_absent=`;
    }


    renderPrice() {
        if (this.state.show_price) {
            return (<span class="ml-1 font-size-h5 font-weight-bolder text-primary">{this.props.product?.active ? `R$ ${this.props.product?.price.toFixed(2)}` : 'Indisponível'}</span>);
        } else {
            return (<span class="ml-1 font-size-h5 font-weight-bolder text-primary">
                <a href="#" onClick={() => AuthService.requestLogin()}>Ver Preço</a>
            </span>);
        }
    }

    renderSizeSelector() {
        if (!this.state.usertype) {
            return null;
        }

        return (
            <>
                <p class="mb-5">Tamanhos disponíveis:
                    <strong>
                        <span id="sizeCaption">{this.state.grid?.size_name}</span>
                    </strong>
                </p>
                <div class="mb-2">
                    {this.props.product?.grid.filter((grid) => grid.color_id === this.state.grid?.color_id).map((grid) => (
                        // this.state.stock[grid.id] > 0 &&
                        <div class="custom-control custom-control-inline custom-control-size mb-2">
                            <button className={'custom-control-label ' + ((this.state.grid?.id === grid.id && this.state.stock[grid.id] > 0) ? 'active' : (this.state.stock[grid.id] > 0 ? '' : 'disabled'))} onClick={this.handleGridClick(grid)}>{grid.size_name}</button>
                        </div>
                    ))}
                </div>
                <p class="mb-2">Estoques disponíveis:</p>
                <div class="mb-2">
                    {this.props.product?.grid.filter((grid) =>
                        grid.color_id === this.state.grid?.color_id
                    ).map((grid) => (
                        // this.state.stock[grid.id] > 0 &&
                        <div class="custom-control custom-control-inline custom-control-size mb-2">
                            <button className={'custom-control-label ' + ((this.state.grid?.id === grid.id && this.state.stock[grid.id] > 0) ? 'active' : (this.state.stock[grid.id] > 0 ? '' : 'disabled'))} onClick={this.handleGridClick(grid)}>{this.state.stock[grid.id] || 0}</button>
                        </div>
                    ))}
                </div>
            </>
        )
    }

    renderGridCode() {
        if (!this.state.usertype) {
            return null;
        }

        return (<p class="mb-5">
            {/* Ref Reduzida: <strong id="colorCaption">{this.state.grid?.code}</strong> */}
            <strong class="colorCaption">Ref Reduzida</strong>: {this.state.grid?.code}
            <br />
            <strong class="text-body">Código</strong>: {this.props.product?.code}
            <br />
            <strong class="text-body">Adicionado em:</strong> {this.props.product?.created_at}
        </p>)
    }

    renderWishlist() {

        if (this.state.usertype != 'user') {
            return null;
        }

        return (
            <button class="btn btn-xs btn-circle btn-white-primary card-action card-action-right" onClick={this.handleCatalogClick}>
                <i class="fe fe-book-open"></i>
            </button>
        )
    }

    render() {

        if (this.stockLoaded && this.imagesLoaded) {
            this.renderCount++;
        }

        return (
            <section class="mt-5">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <div class="row mb-1">
                                <div class="col-xl-8 col-lg-8 col-md-9">
                                    <MobileView media={'>= 768'}>
                                        <div class="row">
                                            {this.renderCarousel()}
                                        </div>
                                    </MobileView>
                                    <MobileView media={'< 768'}>
                                        {this.renderMobileCarousel()}
                                    </MobileView>
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-3 pl-lg-2 pl-2 pr-4 pr-md-2 pb-10 pt-5">
                                    <div class="row mb-1">
                                        <div class="col">
                                            <a class="text-muted" href="#">{this.props.product?.category_name}</a>
                                        </div>
                                    </div>
                                    <h5 class="mb-2">
                                        {
                                            this.props.product?.product_name != null ?
                                                this.props.product?.product_name
                                                :
                                                this.props.product?.name
                                        }
                                    </h5>
                                    <div class="mb-7">
                                        {this.renderPrice()}
                                    </div>
                                    <div class="form-group">
                                        <p class="mb-5">
                                            Cor: <strong id="colorCaption">{this.state.grid?.color_name}</strong>
                                        </p>
                                        {this.renderGridCode()}
                                        <div class="mb-8 ml-n1">
                                            {this.state.colors.map(color => {
                                                return (
                                                    <div class="custom-control custom-control-inline custom-control-img" style={{ width: '40px', height: '40px' }}>
                                                        <a href='javascript:;' style={{ opacity: .8 }} className='custom-control-label' onClick={this.handleColorClick(color)}>
                                                            <span class="embed-responsive embed-responsive-1by1 bg-cover" style={{ background: color.css, borderRadius: '50%', border: (this.state.grid?.color_id == color.id ? '3px solid #ff6f61' : '1px solid #ff6f61') }}></span>
                                                        </a>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        {this.renderSizeSelector()}
                                        <div class="form-row mb-7">
                                            <div class="col-12 col-lg">
                                                <input type="number" placeHolder="Qtd." disabled={!this.props.product?.active || this.state.stock[this.state.grid?.id] <= 0} max={this.state.stock[this.state.grid?.id] || 0} value={this.state.quantity} onChange={this.handleChangeQuantity} class="custom-select mb-2" style={{ background: 'none', padding: '.875rem' }} />
                                            </div>
                                            <div class="col-12 col-lg">
                                                <button type="submit" className={'btn btn-block btn-green mb-2' + ((this.props.product?.active && this.state.stock[this.state.grid?.id] > 0) ? '' : ' disabled')} onClick={this.handleAddCartClick}>
                                                    Adicionar <i class="fe fe-shopping-cart ml-2"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="form-row mb-7">
                                            <div class="col-12 col-lg-auto">
                                                {this.renderWishlistCatalog()}
                                                {this.renderShareOptions()}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default ProductDetails;

