import React from 'react';

import HomeTopSellers from './HomeTopSellers'

import ProductService from './ProductService';
import HomeProductSection from './HomeProductSection';
import HomeCategories from './HomeCategories'
import HomeBanners from './HomeBanners'
import HomeSlider from './HomeSlider';
import HomeLooks from './HomeLooks'
import AuthService from './AuthService'


import './lgpd.css';
import './css/home.css';


class HomeTeste extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      categories: [],
      looks: [],
      readPrivacyPoliticy: false,
    }

 
  }

  renderCount = 0;

  componentDidMount() {

    ProductService.getCategorySpotlight()
      .then((response) => {
        this.setState({ categories: response.data })
      })

      AuthService.getUser()
      .then
      (
        () => {
          this.setState({show_price: true})
        },
        () => {
          this.setState({show_price: false})
        }
      )

  }

  




  render() {

    this.renderCount++;

    return (
      <>
        <HomeSlider />
        <HomeCategories/>
        <HomeBanners/>
        <HomeLooks />
        {this.state.categories.map((category, index) => {
          return category.spotlight.length > 0 ? <HomeProductSection key={index} category={category} show_price={this.state.show_price}/> : null;
        })}            
      </>
    );
  }
}

export default HomeTeste;

