import React, { useState } from 'react';

function HomeInstagram(props){
    return (
        <>
            <section className="categories mb-6 mt-12" style={{ backgroundColor: 'blueviolet', height: '300px' }}>
                <div class="mb-10 text-center" style={{ alignItems: 'center', color: 'white' }}>
                    <h2>Instagram</h2>
                </div>
            </section>
        </>
    );
}

export default HomeInstagram;