import React from 'react';

import {
    BrowserRouter,
    Switch,
    Route,
    Link,
    withRouter
  } from "react-router-dom";

import BudgetService from '../Budget/BudgetService';
import CustomerCard from '../Budget/CustomerCard'

import ModalWindow from '../ModalWindow'
import FindCustomer from '../Budget/FindCustomer'

class StoreCustomers extends React.Component {


	constructor(props) {
        super(props);      
        
        this.state = {
            customers: []
        }

    }

	componentDidMount(){

		BudgetService.loadStoreCustomers()
		.then((response) => {
			this.setState({customers: response.data});
		})

	}

	handleCustomerClick(customer){
		return (e) => {
			BudgetService.setCurrentCustomer(customer.id);
		}
	}

	handleFindCustomer = () => {
		ModalWindow.open(FindCustomer  , {})	
	}

	renderCustomerCards(){
		if(this.state.customers.length == 0){
			return <p style={{textAlign: 'center'}}>Ainda não foi cadastrado nenhum cliente.</p>
		}

		return this.state.customers.map((customer) =>{
			return <CustomerCard customer={customer} />
		})
	}

	render() {
		return (
			<div class="row">
				<div class="col-12 pb-7">
					<h5 class="text-center mb-5">Clientes da Loja</h5>
				</div>
				<div class="col-12 pb-7">
					<Link to="/account/cadastrar-cliente" class="btn btn-green">Cadastrar</Link>
					<button  class="btn btn-blue ml-5" onClick={this.handleFindCustomer}>Procurar</button>
				</div>
        		<div class="col-12">
					{this.renderCustomerCards()}					
				</div>
			</div>
		);
	}
}

export default StoreCustomers;