import React from 'react';

import {
  BrowserRouter,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";

import BudgetService from '../Budget/BudgetService';

class CustomerCard extends React.Component {

  constructor(props) {
    super(props);
  }

  componentWillMount() {

  }


	handleCustomerClick(customer){
		return (e) => {
			BudgetService.setCurrentCustomer(customer.id);
		}
  }
  
  renderBudgetButton(){

    if(this.props.customer.external == null){
      return null;
    }

    return (
        <div class="col-12  col-lg-6">

          <a class="btn btn-sm btn-block btn-blue" href="#" onClick={this.handleCustomerClick(this.props.customer).bind(this)}>
            Fazer Orçamento
          </a>

        </div>
    );
  }


  renderCompleteInfo(){
    if(this.props.complete){
      return (
        <div class="row mt-5">
          <div class="col-6 col-lg-4">

          <h6 class="heading-xxxs text-muted">CPF/CNPJ:</h6>

          <p class="mb-lg-0 font-size-sm font-weight-bold">
            {this.props.customer.cpfcnpj}
          </p>

        </div>

    </div>)
    }
  }

  render() {
    return (
      <div class="card card-lg mb-5 border">
        <div class="card-body pb-0">


          <div class="card card-sm">
            <div class="card-body bg-light">
              <div class="row">

              <div class="col-12  mb-3">
                <span class="heading-xxxs text-muted">Cliente </span>
                <span class="mb-lg-0 font-size-sm font-weight-bold pl-4">{this.props.customer.fullname}</span>
                </div>

                <div class="col-6 ">


                  <h6 class="heading-xxxs text-muted">Código:</h6>

                  <p class="mb-lg-0 font-size-sm font-weight-bold">
                    B2B: {this.props.customer.id}
                  </p>

                  <p class="mb-lg-0 font-size-sm font-weight-bold">
                    Virtual: {this.props.customer.external_id}
                  </p>
                </div>

                

                <div class="col-6">


                  <h6 class="heading-xxxs text-muted">Aprovado:</h6>

                  <p class="mb-lg-0 font-size-sm font-weight-bold">{this.props.customer.date_approval == null ? 'Não' : 'Sim'}</p>

                  <p class="mb-lg-0 font-size-sm font-weight-bold">{this.props.customer.date_approval_formatted}</p>

                </div>

              </div>
              {this.renderCompleteInfo()}
            </div>
          </div>

        </div>
        <div class="card-footer">
          <div class="row align-items-center">
            <div class="col-12">
              <div class="form-row">
                <div class="col-12 col-lg-6">

                  <Link class="btn btn-sm btn-block btn-green mb-4" to={{ pathname: `/account/cliente/${this.props.customer.id}`, order: this.props.order }}>
                    Detalhes
                  </Link>

                </div>
                {this.renderBudgetButton()}
              </div>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CustomerCard;