import React from 'react';

import {
  BrowserRouter,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";

import BudgetService from '../Budget/BudgetService';

import ConfirmDialog from '../ConfirmDialog'
import ModalWindow from '../ModalWindow'


class BudgetCard extends React.Component {

  constructor(props) {
    super(props);    
  }


	handleCustomerClick(customerId){
		return (e) => {
			BudgetService.setCurrentCustomer(customerId);
		}
  }

  handleBudgetRemoveClick(){

      ModalWindow.open(ConfirmDialog  , {
        title: 'Confirmar Exclusão',
        message: 'Deseja realmente excluir esse orçamento?',
        onConfirm: () => this.props.onCloseBudget(this.props.budget.id)
      });	
  }
  
  renderBudgetButton(){
    return (
      <>
        <div class="col-12 col-lg-6 mb-4">

          <a class="btn btn-sm btn-block btn-blue" href="#" onClick={this.handleCustomerClick(this.props.budget.customer_id).bind(this)}>
            Carregar Orçamento
          </a>

        </div>
        <div class="col-12 col-lg-6">

          <a class="btn btn-sm btn-block btn-red" href="#" onClick={this.handleBudgetRemoveClick.bind(this)}>
            Remover
          </a>

        </div>
      </>
    );
  }

  render() {
    return (
      <div class="card card-lg mb-5 border">
        <div class="card-body pb-0">


          <div class="card card-sm">
            <div class="card-body bg-light">
              <div class="row">
                <div class="col-12  mb-3">
                <span class="heading-xxxs text-muted">Cliente </span>
                <span class="mb-lg-0 font-size-sm font-weight-bold">{this.props.budget.customer_name}</span>
                </div>
                <div class="col-6 col-lg-4">


                  <h6 class="heading-xxxs text-muted">Código</h6>

                  <p class="mb-lg-0 font-size-sm font-weight-bold">B2B {this.props.budget.customer_id}</p> 

                  <p class="mb-lg-0 font-size-sm font-weight-bold">Virtual {this.props.budget.customer_external_id}</p> 

                </div>

                <div class="col-6 col-lg-4">


                  <h6 class="heading-xxxs text-muted">Quantidade de Itens:</h6>


                  <p class="mb-0 font-size-sm font-weight-bold">
                    {this.props.budget.items.length}
                  </p>

                </div>

                <div class="col-6 col-lg-4">

                  <h6 class="heading-xxxs text-muted">Subtotal</h6>
                  <p class="mb-lg-0 font-size-sm font-weight-bold">R$ {this.props.budget.subtotal.toFixed(2)}</p>

                </div>

              </div>
            </div>
          </div>

        </div>
        <div class="card-footer">
          <div class="row align-items-center">
            <div class="col-12">
              <div class="form-row">
                {this.renderBudgetButton()}
              </div>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BudgetCard;