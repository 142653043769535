import api from './api';

import PageEvent from './PageEvent'

function WishlistServiceClass(){


	//Wishlist
	this.addToWishlist = (product_id) => {
		return api.post("wishlist/add" ,{product_id: product_id});
	}

	this.removeFromWishlist = (product_id) => {
		return api.post("wishlist/remove" ,{product_id: product_id});
	}

	this.getMyWishlist = () => {
		return api.get("wishlist/products");
	}
}

const Wishlist = new WishlistServiceClass

export default Wishlist