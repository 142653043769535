import React, { useState , useEffect } from 'react';


function MobileView(props){

  	const [width, setWidth] = useState(window.innerWidth);

	const defaultMedia = '>= 768';

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		}
    }, []);

	function validateWidthCondition(){

		let media = props.media || defaultMedia;

		let [ cp , w ] = media.split(/\s+/);
			
		w = parseInt(w);

		switch(cp){

			case '>':
				return width > w;

			case '>=':
				return width >= w;

			case '<':
				return width < w;

			case '<=':
				return width <= w;
			
			case '==':
				return width == w;

			default:
				return false;
		}
	}

	return <>{validateWidthCondition() && props.children}</>


}

export default MobileView;
