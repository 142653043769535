import React, { useEffect, useState } from 'react';
import ReactAliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';


import * as Icons from 'react-icons/fi';
import { Link } from 'react-router-dom';
import api from './api';

function HomeBanners(props) {
    const [banners, setBanners] = useState([])
    const [itemsBreakpoints, setItemsBreakpoints] = useState(1)
    const [width, setWidth] = useState(374);
    const [activeIndex, setActiveIndex] = useState();
    const [bannersSlider, setBannersSlider] = useState();
    const [carousel, setCarousel] = useState();

    const breakpoints = {
        0: { items: itemsBreakpoints }
    }

    useEffect(() => {

        setItemsBreakpoints(parseInt(props.section.layout))
        setBanners(props.section.banners)
        //renderCategoriesItems(props.section.banners)

        if (itemsBreakpoints === 1){
            setWidth(1187);
        }  else if (itemsBreakpoints === 2) {
            setWidth(593);
        } else {
            setWidth(395);
        }
        
    }, [props.section.layout, props.section.banners, itemsBreakpoints]);

    function renderItems() {
        let element = document.getElementById('bannerImg');
        console.log('elemento: ' + element?.width);

        let items = banners.map((banner, index) =>
            
            <div key={index} className="carousel-banner-container"> 
                {
                    banner.linkTo != null ?
                        <Link to={banner.linkTo}>
                            
                            <div className='category-item-image-full-hover' style={{width: width + 'px'}} >Ver Mais</div>
                            <img id="bannerImg" src={"https://b2b.skenn.com.br" + banner.imgUrl}  />
                        </Link>
                    :
                        <div style={{ /* backdropFilter: "blur(25px) saturate(150%)", backgroundColor: "rgba(255, 255, 255, 0.35)" */}}>
                            <img id="bannerImg" src={"https://b2b.skenn.com.br" + banner.imgUrl}  alt="bannerImg"/>
                        </div>
                }
            </div>
        )
        
        return items;
    }


    return (
        <section className="categories mb-5">
            <div className="container carousel-container">
                <ReactAliceCarousel
                    items={renderItems()}
                    responsive={breakpoints}
                    buttonsDisabled={true}
                    //autoPlay={true} 
                    //autoPlayInterval={4000}
                    //duration={800}
                    // slideToIndex={activeIndex}
                    // onSlideChanged={syncActiveIndex}
                    //swipeDelta={30}

                    ref={(el) => setCarousel(el)}

                />
    
            <div className='alice-carousel-custom-buttons' style={{ maxWidth: '60vh', margin: 'auto', left: 0, right: 0, top: 'calc(50% - 4rem)' }}>
                    <button onClick={() => carousel.slidePrev()} className='btn-slider'><Icons.FiChevronLeft /></button>
                    <button onClick={() => carousel.slideNext()} className='btn-slider'><Icons.FiChevronRight /></button>
            </div>
    


     {/* width: "40%",  */}
            </div>

        </section>
    )
}


export default HomeBanners;