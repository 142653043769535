import React from 'react';

import { Link } from "react-router-dom";

import CheckoutBudget from './CheckoutBudget'
import CheckoutCart from './CheckoutCart'
import AuthService from '../AuthService'

class Checkout extends React.Component {


	constructor(props) {
		super(props);

		this.state = {
			usertype: false
		}
	}

	componentDidMount() {

		AuthService.getUser()
		.then(user => {
			this.setState({usertype: user.type});
		})

	}

	render() {

		switch(this.state.usertype){
			case 'user':
				return <CheckoutBudget />
			
			case 'customer':
				return <CheckoutCart />

			default:
				return null;
		}
	}
}

export default Checkout;

