import React from 'react';
import CatalogService from './Catalog/CatalogService';

import AuthService from './AuthService';
import HomeProductCard from './HomeProductCard'

const queryString = require('query-string');

class CatalogPage extends React.Component {


  constructor(props){
    super(props);

    this.state = {
      catalog: false,
      show_price: false,
      userId: '',
      userType: '',
    }
  }

  componentDidMount(){
    CatalogService.getCatalog(this.props.match.params.catalog_id, queryString.parse(this.props.location.search))
    .then(response => {
      
      this.setState({catalog: response.data})
    });

    AuthService.getUser()
    .then((user) => {
      console.log(user);
      this.setState({show_price: true, userId: user.id, userType: user.type});

    },() => {
      this.setState({show_price: false})
    })
  }

  render() {

    if(this.state.catalog == false){
      return null;
    }

    return (            
            <div class="container mb-11 pt-5 mt-10">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-10 col-lg-8 col-xl-6">

                        <h2 class="mb-10 text-center">{this.state.catalog.name}</h2>

                    </div>
                    {
                      this.state.userId === this.state.catalog.salesman_id && this.state.userType === 'user' ?

                      <p class="mb-0">
                        <span class="mr-4">Compartilhar:</span>
                        <a class="btn btn-xs btn-circle btn-green font-size-xxxs text-gray-350 ml-2" href="#" target="_blank">
                          <img src={process.env.REACT_APP_BASE_URL + "/img/whatsapp.svg"} width="24px" height="24px"></img>                
                        </a>
                        <button class="btn btn-xs btn-circle btn-light font-size-xxxs text-gray-350 ml-2"  >
                          <img src={process.env.REACT_APP_BASE_URL + "/img/copy.svg"} width="24px" height="24px"></img> 
                        </button>
                        <button disabled class="btn btn-xs btn-circle btn-info font-size-xxxs text-gray-350 ml-2"  >
                          <img src={process.env.REACT_APP_BASE_URL + "/img/sms.svg"} width="24px" height="24px"></img> 
                        </button>
                        <button disabled class="btn btn-xs btn-circle btn-red font-size-xxxs text-gray-350 ml-2"  >
                          <img src={process.env.REACT_APP_BASE_URL + "/img/email.svg"} width="24px" height="24px"></img> 
                        </button>
                      </p>

                    :
                      ''
                    }
                </div>
                <div class="row">
                    {this.state.catalog.products.map((catalog_product) => {
                        return catalog_product.product ? <HomeProductCard product={catalog_product.product} show_price={this.state.show_price}/> : null;
                    })}                                 
                </div>
            </div>
    )
}
}

export default CatalogPage;