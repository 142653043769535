import React from 'react';

import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link
  } from "react-router-dom";

import Search from './Search';

//function NavBarMenu() {
class NavBarMenuCategory extends React.Component {  

	constructor(props) {
		super(props);

		this.state = {
		   active: false
		}
	 }

	categoryMouseEnter = (e) => this.setState({active : true})

	categoryMouseLeave = (e) => this.setState({active : false})

	componentDidMount() {
		// eslint-disable-next-line no-restricted-globals
		if (screen.width < 1000) {
			this.setState({active : true})
		}
	}
  

	renderCategory = (category) => {
		if(category.subcategories.length === 0){
			return 
		}/*else if (category.subcategories.length === 1) {
			console.log('subcategories' , category , category.subcategories.length)
			return (
				<div className={ this.state.active ? 'dropdown-menu show' : 'dropdown-menu'}>
					<div className="card card-lg">
						<div className="card-body">
							<ul className="list-styled font-size-sm">
								{category.subcategories[0].subcategories.map(function(item){
									return (<li className="list-styled-item">
										<a className="list-styled-link" href="https://yevgenysim.github.io/shopper/index.html">{item.name}</a>
									</li>)
								})}
							</ul>
						</div>
					</div>
				</div>
			)
		}*/else{
			return (
				<div className={ this.state.active ? 'dropdown-menu show' : 'dropdown-menu'} style={{minWidth: '150px'}}>
					<div className="card card-lg">
						<div className="card-body">
							<div className="row">
							<div className="col">
										{/* <div className="mb-5 font-weight-bold">{subcategory.group}</div> */}
										{/* <div className="mb-5 font-weight-bold">Categorias</div> */}
										<ul className="list-styled mb-0 font-size-sm">
										{this.props.category.subcategories.map((subcategory, index) => {
											return(
												<li key={index} className="list-styled-item'">
													<Link className="list-styled-link" to={'/procurar?categories=' + subcategory.id}>{subcategory.name}</Link>
												</li>
												)	
										})}

											{/* {subcategory.itens.map(function(item){
												return ()
											})}	 */}

										</ul>
									</div>

							
							</div>
						</div>
					</div>
				</div>
			)
		}
	}

	render() {
		return (
			<li className={ this.state.active ? 'nav-item dropdown show' : 'nav-item dropdown'} onMouseEnter={this.categoryMouseEnter} onMouseLeave={this.categoryMouseLeave}>
				<Link className="nav-link" to={this.props.category.route} >{this.props.category.name}</Link>
				{this.renderCategory(this.props.category)}
			</li>
		)
	}
}

export default NavBarMenuCategory;

