import React from 'react';

import {
    BrowserRouter,
    Switch,
    Route,
    Link,
    withRouter
  } from "react-router-dom";


import AuthService from '../AuthService'


class ForgotPassword extends React.Component {


	constructor(props) {
        super(props);      
        
        this.email = null;

        this.state = {
            error_message: null
        }
    }

    handleChange = (e) => {
        this.email = e.target.value;
        //this.setState({email: e.target.value})
    }

    handleSubmit = (e) => {

        e.preventDefault();

        AuthService.forgotPassword(this.email)
        .then((response) => {
            this.setState({success_message: 'Você receberá em breve um email com as instruções de recuperação' , error_message: null});
        },(error) => {
            this.setState({error_message: error.response.data.message , success_message: null});
        });

    }

    render() {
        return (
            <section class="pt-7 pb-12" style={{height: '900px'}}>
                <div class="container">
                    <div class="row">
                        <div class="col-12 text-center">
                            <h3 class="mb-10">Esqueci minha senha</h3>
                        </div>
                    </div>
                    <div class="row">

                        <div class="col-12  col-md-6" style={{padding: '2em'}}>

                            <form onSubmit={this.handleSubmit}>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="accountEmail">
                                                Email *
                                            </label>
                                            <input class="form-control form-control-sm" id="accountEmail" type="email" onChange={this.handleChange} placeholder="Digite o seu email"  required />
                                        </div>

                                    </div>
                                                          
                                    <div class="col-12">
                                        <button class="btn btn-dark" type="submit">Recuperar Senha</button><br/>
                                        <span style={{color: 'red' , marginLeft: '10px'}}>{this.state.error_message}</span>
                                        <span style={{color: '#03b6fb' , marginLeft: '10px'}}>{this.state.success_message}</span>
                                    </div>

                                    
                                </div>
                            </form>

                        </div>

                        <div class="col-12  col-md-6" style={{borderLeft: '1px solid #e5e5e5' , padding: '3em'}}>
                            <h4>Recuperação de senha</h4>
                            <p>Instruções de como recuperar senha conta</p>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ForgotPassword;