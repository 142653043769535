import React from 'react';

import { withRouter } from "react-router-dom";

import AuthService from '../AuthService';

import BudgetService from '../Budget/BudgetService';
import SigninNaturalPerson from './SigninNaturalPerson';
import SigninJuridicalPerson from './SigninJuridicalPerson';

const queryString = require('query-string');

class InsertCustomer extends React.Component {


    constructor(props) {
        super(props);

        this.cpfCnpj = null;

        this.state = {
            type: 'j',
            name: null,
            email: null,
            //password: null,
            //password_confirmation: null,
            natural_person: {},
            juridical_person: {},

            address_cep: null,
            address_number: null,
            address_complement: null,
            address_neighborhood: null,
            address_street: null,

            error_message: null,
            email_invalid: false

        }

    }

    componentWillMount(){
        let parsedQueryString = queryString.parse(this.props.location.search);

        if(parsedQueryString.cpfCnpj){
            this.cpfCnpj = parsedQueryString.cpfCnpj;            
        }
    }

    onBlurEmail = (e) => {

        AuthService.validateEmail(this.state.email)
        .then((response) => {
            this.setState({email_invalid: response.data.email_invalid});    
        },(error) => {
            this.setState({error_message: error.response.data.message});
        })

    }

    handleSignin = (e) => {
        e.preventDefault();
        console.log(this.state)

        this.setState({ error_message: null });

        BudgetService.saveCustomer(this.state)
            .then((customer) => {
                console.log(customer)
                this.props.history.push({
                    pathname: `/account/cliente/${customer.id}`
                });
            }, (error) => {
                this.setState({ error_message: error.response.data.message || 'Erro Inesperado' });
            })
    }


    handleFormChange(type) {

        return (person, field) => {

            switch (type) {
                case 'j':
                    this.setState({ juridical_person: person });
                    break;

                case 'f':
                    this.setState({ natural_person: person });
                    break;
            }
        }

    }


    handleChange = (field) => {
        return (e) => {
            let state = {};
            state[field] = e.target.value;


            if (field == 'address_cep') {
                state[field] = state[field].replace(/[^0-9]+/, '');

                if (state[field].length == 8) {
                    BudgetService.findAddress(state[field])
                        .then((response) => {

                            console.log(response.data)

                            if (response.data.cep) {

                                let address = response.data;
                                this.setState({ address_neighborhood: address.bairro, address_street: address.nome_logradouro })

                            } else {
                                this.setState({ error_message: 'CEP não localizado' });
                            }

                        }, (response) => {
                            this.setState({ error_message: 'CEP não localizado' });
                        })
                }
            }

            this.setState(state);
        }
    }

    renderPersonForm() {
        switch (this.state.type) {
            case 'f':
                return <SigninNaturalPerson cpfCnpj={this.cpfCnpj} onChange={this.handleFormChange('f').bind(this)} />

            case 'j':
                return <SigninJuridicalPerson cpfCnpj={this.cpfCnpj} onChange={this.handleFormChange('j').bind(this)} />
        }

    }

    render() {
        return (
            <div class="row">
                <div class="col-12 col-md-12 col-lg-12">
                    <h5 class="text-center mb-5">Cadastrar Novo Cliente</h5>

                    <form onSubmit={this.handleSignin}>
                        <div class="row">
                            {/*<div class="col-12">
                                <div class="form-group mb-8">
                                    <label>Tipo</label>
                                    <div class="btn-group-toggle" data-toggle="buttons">
                                        <label class="btn btn-sm btn-outline-border">
                                            <input type="radio" name="gender" value="f" onClick={this.handleChange('type')} checked={this.state.type == 'f'} /> Física
                                            </label>
                                        <label class="btn btn-sm btn-outline-border active">
                                            <input type="radio" name="gender" value="j" onClick={this.handleChange('type')} checked={this.state.type == 'j'} /> Jurídica
                                            </label>
                                    </div>
                                </div>
                            </div>*/}

                            <div class="col-12 col-md-6">

                                <div class="form-group">
                                    <label for="accountFirstName">
                                        {this.state.type == 'f' ? 'Nome Completo' : 'Razão Social'} *
                                    </label>
                                    <input class="form-control form-control-sm" id="accountFirstName" type="text" value={this.state.name} onChange={this.handleChange('name')} placeholder={this.state.type == 'f' ? 'Nome Completo' : 'Razão Social'} required="" />
                                </div>

                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <label for="accountEmail">
                                        Email *
                                    </label>
                                    <input class="form-control form-control-sm" id="accountEmail" type="email" onChange={this.handleChange('email')} onBlur={this.onBlurEmail} placeholder="Email" required="" />
                                    {this.state.email_invalid && <span style={{color: 'red'}}>Esse endereço de email ja se encontra em nosso sistema</span>}
                                </div>
                            </div>
                            {/*<div class="col-12 col-md-6">
                                <div class="form-group">
                                    <label for="accountPassword">
                                        Senha *
                                    </label>
                                    <input class="form-control form-control-sm" id="accountPassword" type="password" onChange={this.handleChange('password')} placeholder="Senha" required="" />
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <label for="accountConfirmPassword">
                                        Confirmar Senha *
                                    </label>
                                    <input class="form-control form-control-sm" id="accountConfirmPassword" type="password" onChange={this.handleChange('password_confirmation')} placeholder="Confirmar Senha" required="" />
                                </div>
                            </div>*/}
                            <div class="col-12 mb-10"></div>
                            {this.renderPersonForm()}
                            <div class="col-12 mb-10"></div>

                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <label for="accountCEP">
                                        CEP *
                                    </label>
                                    <input class="form-control form-control-sm" id="accountCEP" type="text" onChange={this.handleChange('address_cep')} placeholder="CEP" required="" />
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <label for="accountLogradouro">
                                        Endereço *
                                    </label>
                                    <input class="form-control form-control-sm" id="accountLogradouro" type="text" value={this.state.address_street} placeholder="Endereço" required="" disabled />
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <label for="accountBairro">
                                        Bairro *
                                    </label>
                                    <input class="form-control form-control-sm" id="accountBairro" type="text" value={this.state.address_neighborhood} placeholder="Bairro" required="" disabled />
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <label for="accountEndNumber">
                                        Número
                                    </label>
                                    <input class="form-control form-control-sm" id="accountEndNumber" type="text" onChange={this.handleChange('address_number')} placeholder="Número" required="" />
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <label for="accountConfirmPassword">
                                        Complemento
                                    </label>
                                    <input class="form-control form-control-sm" id="accountConfirmPassword" type="text" onChange={this.handleChange('address_complement')} placeholder="Complemento" required="" />
                                </div>
                            </div>
                            <div class="col-12 mb-10"></div>
                            <span className="error pt-5 pb-5">{this.state.error_message}</span>
                            <div class="col-12">
                                <button class="btn btn-dark" type="submit"  disabled={this.state.email_invalid}>Cadastrar</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default withRouter(InsertCustomer);