

import React from 'react';

import HomeProductCard from '../HomeProductCard'

class ProductPageLook extends React.Component {

	constructor(props) {

		super(props);

    }

    render(){

        if(!this.props.look){
            return null;
        }

        return (
            <div class="container mb-11 pt-9 border-top">
                <h4 class="mb-10 text-center">{this.props.look.name}</h4>
                <div class="row">
                    {this.props.look.products.map((product, i) => {
                        return <HomeProductCard product={product} look={this.props.look || false} />
                    })}	
                </div>
			</div>
        )
    }
    
}

export default ProductPageLook;