import React, { useState } from 'react';

import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
	useParams
} from "react-router-dom";

import AuthService from './AuthService'

import CartSidenav from './CartSidenav'
import BudgetSidenav from './Budget/BudgetSidenav'


class CartBudget extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			usertype: false
		}
	}



	componentWillMount(){		

		AuthService.getUser()
		.then((user) => {
			this.setState({usertype: user.type})
		});
	}

	render() {

		switch( this.state.usertype ){
			case 'customer':
				return <CartSidenav/>
			
			case 'user':
				return <BudgetSidenav/>

			default:
				return null
		}
	}
}

export default CartBudget;