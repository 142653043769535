import React from 'react';
import { Link } from 'react-router-dom';

import AuthService from './AuthService';
import HomeProductCard from './HomeProductCard'
import ProductService from './ProductService';

const queryString = require('query-string');

class WeekNews extends React.Component {


  constructor(props){
    super(props);

    this.state = {
      products: [],
      show_price: false,
      userId: '',
      userType: '',
    }
  }

  componentDidMount(){
    ProductService.getWeekNews()
    .then((response) => {
        let filtered = response.data.filter((product) => {
          let tenDays = new Date(product.week_news_days);
          let today = new Date();

          if (tenDays > today){
            return product;
          }

        });

        this.setState({products: filtered});
    }, () => {
        this.setState({products: []})
    });

    AuthService.getUser()
    .then((user) => {
      this.setState({show_price: true, userId: user.id, userType: user.type});

    },() => {
      this.setState({show_price: false})
    });
  }

  render() {
    return (
      <>  
        { this.state.products.length > 0 && 
            <div class="container mb-11 pt-5 mt-10">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-10 col-lg-8 col-xl-6">
                       <h2 class="mb-10 text-center">Novidades da Semana</h2>
                    </div>
                </div>
                      <div class="row">
                            {this.state.products.map((product) => {
                                  return product ? <HomeProductCard product={product} show_price={this.state.show_price}/> : null;
                            })}
                      </div>
            </div>
          }
    </>
    )
}
}

export default WeekNews;
