import React, { useState } from 'react';

import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
	useParams
} from "react-router-dom";

import AuthService from './AuthService'

class AuthForm extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			authFormOpen: false,
			credentials: {
				email: null,
				password: null
			},
			lasterror: null
		}


	}

	componentDidMount(){
		AuthService.onRequestLogin(() => {
			this.setState({authFormOpen : true})
		})
	}

	handleCloseAuthForm = (e) => {
		this.setState({authFormOpen : false});
	}

	handleEmailChange = (e) => {
		this.state.credentials.email = e.target.value;
		this.setState({credentials: this.state.credentials});
	}

	handlePasswordChange = (e) => {
		this.state.credentials.password = e.target.value;
		this.setState({credentials: this.state.credentials});
	}

	handleSubmit = (e) => {
		e.preventDefault();
		this.setState({lasterror: null});
		AuthService.authenticate(this.state.credentials.email , this.state.credentials.password)
		.then(this.onAuthSucceed , this.onAuthFailed);
	}

	handleSigninClick = (e) => {
		this.setState({authFormOpen : false});
	}

	handleForgotPassword = (e) => {
		this.setState({authFormOpen : false});
	}

	onAuthSucceed = (result) =>{
		window.location.reload();
		this.setState({authFormOpen: false});
	}

	onAuthFailed = (error) => {
		this.setState({lasterror: error.message});
	}

	render() {
		return (
			<div className={this.state.authFormOpen ? 'modal fade show' : 'modal fade'} id="modalAuthForm" tabindex="-1" role="dialog" style={{ display: this.state.authFormOpen ? 'block' : 'none', paddingRight: '17px', backgroundColor: 'rgba(17,17,17,.3)' }} aria-modal="true">
				<div className="modal-dialog modal-dialog-center" role="document" style={{height: '100%' , marginTop: '0px' , maxWidth: '750px'}}>

					<div className="modal-content  login-modal">


						<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleCloseAuthForm}>
							<i className="fe fe-x" aria-hidden="true"></i>
						</button>


						<div className="modal-header line-height-fixed font-size-lg">
							<strong className="mx-auto">Login</strong>
						</div>




						<div className="modal-body row">
							<div className="col-12 col-md-6" style={{borderRightWidth: '2px', borderColor: 'black'}}>
								<form onSubmit={this.handleSubmit}>
									<input type="text" placeHolder="Email" name="email" onChange={this.handleEmailChange} class="custom-select mb-2" style={{background: 'none' , padding: '.875rem' , width: '100%'}} />
									<input type="password" placeHolder="Senha" name="password" onChange={this.handlePasswordChange}  class="custom-select mb-2" style={{background: 'none' , padding: '.875rem' , width: '100%'}} />

									<button className="btn btn-block btn-dark">Login</button>
									<div style={{marginBottom: '15px' , textAlign: 'center' , color: 'red'}}>{this.state.lasterror}</div>
								</form>
								<Link className="btn btn-block btn-outline-dark" to={'/esqueci-senha'} onClick={this.handleForgotPassword}>Esqueci minha senha</Link>
							</div>
							<div className="col-12 col-md-6">
								<p style={{textAlign: 'center'}}>Ainda não possui uma conta? Clique aqui e solicite seu cadastro</p>
								
								<Link to="/cadastro" onClick={this.handleSigninClick}><button className="btn btn-block btn-red" >Criar Conta</button></Link>
							</div>
						</div>

					</div>



				</div>
			</div>
		);
	}
}

export default AuthForm;