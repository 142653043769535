import React from 'react';

import { Link , withRouter} from "react-router-dom";

import CartService from '../CartService'
import PageEvent from '../PageEvent'
import ToastService from '../ToastService'

class CheckoutCart extends React.Component {


	constructor(props) {
		super(props);

		this.state = {
			cart: {
				subtotal: 0,
				items: [], 
				total: 0
			},
			payment: {
				observation: '',
				methods: []
			},
			discountCode: "",
			appliedDiscount: {
				name: "",
				value: 0,
				type: 0,
				appliedValue: 0
			},
		}


	}

	componentDidMount() {
		CartService.updateAllCartProducts();				
		PageEvent.addEventListener(PageEvent.events.CART_UPDATE , this.handleCartProductsUpdate);		
	}

	handleCartProductsUpdate = (cart) => {
		this.setState({ cart: cart });
		if (cart.desconto !== null){
			this.state.cart.total = cart.desconto.total.toFixed(2);
			this.setState({ appliedDiscount: cart.desconto.appliedDiscount, cart: this.state.cart })
		}
	}



	handleChangeObservation = (e) => {
		this.state.payment.observation = e.target.value;
		this.setState({ payment: this.state.payment });
	}

	handleCheckoutClick = (e) => {

		if (this.state.cart.subtotal <= 0 || this.state.cart.items.length <= 0){
			ToastService.sendMessage('Seu carrinho está vazio' , 'toast-error' , 2500);
			return null;
		}

		let payment = this.state.payment;

		CartService.orderCheckout(payment)
		.then(
			(result) => {				
				CartService.updateAllCartProducts();

				this.props.history.push({
					pathname: `/account/compra/${result.data.id}`
				});
			}
		).catch(
			(error) => {
				console.log(error)
				CartService.updateAllCartProducts();
			}
		)

	}

	handleChangeDiscount = (e) => {
		this.state.discountCode = e.target.value;
		this.setState({ discountCode: this.state.discountCode })
	}

	handleDiscountClick = () => {
		
		if (this.state.discountCode === ""){
			return null;
		}

		let discountCode = this.state.discountCode;

		CartService.addDiscountCart({ discount: discountCode })
		.then(
			(result) => {				
				this.state.appliedDiscount = result.data.appliedDiscount;
				this.state.cart.total = result.data.total.toFixed(2);
				this.setState({ cart: this.state.cart, appliedDiscount: this.state.appliedDiscount });
				ToastService.sendMessage('Desconto aplicado com sucesso!' , 'toast-success' , 2500);

		})
		.catch(
			(error) => {
				
				ToastService.sendMessage(error.response.data.message , 'toast-error', 2500);
			}
		)
	}

	removeDiscount = () => {
		
		CartService.removeDiscountCart()
		.then(
			(result) => {
				console.log(result.data)
				this.state.appliedDiscount = result.data.appliedDiscount;
				this.state.cart.total = result.data.total.toFixed(2);
				this.setState({ cart: this.state.cart, appliedDiscount: this.state.appliedDiscount });
				ToastService.sendMessage('Desconto removido', 'toast-success', 2500);

		})
		.catch(
			(error) => {
				ToastService.sendMessage('Ops! Algo errado aconteceu. Tente novamente', 'toast-error', 2500);
			}
		)
	}

	render() {
		return (
			<section class="pt-7 pb-12">
				<div class="container">
					<div class="row">
						<div class="col-12 text-center">

							<h3 class="mb-4">Checkout</h3>


						</div>
					</div>
					<div class="row">
						<div class="col-12 col-md-6 col-lg-5">

							<form>

								{/* <div class="list-group list-group-sm mb-7">
									<div class="list-group-item">


										<div class="custom-control custom-radio">


											<input class="custom-control-input" id="checkoutPaymentCard" name="payment" type="radio" data-toggle="collapse" data-action="show" data-target="#checkoutPaymentCardCollapse" />


											<label class="custom-control-label font-size-sm text-body text-nowrap" for="checkoutPaymentCard">
												Credit Card <img class="ml-2" src="assets/img/brands/color/cards.svg" alt="..." />
											</label>

										</div>

									</div>
									<div class="list-group-item collapse py-0" id="checkoutPaymentCardCollapse">


										<div class="form-row py-5">
											<div class="col-12">
												<div class="form-group mb-4">
													<label class="sr-only" for="checkoutPaymentCardNumber">Card Number</label>
													<input class="form-control form-control-sm" id="checkoutPaymentCardNumber" type="text" placeholder="Card Number *" required="" />
												</div>
											</div>
											<div class="col-12">
												<div class="form-group mb-4">
													<label class="sr-only" for="checkoutPaymentCardName">Name on Card</label>
													<input class="form-control form-control-sm" id="checkoutPaymentCardName" type="text" placeholder="Name on Card *" required="" />
												</div>
											</div>
											<div class="col-12 col-md-4">
												<div class="form-group mb-md-0">
													<label class="sr-only" for="checkoutPaymentMonth">Month</label>
													<select class="custom-select custom-select-sm" id="checkoutPaymentMonth">
														<option>January</option>
														<option>February</option>
														<option>March</option>
													</select>
												</div>
											</div>
											<div class="col-12 col-md-4">
												<div class="form-group mb-md-0">
													<label class="sr-only" for="checkoutPaymentCardYear">Year</label>
													<select class="custom-select custom-select-sm" id="checkoutPaymentCardYear">
														<option>2017</option>
														<option>2018</option>
														<option>2019</option>
													</select>
												</div>
											</div>
											<div class="col-12 col-md-4">
												<div class="input-group input-group-merge">
													<input class="form-control form-control-sm" id="checkoutPaymentCardCVV" type="text" placeholder="CVV *" required="" />
													<div class="input-group-append">
														<span class="input-group-text" data-toggle="popover" data-placement="top" data-trigger="hover" data-content="The CVV Number on your credit card or debit card is a 3 digit number on VISA, MasterCard and Discover branded credit and debit cards." data-original-title="" title="">
															<i class="fe fe-help-circle"></i>
														</span>
													</div>
												</div>
											</div>
										</div>

									</div>
									<div class="list-group-item">


										<div class="custom-control custom-radio">


											<input class="custom-control-input" id="checkoutPaymentPaypal" name="payment" type="radio" data-toggle="collapse" data-action="hide" data-target="#checkoutPaymentCardCollapse" />


											<label class="custom-control-label font-size-sm text-body text-nowrap" for="checkoutPaymentPaypal">
												<img src="assets/img/brands/color/paypal.svg" alt="..." />
											</label>

										</div>

									</div>

									
								</div> */}

								<textarea class="form-control form-control-sm mb-9 mb-md-0 font-size-xs" rows="5" value={this.state.payment.observation} onChange={this.handleChangeObservation} placeholder="Observações (opcional)"></textarea>

							</form>

						</div>
						<div class="col-12 col-md-6 col-lg-6 offset-lg-1">


							<h6 class="mb-7">Itens no Carrinho ({this.state.cart.items.length})</h6>


							<hr class="my-7" />


							<ul class="list-group list-group-lg list-group-flush-y list-group-flush-x mb-7">
								{this.state.cart.items.map(product => {
									return (<li class="list-group-item">
										<div class="row align-items-center">
											<div class="col-4">


												<Link to={`/produto/${product.product_id}`} href="#">
													<img src={process.env.REACT_APP_BASE_URL + (product.grid.cover_medium || '/img/no-picture.jpg')} alt="..." class="img-fluid" />
												</Link>

											</div>
											<div class="col">


												<p class="mb-4 font-size-sm font-weight-bold">
													<Link to={`/produto/${product.product_id}`} class="text-body" href="product.html">{product.name}</Link> <br />
													<span class="text-muted">R$ {product.price.toFixed(2)}</span> <br />
													<span class="text-muted">x{product.quantity} R$ {product.subtotal.toFixed(2)}</span>
												</p>


												<div class="font-size-sm text-muted">
													Cor: {product.grid.color_name}
												</div>

												<div class="font-size-sm text-muted">
													Tamanho: {product.grid.size_name}
												</div>

											</div>
										</div>
									</li>)
								})}
							</ul>


							<div class="card mb-9 bg-light">
								<div class="card-body">
									<ul class="list-group list-group-sm list-group-flush-y list-group-flush-x">
										<li class="list-group-item d-flex">
											<span>Subtotal</span> <span class="ml-auto font-size-sm">R$ {this.state.cart.subtotal.toFixed(2)}</span>
										</li>
										{this.state.cart.subtotal > 0 && 	
											<>
												{ this.state.appliedDiscount.appliedValue === 0 ?
												<li class='list-group-item d-flex'>	
													<div class="input-group">
														<input class="form-control" id="cupomDesconto" type="text" placeholder="Digite aqui seu cupom de desconto..." onChange={(e) => this.handleChangeDiscount(e)} />
														<button class="btn btn-block btn-red" onClick={() => this.handleDiscountClick()}>Aplicar Desconto</button>
													</div>
												</li>
												:
												<li class='list-group-item d-flex'>	
													<span> Cupom: </span>
													<span class="ml-auto">  <b>{ this.state.appliedDiscount.name }</b> ({ this.state.appliedDiscount.type === 1 ? this.state.appliedDiscount.value + "%" : "R$" + this.state.appliedDiscount.value } OFF)</span>
													<span class='ml-auto font-size-sm'><a href="#" onClick={() => this.removeDiscount()}> Remover </a></span>
												</li>	
												}
										<li class="list-group-item d-flex">
											<span>Desconto</span>
											<span class="ml-auto font-size-sm">R$ {this.state.appliedDiscount.appliedValue.toFixed(2)}</span>
										</li>
										</>									
										}
										{/*<li class="list-group-item d-flex">
											<span>Frete?</span> <span class="ml-auto font-size-sm">R$ 0.00</span>
										</li>*/}
										<li class="list-group-item d-flex font-size-lg font-weight-bold">
											<span>Total</span>
											{ this.state.appliedDiscount.appliedValue === 0 ?
											 	<span class="ml-auto">R$ {this.state.cart.subtotal.toFixed(2)}</span>
											:
												<span class="ml-auto"> R$ {this.state.cart.total } </span>
											 }
										</li>
									</ul>
								</div>
							</div>

							<p class="mb-7 font-size-xs text-gray-500">
								{/* Termos etc */}
            	</p>

							<button class="btn btn-block btn-dark" onClick={this.handleCheckoutClick}>Finalizar</button>

						</div>
					</div>
				</div>
			</section>
		);
	}
}

export default withRouter(CheckoutCart);

