import React, { useEffect, useState } from 'react';
import ReactAliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

import * as Icons from 'react-icons/fi';
import { Link } from 'react-router-dom';
import api from './api';


function HomeCategories(props) {
    const [update, setUpdate] = useState(0);
    const [categories, setCategories] = useState([]);
    const [carousel, setCarousel] = useState()

    const [categorySlider , setCategorySlider] = useState()


    useEffect(() => {

        api.get('/products/categories')
            .then(result => {
                let categoriesResult = [];
                result.data.map(category => categoriesResult.push({
                        name: category.name,
                        linkTo: '/procurar?categories='+category.id,
                        imgUrl: 'https://b2b.skenn.com.br'+category.image
                    })
                );
                setCategories(categoriesResult);
                console.log(categoriesResult);
                parseCategoryItems(categoriesResult)
            })

    }, [update])

    function parseCategoryItems(categories){

        setCategorySlider(categories.map(category =>
            <div className="category-item">
                <div className="category-item-image">
                    <Link to={category.linkTo}>
                        <div className='category-item-image-hover'>Ver Mais</div>
                        <img src={category.imgUrl} />
                    </Link>
                </div>
                <h1>{category.name}</h1>
            </div>
        ))
    }

    function renderCategoriesItems() {
        let items = categories.map(category =>
            <div className="category-item">
                <div className="category-item-image">
                    <Link to={category.linkTo}>
                        <div className='category-item-image-hover'>Ver Mais</div>
                        <img src={category.imgUrl} />
                    </Link>
                </div>
                <h1>{category.name}</h1>
            </div>
        )

        return items
    }

    const breakpoints = {
        0: { items: 3 },
        650: { items: 4 },
        850: { items: 5 },
        1024: { items: 6 },
        1200: { items: 8 },
    }


    return (
        <section className="categories mb-6 mt-6">
            <div className="container carousel-container">

                <ReactAliceCarousel
                    items={categorySlider}

                    responsive={breakpoints}
                    disableDotsControls={true}
                    disableButtonsControls={true}

                    dotsDisabled={true}
                    buttonsDisabled={true}
                    infinite={false}

                    ref={(el) => setCarousel(el)}
                />

                <div className='alice-carousel-custom-buttons'>
                    <button onClick={() => carousel.slidePrev()}><Icons.FiChevronLeft /></button>
                    <button onClick={() => carousel.slideNext()}><Icons.FiChevronRight /></button>
                </div>
            </div>

        </section>
    )
}


export default HomeCategories;