import React from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";

import CartService from './CartService'

class Category extends React.Component {

	constructor(props) {
		super(props);
	 }

  handleOnClick(e){
    CartService.openSideNav()
  }

  render() {
    return <h1>{this.props.match.params.category_id}</h1>;
  }
}

export default Category;