import React from 'react';

import WishlistService from '../WishlistService'

import { Link } from "react-router-dom";

class Wishlist extends React.Component {

  constructor(props) {
		super(props);

		this.state = {
			products: [],
		}
	}

  componentDidMount() {

		WishlistService.getMyWishlist()
		.then(response => {
			this.setState({products: response.data})
    });
    
  }

  removeFromWishlist = (product_id , index) => {

		WishlistService.removeFromWishlist(product_id)
		.then(response => {
      let products = this.state.products;
      products.splice(index , 1);
			this.setState({products: products})
    });

  }
  
  renderProducts() {

    return this.state.products.map((product , index) => {

      let cover = '/img/no-picture.jpg';

      if(product.covers[0]){
        cover = product.covers[0].medium;
      }

      return  (<div class="col-6 col-md-4">
          <div class="card mb-7">
            <div class="card-img">
              <button class="btn btn-xs btn-circle btn-white-primary card-action card-action-right" onClick={() => this.removeFromWishlist(product.id , index)}>
                <i class="fe fe-x"></i>
              </button>
              <Link class="btn btn-xs btn-block btn-dark card-btn" to={`/produto/${product.id}`}>
                <i class="fe fe-eye mr-2 mb-1"></i> Ver Detalhes
              </Link>
              <img class="card-img-top" src={process.env.REACT_APP_BASE_URL + cover}  />

            </div>

            <div class="card-body font-weight-bold text-center">
                <Link class="text-body" to={`/produto/${product.id}`}>{product.name}</Link><br/>            
                <span class="text-muted">R$ {product.price.toFixed(2)}</span>
            </div>
          </div>
        </div>)

    });

  }

  render() {
    return (
      <div class="row">      
          {this.renderProducts()}
      </div>
    );
  }
}

export default Wishlist;