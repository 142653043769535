import React, { useState } from 'react';

import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
	useParams
} from "react-router-dom";

import CartService from './CartService'
import PageEvent from './PageEvent'

class CartSidenav extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			sideNavOpen: false,
			cart: {
				subtotal: 0,
				items: []
			}
		}


	}

	componentDidMount(){

		CartService.updateAllCartProducts();

		PageEvent.addEventListener(PageEvent.events.CART_CLICK , (open) => {
			this.setState({sideNavOpen: open});
		});

		PageEvent.addEventListener(PageEvent.events.CART_UPDATE , (cart) => {
			this.setState({cart : cart});
		});

	}


	handleQuantityChange = (item) => {
		return (e) => {
			let quantity = parseInt(e.target.value);
			CartService.updateCartProduct({item: item , quantity: quantity});
		}
	}

	handleRemoveItem = (item) => {
		return (e) => {
			CartService.removeCartProduct({item: item});
		}
	}

	handleCloseSidenav = (e) => {
		this.setState({sideNavOpen : false});
	}

	render() {
		return (
			<div className={'modal fixed-right fade ' + (this.state.sideNavOpen ? 'show' : '')} id="modalShoppingCart" tabindex="-1" role="dialog" style={{ display: this.state.sideNavOpen ? 'block' : 'none', paddingRight: '17px', backgroundColor: 'rgba(17,17,17,.3)' }} aria-modal="true">
				<div className="modal-dialog modal-dialog-vertical" role="document">

					<div className="modal-content">


						<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleCloseSidenav}>
							<i className="fe fe-x" aria-hidden="true"></i>
						</button>


						<div className="modal-header line-height-fixed font-size-lg">
							<strong className="mx-auto">Seu Carrinho ({this.state.cart.items.length})</strong>
						</div>


						<ul className="list-group list-group-lg list-group-flush">

							{this.state.cart.items.map((product) => {
								return (<li className="list-group-item">
									<div className="row align-items-center">
										<div className="col-4">


											<a href="./product.html">
												<img className="img-fluid" src={process.env.REACT_APP_BASE_URL + (product.grid.cover_medium || '/img/no-picture.jpg')} alt="..." />
											</a>

										</div>
										<div className="col-8">


											<p className="font-size-sm font-weight-bold mb-6">
												<a className="text-body" href="#">{product.code} - {product.name}</a> <br />
												<span className="text-muted">Cor {product.grid.color_name}</span><br/>
												<span className="text-muted">Tam: {product.grid.size_name}</span><br/>
												<span className="text-muted">R$ {product.price.toFixed(2)}</span> <br />
												<span className="text-muted">x{product.quantity} R$ {product.subtotal.toFixed(2)}</span>
											</p>


											<div className="d-flex align-items-center">


												<input type="number" value={product.quantity} onChange={this.handleQuantityChange.bind(this)(product)} className="custom-select custom-select-xxs" style={{width: '80px'}} />


												<a className="font-size-xs ml-auto" href="#!" onClick={this.handleRemoveItem.bind(this)(product)}>
													<i className="fe fe-x"></i> Remover
												</a>

											</div>

										</div>
									</div>
								</li>)
							})}
						</ul>


						<div className="modal-footer line-height-fixed font-size-sm bg-light mt-auto">
							<strong>Subtotal</strong> <strong className="ml-auto">R$ {this.state.cart.subtotal.toFixed(2)}</strong>
						</div>


						<div className="modal-body">
							<Link to="/checkout" className="btn btn-block btn-dark" onClick={this.handleCloseSidenav}>Checkout</Link>
						</div>

					</div>

					

				</div>
			</div>
		);
	}
}

export default CartSidenav;