import React from 'react';

import {
    BrowserRouter,
    Switch,
    Route,
    Link,
    withRouter
  } from "react-router-dom";

import Orders from './Orders'
import Wishlist from './Wishlist'
import PersonalInfo from './PersonalInfo'
import Addresses from './Addresses'
import EditAddress from './EditAddress'
import AddAddress from './AddAddress'

import AuthService from '../AuthService'
import OrderDetails from './OrderDetails';
import Orcamentos from './Orcamentos';

import PageEvent from '../PageEvent'

import StoreCustomers from './StoreCustomers'
import MyCatalogs from './MyCatalogs'
import CatalogPage from '../CatalogPage'
import InsertCustomer from './InsertCustomer'
import CustomerDetails from './CustomerDetails'

class Account extends React.Component {


	constructor(props) {
        super(props);      
        
        this.state = {
            usertype: false
        }

        //PageEvent.addEventListener(PageEvent.events.LOGIN , (user) => {
			//this.setState({usertype: user.type});
		//});
    }



    componentWillMount() {

        AuthService.getUser()
        .then((user) => {            
            this.setState({usertype: user.type})
        } , () => {
            AuthService.requestLogin();
            this.props.history.push({pathname: '/home'});
        })
    }

    handleClickLogout = (e) => {
        AuthService.logout()
    }

    styleActiveMenu = (pathnames) => {

        if(!Array.isArray(pathnames)){
            pathnames = [pathnames]
        }

        return {fontWeight: pathnames.includes(this.props.location.pathname) ? 600 : 400};
    }

    renderAccountMenu(){

        if(this.state.usertype == 'customer'){

            return (
            <div class="list-group list-group-sm list-group-strong list-group-flush-x">
                <Link to="/account/compras" style={this.styleActiveMenu(['/account/compras' , '/account/'])} class="list-group-item list-group-item-action dropright-toggle">
                    Pedidos
                </Link>
                <Link to="/account/wishlist"  style={this.styleActiveMenu('/account/wishlist')} class="list-group-item list-group-item-action dropright-toggle">
                    Lista de Desejos
                </Link>
                {/*<Link to="/account/informacoes-pessoais" style={this.styleActiveMenu('/account/informacoes-pessoais')} class="list-group-item list-group-item-action dropright-toggle ">
                    Informações Pessoais
                </Link>
                <Link to="/account/enderecos" style={this.styleActiveMenu(['/account/enderecos' , '/account/editar-endereco' , '/account/adicionar-endereco'])} class="list-group-item list-group-item-action dropright-toggle ">
                    Endereços
                </Link>*/}
                <a class="list-group-item list-group-item-action dropright-toggle" onClick={this.handleClickLogout} href="javascript:;">
                    Logout
                </a>
            </div>)

        }else if(this.state.usertype == 'user'){

            return (
                <div class="list-group list-group-sm list-group-strong list-group-flush-x">
                    <Link to="/account/clientes" style={this.styleActiveMenu(['/account/clientes' , '/account/'])} class="list-group-item list-group-item-action dropright-toggle">
                        Clientes
                    </Link>
                    <Link to="/account/mycatalogs"  style={this.styleActiveMenu('/account/mycatalogs')} class="list-group-item list-group-item-action dropright-toggle">
                        Catálogos
                    </Link>
                    <Link to="/account/orcamentos"  style={this.styleActiveMenu('/account/orcamentos')} class="list-group-item list-group-item-action dropright-toggle">
                        Orçamentos
                    </Link>
                    <a class="list-group-item list-group-item-action dropright-toggle" onClick={this.handleClickLogout} href="javascript:;">
                        Logout
                    </a>
                </div>)

        }
    }

    renderAccountContent(){

        if(this.state.usertype == 'customer'){

            return (
                <Switch>                      

                    <Route path="/account/wishlist"  component={Wishlist} /> 
                    <Route path="/account/informacoes-pessoais"  component={PersonalInfo} /> 
                    <Route path="/account/enderecos"  component={Addresses} /> 

                    <Route path="/account/editar-endereco"  component={EditAddress} /> 
                    <Route path="/account/adicionar-endereco"  component={AddAddress} /> 

                    <Route path="/account/compra/:order_id"  component={OrderDetails} /> 

                    
                    <Route path={["/account" , "/account/compras"]} component={Orders} /> 
                </Switch>
            )

        }else if(this.state.usertype == 'user'){

            return (
                <Switch>                      
                    <Route path="/account/mycatalogs"  component={MyCatalogs} /> 
                    <Route path="/account/cadastrar-cliente" component={InsertCustomer} />
                    <Route path="/account/cliente/:cliente_id"  component={CustomerDetails} />
                    <Route path="/account/compra/:order_id"  component={OrderDetails} /> 

                    <Route path="/account/orcamentos"  component={Orcamentos} /> 

                    <Route path={["/account" , "/account/clientes"]}  component={StoreCustomers} />                                          
                </Switch>
            )

        }

    }

    render(){
        return (
            <section class="pt-7 pb-12 min-height-container">
            <div class="container">
                <div class="row">
                    <div class="col-12 text-center">
        
                    <h3 class="mb-10">Minha Conta</h3>
        
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-3">
        
                    <nav class="mb-10 mb-md-0">
                        {this.renderAccountMenu()}
                    </nav>
        
        
                    </div>
                    <div class="col-12 col-md-9 col-lg-8 offset-lg-1">
                        {this.renderAccountContent()}                
                    </div>
                </div>
            </div>
        </section>
        );
  }
}

export default withRouter(Account);