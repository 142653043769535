import React from 'react';

import ToastService from './ToastService';

class ToastContainer extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			message: false
		}

		this.timeoutHandler = false;

		ToastService.onReceiveMessage((message , className , delay = false) => {

			message = {text: message , className : className , delay: delay};

			this.setState({message : message} , () => {

				if(this.timeoutHandler){
					clearTimeout(this.timeoutHandler);
				}

				if(delay){

					this.timeoutHandler = setTimeout(this.closeMessage , delay);
				}

				return;
			});
		});
	}

	closeMessage = () => {
		this.setState({message: false});
	}


	render() {

		if(!this.state.message){
			return null;
		}

		let {text , className} = this.state.message;
		
		return <div className={`toast-message ${className || ''}`}>
			<button type="button" class="close" data-dismiss="modal" aria-label="Close" style={{ top: '5px' , right: '5px' }} onClick={this.closeMessage}>
				<i class="fe fe-x" aria-hidden="true"></i>
			</button>
			<a href={'/home'} >{text}</a>
		</div>
	};
}

export default ToastContainer;

