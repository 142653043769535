import React from 'react';

import { Link, Redirect } from "react-router-dom";

import logo from "./img/logo-skenn.png";

import ProductService from './ProductService'

const app_lgpd_storage_key = 'lgpd-consent__accept';

class PrivacyPoliticy extends React.Component {

	

	constructor(props) {

		super(props);

		this.state = {
			agreed: localStorage.getItem(app_lgpd_storage_key) == 1
		}

	}

	componentDidMount(){

	}

	agreePrivacyPoliticy(){
		localStorage.setItem(app_lgpd_storage_key , 1);
		this.setState({agreed: true})
	}

	render() {

		if(this.state.agreed){
			return null;
		}

		return (
			<div className="banner-lgpd-consent-container">
			  <div className="banner-lgpd-consent banner-lgpd-consent--show" > 
				<div id="lgpd-consent__text" className="banner-lgpd-consent__column"> 
				  Utilizamos cookies essenciais e tecnologias semelhantes de acordo com a nossa <a href="/" target="_blank" class="banner-lgpd-consent__link">Política de Privacidade</a> e, ao continuar navegando, você concorda com estas condições. 
				</div> 
				<div className="banner-lgpd-consent__column"> 
				  <button className="banner-lgpd-consent__accept" onClick={this.agreePrivacyPoliticy.bind(this)}>OK</button> 
				</div> 
			  </div> 
			</div>
		  )
	};
}

export default PrivacyPoliticy;

