import React from 'react';
import CatalogService from '../Catalog/CatalogService';

import CatalogCard from '../Catalog/CatalogCard'

class MyCatalogs extends React.Component {


  constructor(props){
    super(props);

    this.state = {
      catalogs: []
    }
  }

  componentDidMount(){
    this.loadCatalogs();
  }

  loadCatalogs(){

    CatalogService.getAvailableCatalogs()
    .then(response => {
      this.setState({catalogs: response.data})
    });

  }

  onDelete(){
    this.loadCatalogs();
  }

  render() {

    if(this.state.catalogs.length == 0){
      return <p style={{textAlign: 'center'}}>Você não possui nenhum catálogo.</p>
    }

    return (
      <>
        {this.state.catalogs.map(catalog => {
          return <CatalogCard catalog={catalog} onDelete={this.onDelete.bind(this)} />
        })}
      </>
    );
  }
}

export default MyCatalogs;