import React from 'react';

import { Router , Link , Redirect } from "react-router-dom";

import BudgetService from './BudgetService'
import Account from '../Account/Account'

class FindCustomer extends React.Component {


	constructor(props) {
		super(props);

		this.state = {
			cpfcnpj: '',
			name: '',		
			email:'',	
			external_id: null,
			local_id: null,
			notfound: false,
			invalid: false,
			error_message: ''
		}
	}


	componentDidMount() {

	}

	handleCpfCnpjChange(e){
		this.setState({cpfcnpj: e.target.value});
	}

	handleChangeEmail(e){
		this.setState({email: e.target.value});
	}

	handleClickSearch() {

		this.setState({error_message: ''});

		BudgetService.findCustomer({cpfcnpj: this.state.cpfcnpj})
		.then((response) => {
			
			this.setState({
				name: response.data.nm_pessoa,
				external_id: response.data.cd_pessoa,
				invalid: response.data.invalid,
				email: response.data.email,
				local_id: response.data.local ? response.data.local.id : false,
				notfound: false
			});

		} , (error) => {

			this.setState({
				name: '',
				external_id: null,
				invalid: false,
				email: '',
				local_id: false,
				notfound: true,
				error_message: error.response.data.message
			});
		})
	}



	handleClickConfirm() {

		if(this.state.local_id){
			//
			BudgetService.setCurrentCustomer(this.state.local_id);
			this.props.modal_window.close(this.state);
		}else{
			BudgetService.createLocal(this.state.external_id , this.state.email)
			.then((response) => {
				BudgetService.setCurrentCustomer(response.data.id);	
				this.props.modal_window.close(this.state);
			}, (error) => {
				this.setState({error_message: error.response.data.message});
			});
		}

		
	}

	handleClickCancel() {
		this.props.modal_window.close(false);
	}


	renderErrorMessage(){
		if(this.state.invalid){
			return (
				<div className="text-center mb-8">
					<span>Cliente já possui cadastro em outra loja</span>					
				</div>
			)
		}else{
			return (
				<div className="text-center mb-8">
					<span>{this.state.error_message}</span>					
				</div>
			)
		}
	}
	


	render() {
		return (
			<div class="modal-dialog modal-dialog-centered modal-x2" role="document">
			  <div class="modal-content customer-finder-modal p-7">	
			  		<h5 className="text-center">Digite o CPF ou CNPJ</h5>	
					  <button type="button" class="close" onClick={this.handleClickCancel.bind(this)}><i class="fe fe-x" aria-hidden="true"></i></button>			
			  		<div class="mb-3 mt-3">
					  	<label for="accountCNPJ">CPF/CNPJ *</label>
					  	<div class="form-inline">
							<input class="form-control" style={{flex: 1}} onChange={this.handleCpfCnpjChange.bind(this)} id="accountCNPJ" type="text"/>
							<button class="btn btn-blue" style={{float: 'right' , marginLeft: '6px'}} onClick={this.handleClickSearch.bind(this)}>Procurar</button>
						</div>	
						<div class="form-group mt-5">
							<label for="accountCNPJ">Código *</label>
							<input class="form-control form-control-sm" id="accountCNPJ" type="text" readonly disabled value={this.state.external_id} />
						</div> 		
						<div class="form-group">
							<label for="accountCNPJ">Nome *</label>
							<input class="form-control form-control-sm" id="accountCNPJ" type="text" readonly disabled value={this.state.name}/>
						</div> 		
						<div class="form-group">
							<label for="accountCNPJ">Email *</label>
							<input class="form-control form-control-sm" id="accountCNPJ" type="text"  value={this.state.email} onChange={this.handleChangeEmail.bind(this)}/>
						</div> 									
					</div>
					{this.renderErrorMessage()}
					<div class="mb-8">						
						<button class="btn btn-green" disabled={this.state.notfound || this.state.invalid} style={{float: 'right' , width: '100%'}} onClick={this.handleClickConfirm.bind(this)}>Confirmar</button>
					</div>
					<div class="" style={{ textAlign: 'center' }}>
						<span>Não encontrou o cliente que procurava? </span>
						<br />
						<a class="color-blue" href={`/account/cadastrar-cliente?cpfCnpj=${this.state.cpfcnpj}`}>Cadastre um novo cliente</a>
					</div>
				</div>
			</div>)
	}
}

export default FindCustomer;

