import React from 'react';

import { Link , Redirect , withRouter } from "react-router-dom";

import BudgetService from  '../Budget/BudgetService'
import OrderService from '../Orders/OrderService'
import OrderCard from  '../Orders/OrderCard'
import CustomerCard from  '../Budget/CustomerCard'

class CustomerDetails extends React.Component {

	constructor(props) {
        super(props);     
        
        this.state = {
			customer: {
				fullname: '',
				external_id: null,
				external: {
					nm_pessoa: ''
				}
			},
			orders: []
		}		
	}

	componentWillMount(){

		BudgetService.getCustomer(this.props.match.params.cliente_id)
		.then((response) => {
			this.setState({customer: response.data})
		});

		OrderService.getCustomerOrders(this.props.match.params.cliente_id)
		.then((response) => {
			this.setState({orders: response.data})
		});
	}

	renderOrders(){
		if(this.state.orders.length == 0){
			return <p class="text-center">Nenhum pedido encontrado</p>
		}

		let orders = [];

		this.state.orders.map((order) =>{
			orders.push(<OrderCard order={order} />)
		});

		return orders;
	}

  	render(){
    return (
		<div>
			<h5 class="text-center">Dados do Cliente</h5>
			<CustomerCard customer={this.state.customer} complete={true}/>
			<h5 class="text-center mt-5">Pedidos</h5>
			{this.renderOrders()}
		</div>
	);
  }
}

export default withRouter(CustomerDetails);