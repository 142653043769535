import api from './api';

import ReactDOM from 'react-dom';
import CartService from './CartService'
import { reject } from 'lodash';

function AuthServiceClass(){

	var tokenName = 'token';

	var user = null;


	this.getUser = () => {
		return _promise;
	}

	this.loadUser = () => {
		return api.get('user');
	}


	var _promise = new Promise((resolve , reject) => {

		this.loadUser()
		.then((response) => {
			user = response.data;	
			resolve(user);	
		})
		.catch((error) => {
			user = false;
			reject(false)
		})

	})


	this.requestLogin = () => {
		this.onRequestLoginCallback();
	}

	this.getAuthToken = () => {
		return localStorage.getItem(tokenName) || null;
	}

	this.setAuthToken = (token) => {
		localStorage.setItem(tokenName , token);	
	}

	this.findAddress = (cep) => {	
		return api.post("find-address" ,{cep: cep});
	}

	this.onRequestLoginCallback = () => {}
	this.onRequestLogin = (callback) => {
		this.onRequestLoginCallback = callback;
	}

	this.logout = () => {
		// window.location.assign('/');
		// return;

		api.get('/logout')
		.then((response) => {
			localStorage.removeItem(tokenName);
			user = null;
			window.location.assign('/');
		})
		.catch((error) => {

		})
	}

	this.authenticate = (email , password) => {

		return new Promise(function(resolve , reject){

			api.post('login' , {email: email , password: password})
			.then(function (response) {
				localStorage.setItem(tokenName , response.data.token);	
				CartService.updateAllCartProducts();	
				resolve(response.data)
			})
			.catch(function (error) {
				if(error.response){
					//reject({message: error.response.data})
					console.info(error.response.data)
					reject(error.response.data)
				}else{
					console.log('server error')
					reject({message: 'Server Error'});
				}

			})

		});
					
	}

	this.signin = (data) => {
		return api.post('signin' , data);				
	}

	this.validateEmail = (email) => {
		return api.post('validate-email' , {email: email});
	}

	this.forgotPassword = (email) => {
		return api.post('forgot-password' , {email: email});
	}
	
	this.recoverPassword = (data) => {
		return api.post('recover-password' , data);
	}

	this.createPassword = (data) => {
		return api.post('create-password' , data);
	}

	this.validateToken = (token) => {
		return api.post('validate-token', {token: token});
	}
}

const AuthService = new AuthServiceClass


export default AuthService