import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash'

import ModalWindow from './ModalWindow'

import ProductQuickView from './ProductQuickView'

import AuthService from './AuthService'

import WishlistService from './WishlistService'
import CatalogWindow from './Catalog/CatalogWindow'

import {
	BrowserRouter,
	Switch,
	Route,
	Link,
	useParams
  } from "react-router-dom";

class LookProductCard extends React.Component {

	constructor(props) {
        super(props);     
        
        this.state = {
			showOpts: false,
			grid: {},
			cover: {},

			size: null,
			sizes: [],

			colors: []
		}		
	}

	componentDidMount() {

		AuthService.getUser()
		.then(user => {
			this.setState({usertype: user.type})
		});

		this.setupProduct();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.product.id !== this.props.product.id) {
			this.setupProduct();
		}
	}

	handlePriceClick = () => {
		//AuthService.requestLogin();
	}

	setupProduct() {
		let first_grid = this.props.product.grid[0];

		for (var i = 0; i < this.props.product.grid.length; i++){
			if (this.props.product.grid[i].num_images > 0){
				first_grid = this.props.product.grid[i];
				break;
			}
		}
		// this.props.product.grid.map(grid => grid.num_images > 0 ? first_grid = grid : null);

		this.updateColor(first_grid.color_id);
		this.updateAvailableColors();
	}

	updateAvailableColors() {

		let available_colors = _.uniqBy(this.props.product.grid.map(grid => {
			return { id: grid.color_id, name: grid.color_name, rgb: grid.color_rgb }
		}), 'id');

		this.setState({ colors: available_colors });
	}

	updateColor(color_id) {

		let selected_grid = this.props.product.grid.filter(grid => grid.color_id == color_id)[0]
		this.setState({ grid: selected_grid })

		//Updating Picture based on selected color
		let cover = this.props.product.covers.filter(cover => cover.color_id == color_id)
		if (cover[0]) {
			this.setState({ cover: cover[0].file });
		} else {
			this.setState({ cover: '/img/no-picture.jpg' });
		}

		//Updating sizes from new selected color
		let available_sizes = this.props.product.grid
			.filter(grid => grid.color_id == selected_grid.color_id)
			.map(grid => {
				return { id: grid.size_id, name: grid.size_name }
			});

		this.setState({ sizes: available_sizes })

	}

	handleColorClick = (color_id) => {
		return () => this.updateColor(color_id)
	}

    productMouseEnter = (e) => this.setState({showOpts : true})

	productMouseLeave = (e) => this.setState({showOpts : false})

	handleQuickViewClick = (e) => {
		//ModalWindow.open(<ProductQuickView product={this.props.product} />)	
		ModalWindow.open(ProductQuickView  , {product: this.props.product , hideImages: true})	
	}

	addToWishlist = () => {
		WishlistService.addToWishlist(this.props.product.id)
	}

	handleCatalogClick = (e) => {

		ModalWindow.open(CatalogWindow  , {product_id: this.props.product.id})

	}
	
	renderPrice(){
		if(this.props.show_price){
			return (<span class="font-size-lg text-primary">{this.props.product.active ? `R$ ${this.props.product.price.toFixed(2)}` : 'Indisponível'}</span>);
		}else{
			return (<span class="font-size-lg text-primary"><a href="javascript:;" onClick={() => AuthService.requestLogin()}>Ver Preço</a></span>);
		}
	}

	renderCardActions(){

		switch(this.state.usertype){
			case 'user':
				return (
						<button class="btn btn-xs btn-circle btn-white-primary card-action card-action-right" onClick={this.handleCatalogClick}>
							<i class="fe fe-book-open"></i>
						</button>
				);

			case 'customer':
				return (
						<button class="btn btn-xs btn-circle btn-white-primary card-action card-action-right" data-toggle="button" onClick={this.addToWishlist}>
							<i class="fe fe-heart"></i>
						</button>
				);				
		}

	}

    render() {
        return (
            <div class="card">


				  
					<div class="badge card-badge text-uppercase" style={{ backgroundColor: this.props.product.badge[1] || process.env.REACT_APP_DEFAULT_BADGEBC , color: this.props.product.badge[2] || process.env.REACT_APP_DEFAULT_BADGETC }}>
						{this.props.product.badge[0]}
					</div>
					

                  <div class="card-img">
					
                    {/*<button class="btn btn-xs btn-circle btn-white-primary card-action card-action-right" data-toggle="button">
                      <i class="fe fe-heart"></i>
                    </button>*/}
					{this.renderCardActions()}
					
                    <img class="card-img-top" src={process.env.REACT_APP_BASE_URL + this.state.cover} alt="..." />	

					{/*<button class="btn btn-xs btn-block btn-dark card-btn" onClick={this.handleQuickViewClick}>
                		<i class="fe fe-eye mr-2 mb-1"></i> Ver
					</button>*/}						
                  </div>


                  <div class="card-body font-weight-bold text-center">
                    <Link to={{pathname: `/produto/${this.props.product.id}` , product: this.props.product , look: this.props.look || false}} class="font-size-xxs text-body nowrap" >{this.props.product.name}</Link>
                    <div>
						{this.renderPrice()}
                    </div>

					<div class="row">
						<div class="mb-1 p-2 col-12">
							<span>COR: </span>
							{this.state.colors.map((color) => {
								return(<div class="custom-control custom-control-inline custom-control-color" style={{paddingTop: '5px'}}>
									<a href="javascript:" className={(color.id == this.state.grid.color_id) ? 'custom-control-label custom-control-label-active' : 'custom-control-label'} onClick={this.handleColorClick(color.id)} style={{color: color.rgb}}></a>
								</div>)
							})}						

							<span style={{marginLeft: '5px'}}>TAM: </span>
							{this.state.sizes.map(function(size){
								return(<div class="custom-control custom-control-inline custom-control-text font-size-xs">
									<input type="radio" id="productOneSizeOne" name="sizeRadio" class="custom-control-input"/>
									<label class="custom-control-label" for="productOneSizeOne">{size.name}</label>
								</div>)
							})}
						</div>
					</div>

                  </div>




                </div>
        )
    }


}


export default LookProductCard;