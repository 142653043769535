import React from 'react';

import {
    BrowserRouter,
    Switch,
    Route,
    Link,
    withRouter
} from "react-router-dom";



import AuthService from '../AuthService';
import SigninNaturalPerson from './SigninNaturalPerson';
import SigninJuridicalPerson from './SigninJuridicalPerson';
import ReactInputMask from 'react-input-mask';
import toast from 'react-hot-toast';

class Signin extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            type: 'j',
            name: null,
            email: null,
            password: null,
            password_confirmation: null,
            natural_person: {},
            juridical_person: {},

            address_cep: null,
            address_number: null,
            address_complement: null,
            address_neighborhood: null,
            address_street: null,

            error_message: null,
            email_invalid: false,

            phone: null,
            cellphone: null
        }

    }

    onBlurEmail = (e) => {

        AuthService.validateEmail(this.state.email)
            .then((response) => {
                this.setState({ email_invalid: response.data.email_invalid });
            }, (error) => {
                this.setState({ error_message: error.response.data.message });
            })

    }

    handleSignin = (e) => {
        e.preventDefault();
        // this.setState({ error_message: null });

        toast.promise(AuthService.signin(this.state), {
            loading: 'Cadastrando...',
            error: (error) => error.response.data.message || 'Erro ao cadastrar',
        }).then((response) => {
            toast.remove();
            // redirect to 'sucesso-cadastro'
            this.props.history.push('/sucesso-cadastro');
            // toast.success('Cadastro realizado com sucesso!');
        }/* , (error) => {
            toast.remove();
            toast.error(error.response.data.message);
            // this.setState({ error_message: error.response.data.message });
        }) */)
    }

    handleFormChange(type) {
        return (person) => {
            // eslint-disable-next-line default-case
            switch (type) {
                case 'j':
                    this.setState({ juridical_person: person });
                    break;
                case 'f':
                    this.setState({ natural_person: person });
                    break;
            }
        }

    }

    handleChange = (field) => {
        return (e) => {
            let state = {};
            state[field] = e.target.value;

            field === 'phone' && (state[field] = parseInt(e.target.value.replace(/\D/g, '')));
            field === 'cellphone' && (state[field] = parseInt(e.target.value.replace(/\D/g, '')));

            if (field === 'address_cep') {
                state[field] = state[field].replace(/[^0-9]+/, '');

                if (state[field].length === 8) {
                    AuthService.findAddress(state[field])
                        .then((response) => {

                            console.log(response.data)

                            if (response.data.cep) {

                                let address = response.data;
                                this.setState({ address_neighborhood: address.bairro, address_street: address.nome_logradouro })

                            } else {

                            }

                        })
                }
            }

            this.setState(state)
        }
    }

    renderPersonForm() {

        switch (this.state.type) {

            case 'f':
                return <SigninNaturalPerson onChange={this.handleFormChange('f').bind(this)} />

            case 'j':
                return <SigninJuridicalPerson onChange={this.handleFormChange('j').bind(this)} />
        }

    }

    render() {
        return (
            <section class="pt-7 pb-12">
                <div class="container">
                    <div class="row ml-md-0 ml-md-0 ml-lg-12 mr-lg-12 mt-5">
                        <div class="col-12 col-md-12 col-lg-12">

                            <form onSubmit={this.handleSignin}>

                                <div class="row">

                                    {this.renderPersonForm()}

                                    {/* <div class="col-12 col-lg-6">

                                        <div class="form-group mb-8">
                                            <label>Tipo</label>
                                            <div class="btn-group-toggle" data-toggle="buttons">
                                                <label class="btn btn-sm btn-outline-border">
                                                    <input type="radio" name="gender" value="f" onClick={this.handleChange('type')} checked={this.state.type == 'f'} /> Física
                                                </label>
                                                <label class="btn btn-sm btn-outline-border">
                                                    <input type="radio" name="gender" value="j" onClick={this.handleChange('type')} checked={this.state.type == 'j'}/> Jurídica
                                                </label>
                                            </div>
                                        </div>

                                    </div> */}

                                    <div class="col-12 col-md-6">
                                        <div class="form-group">
                                            <label for="accountCEP">
                                                <b style={{ textTransform: 'uppercase', fontSize: '14px' }}>*CEP</b>
                                            </label>
                                            <ReactInputMask id="accountCEP" type="text" name="address_cep" className="form-control" mask="99999-999" placeholder="Ex.: 00000-000" defaultValue={this.state.address_cep || ''} onChange={this.handleChange('address_cep')} />
                                            {/* <input class="form-control form-control-sm" id="accountCEP" type="text" onChange={this.handleChange('address_cep')} placeholder="Ex.: 00000-000" /> */}
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <div class="form-group">
                                            <label for="accountLogradouro">
                                                <b style={{ textTransform: 'uppercase', fontSize: '14px' }}>*Logradouro</b>
                                            </label>
                                            <input class="form-control form-control-sm" id="accountLogradouro" type="text" value={this.state.address_street} placeholder="Insira o logradouro do endereço" disabled />
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <div class="form-group">
                                            <label for="accountBairro">
                                                <b style={{ textTransform: 'uppercase', fontSize: '14px' }}>*Bairro</b>
                                            </label>
                                            <input class="form-control form-control-sm" id="accountBairro" type="text" value={this.state.address_neighborhood} placeholder="Insira o nome do bairro" disabled />
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <div class="form-group">
                                            <label for="accountEndNumber">
                                                <b style={{ textTransform: 'uppercase', fontSize: '14px' }}>*Número</b>
                                            </label>
                                            <input class="form-control form-control-sm" id="accountEndNumber" type="number" onChange={this.handleChange('address_number')} placeholder="Ex.: 1234" />
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="accountConfirmPassword">
                                                <b style={{ textTransform: 'uppercase', fontSize: '14px' }}>Complemento</b>
                                            </label>
                                            <input class="form-control form-control-sm" id="accountConfirmPassword" type="text" onChange={this.handleChange('address_complement')} placeholder="Insira o complemento do endereço (caso haja)" />
                                        </div>
                                    </div>


                                    <div class="col-12 text-center">
                                        <h2 class="mt-4 mb-4">Dados Pessoais</h2>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <div class="form-group">
                                            <label for="accountEmail">
                                                <b style={{ textTransform: 'uppercase', fontSize: '14px' }}>*E-mail</b>
                                            </label>
                                            <input class="form-control form-control-sm" id="accountEmail" type="email" value={this.state.email} onChange={this.handleChange('email')} /* onBlur={this.onBlurEmail} */ placeholder="Insira seu e-mail" />
                                            {/* {this.state.email_invalid && <span style={{ color: 'red' }}>Esse endereço de email já se encontra em nosso sistema</span>} */}
                                        </div>

                                    </div>

                                    <div class="col-12 col-md-3">
                                        <div class="form-group">
                                            <label for="accountPhone">
                                                <b style={{ textTransform: 'uppercase', fontSize: '14px' }}>*Telefone</b>
                                            </label>
                                            <ReactInputMask type="text" name="phone" className="form-control" mask="(99) 9999-9999" defaultValue={this.state.phone || ''} onChange={this.handleChange('phone')} placeholder="Ex.: (11) 1111-1111" />
                                            {/* <input class="form-control form-control-sm" id="accountPhone" type="text" value={this.state.phone} onChange={this.handleChange('phone')} placeholder="Ex.: (11) 11111-1111" /> */}
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-3">
                                        <div class="form-group">
                                            <label for="accountPhone">
                                                <b style={{ textTransform: 'uppercase', fontSize: '14px' }}>*Celular</b>
                                            </label>
                                            <ReactInputMask type="text" name="cellphone" className="form-control" mask="(99) 99999-9999" defaultValue={this.state.cellphone || ''} onChange={this.handleChange('cellphone')} placeholder="Ex.: (11) 11111-1111" />
                                            {/* <input class="form-control form-control-sm" id="accountPhone" type="text" value={this.state.phone} onChange={this.handleChange('phone')} placeholder="Ex.: (11) 11111-1111" /> */}
                                        </div>
                                    </div>

                                    <div class="col-12 col-md-6">
                                        <div class="form-group">
                                            <label for="accountPassword">
                                                <b style={{ textTransform: 'uppercase', fontSize: '14px' }}>*Senha</b>
                                            </label>
                                            <input class="form-control form-control-sm" id="accountPassword" type="password" onChange={this.handleChange('password')} placeholder="Insira sua senha" />
                                        </div>

                                    </div>
                                    <div class="col-12 col-md-6">

                                        <div class="form-group">
                                            <label for="accountConfirmPassword">
                                                <b style={{ textTransform: 'uppercase', fontSize: '14px' }}>*Confirmar senha</b>
                                            </label>
                                            <input class="form-control form-control-sm" id="accountConfirmPassword" type="password" onChange={this.handleChange('password_confirmation')} placeholder="Insira a senha de confirmação" />
                                        </div>

                                    </div>

                                    {/* <div class="col-12 mb-5 text-center">
                                        <span style={{ color: 'red', textAlign: 'center' }}>{this.state.error_message}</span>
                                    </div> */}
                                    <div class="col-12 text-center">
                                        <button class="btn btn-dark" type="submit" disabled={this.state.email_invalid} style={{ textAlign: 'center' }}>Cadastrar</button>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default withRouter(Signin);