import api from '../api'

function OrderServiceClass(){


	this.getOrders = () => {
		return api.get("orders/list");
	}

	this.getCustomerOrders = (customer_id) => {
		return api.get(`orders/customer/${customer_id}`);
	}

	this.getOrderById = (order_id) => {
		return api.get(`orders/details/${order_id}`);
	}

}

const OrderService = new OrderServiceClass

export default OrderService