import React from 'react';

import HomeProductCard from './HomeProductCard'
import ProductService from './ProductService'
import LookProductCard from './LookProductCard';
import AuthService from './AuthService';

class Look extends React.Component {

	constructor(props) {
        super(props);      
        
        this.state = {
            active_product: props.look.products[0],
            show_price: false 
        }

    }

    componentDidMount(){

		AuthService.getUser()
		.then
		(
			() => {
				this.setState({show_price: true})
			},
			() => {
				this.setState({show_price: false})
			}
		)

    }

    handleTagClick = (product) => {
        return () => {
            this.setState({active_product: product})
        }
    }
    
    render() {
        return(                    
                <div class="row position-relative align-items-center">
                    <div class="col-md-7 col-lg-5 offset-lg-1">
                        <div class="w-100 look-product-cover">
                            {this.props.look.products.map((product) => {
                                return (<a className={'look-product-tag ' + (product.id == this.state.active_product.id ? 'active' : '')} href="javascript:;" onClick={this.handleTagClick(product).bind(this)} style={{left: product.tag_settings.x , top: product.tag_settings.y}}>{product.name.substring(0,15)}</a>)
                            })}
                            <img src={process.env.REACT_APP_BASE_URL + this.props.look.image} alt="..." class="img-fluid" />
                        </div>
                    </div>
                    <div className="col-md-5 col-lg-4 offset-lg-1 position-static">
                        <h6 class="heading-xxs text-center text-gray-400">
                            Look
                        </h6>
                        <h3 class="mb-3 text-center">
                            {this.props.look.name}
                        </h3>
                        <LookProductCard key={this.props.look.id} product={this.state.active_product} show_price={this.state.show_price} look={this.props.look} />
                    </div>
                </div>
            )
    }

}


export default Look;