/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';

import { Link, Redirect } from "react-router-dom";

import ReactAliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css'

import GalleryService from './GalleryService';
import * as Icons from 'react-icons/fi';


function HomeSlider(props){
	const [banners, setBanners] = useState([]);
	const [update, setUpdate] = useState(0);
	const [activeIndex, setActiveIndex] = useState(0);

	const [bannersSlider, setBannersSlider] = useState();
	

	useEffect(() => {

		GalleryService.getBanners()
		.then(response => {
			setBanners(response.data)
			renderItems(response.data)
		})
		}, [update])


	// componentDidMount(){
	// 	GalleryService.getBanners()
	// 	.then((response) => {
	// 		this.setState({banners: response.data})
	// 		console.log(response.data)
	// 	})
	// }

	
    // const slidePrev = () => {
	// 	let newIndex = activeIndex - 1;
	// 	console.log('prev new index: ' + newIndex)
	// 	setActiveIndex(newIndex);
	// 	console.log("active index: " + activeIndex)
	// }
	
    // const slideNext = () => {
	// 	let newIndex = activeIndex + 1;
	// 	console.log('next new index: ' + newIndex)
	// 	setActiveIndex(newIndex);
	// 	console.log("active index: " + activeIndex)
	// } 

	// const slideNext = () => { 
	// 	let newIndex = activeIndex + 1;

	// }

    const syncActiveIndex = ({item}) => {
		console.log("sync active index: " + item)
		setActiveIndex(item);
		console.log("active index: " + activeIndex)
	}


	function renderItems (banners) {
		setBannersSlider(banners.map((banner, index) => 
			
			<div key={index} class="w-100 bg-cover is-selected" style={{ position: 'relative', overflow: 'hidden'}} /*style={{ /*backgroundImage: `url('${process.env.REACT_APP_BASE_URL + banner.image}')`, minHeight: '65vh' }} */>
				<picture style={ { width: '100%',  objectFit: 'cover'} }>
					{/* <source media="(max-width: 500px)" srcset="" /> */}
					<img src={ process.env.REACT_APP_BASE_URL + banner.image } style={ { width: '100%' } } /> 
				</picture>
					<div style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>

						<div class="col-12 text-center text-white" style={{position: 'relative', top: '50%', transform: 'translateY(-50%)'}}>
							<div className='alice-carousel-custom-buttons' style={{ top:'calc(50% - 25px)' }}>
								<button onClick={() => setActiveIndex(index - 1)} className='btn-slider'><Icons.FiChevronLeft /></button>
								<button onClick={() => setActiveIndex(index + 1)} className='btn-slider'><Icons.FiChevronRight /></button>
							</div>


							{
								banner.enable_text ?
								<>
								<h4 class="mb-0" style={{fontSize: banner.title_font_size, color: banner.title_font_color, fontFamily: banner.title_font_family }}>{banner.title}</h4>
									<h1 class="display-1" style={{fontSize: banner.subtitle_font_size, color: banner.subtitle_font_color, fontFamily: banner.subtitle_font_family}}>
										{banner.subtitle} 
									</h1>
									<p class="mb-0"> 
									{
									banner.redirect_to !== null &&
										(banner.redirect_to && banner.redirect_to.indexOf('http') == 0 
										? 
										<a href={banner.redirect_to} target="_blank" class="link-underline text-reset mx-4 my-4">{banner.redirect_text}</a>
										:
										<Link to={banner.redirect_to} class="link-underline text-reset mx-4 my-4">{banner.redirect_text}</Link>
										)
									}
									</p>
								</>
									:
								null
							}
						</div>
						
					</div>
			</div>
		)
		)
	}

	return (
		<section>
			<div>
				<ReactAliceCarousel 

					items={bannersSlider}

					//fadeOutAnimation={true} 
					buttonsDisabled={true}
					autoHeight={true} 
					// autoPlay={true} 
					// autoPlayInterval={4000} 
					//duration={800}
					//activeIndex={activeIndex}
					slideToIndex={activeIndex}
					// renderPrevButton={renderPrevButton}
					// renderNextButton={renderNextButton}
					onSlideChanged={syncActiveIndex}
					

					//ref={(el) => setCarousel(el)}
					/>

			</div>
		</section>
	);
}

export default HomeSlider;

