import api from './api'

function PageServiceClass(){

	this.getPages = () => {
		return api.get("pages/list");
	}

	this.getPageByUrl = (data) => {
		return api.post("pages/by-url" , data);
	}
}

const PageService = new PageServiceClass

export default PageService