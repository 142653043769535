import api from './api'

function ProductServiceClass(){


	this.getProducts = () => {
		return api.get("products/list");
	}

	this.getProductById = (product_id) => {
		return api.get(`products/details/${product_id}`);
	}

	this.searchProducts = (q) => {
		return api.post("products/search" , q);
	}

	this.getLooks = () => {		
		return api.get("products/looks");
	}

	this.getCategories = () => {		
		return api.get("products/categories");
	}

	this.getCategorySpotlight = () => {		
		return api.get("products/categories-spotlight");
	}

	this.getImages = (product_id) => {		
		return api.get(`products/images/${product_id}`);
	}

	this.getStock = (product_id, token) => {		
		if (token.t != null){
			return api.get(`products/stock/${product_id}?t=${token.t}`);
		} else {
			return api.get(`products/stock/${product_id}`);
		}
		
	}

	this.getCategoryRelated = (category_id , product_id = '') => {		
		return api.get(`products/related/${category_id}/${product_id}`);
	}

	this.categories = false;	

	this.getCategoriesCached = () => {
		return new Promise((resolve , reject) => {

			if(this.categories !== false){
				resolve(this.categories)
			}else{
				this.getCategories()
				.then((response) => {
					this.categories = response.data;
					resolve(this.categories);
				})
				.catch((error) => {
					reject(error)
				});
			}

		});
	}

	this.getWeekNews = () => {
		return api.get(`/products/week-news`);
	}

	this.getSections = () => {
		return api.get(`/sections`);
	}

}

const ProductService = new ProductServiceClass

export default ProductService