import api from './api';

import PageEvent from './PageEvent'
import ToastService from './ToastService'

function CartServiceClass(){


	/** Itens */
	this.itens =  [];
	this.details = {
		subtotal: 0,
		items: []
	};	

	this.getItems = () => {
		return this.itens;
	}

	this.getCart = () => {
		return this.details;
	}

	/**
	 * Checkout
	 */
	this.orderCheckout = (payment_data) => {
		return api.post("cart/checkout" , payment_data);
	}

	/**
	 * Update Cart with current products
	 */
	this.updateAllCartProducts = () => {

		api.get("cart/items")
		.then(
			(result) => {				
				this.details = result.data;				
				PageEvent.triggerEvent(PageEvent.events.CART_UPDATE , this.details);				
			}
		)
		.catch(
			(error) => {
				  console.log(error)
				  //alert(error.response.data.message);
			}
		)
	}

	//this.updateAllCartProducts();

	/**
	 * Remove Product from Cart
	 * 
	 * @param {*} product 
	 */
	this.removeCartProduct = ( item ) => {

		api.post("cart/remove" , item)
		.then(
			(result) => {				
				this.details = result.data;
				PageEvent.triggerEvent(PageEvent.events.CART_UPDATE , this.details);
			}
		).catch(
			(error) => {
				  console.log(error)
				  alert(error.response.data.message);
			}
		)


	}	

	/**
	 * Update Product from Cart
	 * 
	 * @param {*} product 
	 */
	this.updateCartProduct = ( item ) => {

		api.post("cart/update" , item)
		.then(
			(result) => {
				this.details = result.data;
				PageEvent.triggerEvent(PageEvent.events.CART_UPDATE , this.details);
			}
		).catch(
			(error) => {
				  console.log(error)
				  alert(error.response.data.message);
			}
		)



	}

	/**
	 * Add item do cart
	 * 
	 * @param {Produto} item 
	 */
	this.addCartProduct = (item) => {
		

		api.post("cart/add" ,item)
		.then(
			(result) => {
				this.details = result.data;
				PageEvent.triggerEvent(PageEvent.events.CART_UPDATE , this.details);	
				ToastService.sendMessage('Peça Adicionada ao carrinho' , 'toast-success' , 2000);			
			}
		).catch(
			(error) => {
				  console.log(error)
				  //alert();
				  ToastService.sendMessage(error.response.data.message , 'toast-error');
			}
		)

		
	}

	this.addDiscountCart = (item) => {		
		return api.post("cart/add-discount", item)
	}

	this.removeDiscountCart = () => {

		return api.post('cart/remove-discount');
		//return api.post('cart/test-discount');
		
	}
		

}

const CartService = new CartServiceClass

export default CartService