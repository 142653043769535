import React, { useState } from 'react';



class ConfirmDialog extends React.Component {

	constructor(props) {
		super(props);

	}

	handleConfirm(){
		
		if(this.props.onConfirm){
			this.props.onConfirm();
		}

		this.close();
	}

	handleCancel(){
		
		if(this.props.onCancel){
			this.props.onCancel();
		}

		this.close();
	}

	close() {
		this.props.modal_window.close(false);
	}

	render() {
		return (
			<div class="modal-dialog modal-dialog-centered modal-x2" role="document">
			  <div class="modal-content customer-finder-modal p-7">	
				<h5 className="text-center">{this.props.title}</h5>
				<div className="mb-3 mt-3 text-center">
			  		{this.props.message}
				</div>
				<div className="col-12 mt-5">
					<button class="btn btn-red"  style={{float: 'left'}} onClick={this.handleCancel.bind(this)}>Cancelar</button>
					<button class="btn btn-green"  style={{float: 'right'}} onClick={this.handleConfirm.bind(this)}>Confirmar</button>
				</div>
			  </div>
			</div>
		);
	}
}

export default ConfirmDialog;