import React from 'react';

import { withRouter } from "react-router-dom";

import ProductService from './ProductService'
import SearchProductCard from './SearchProductCard'
import AuthService from './AuthService'

import ProductVoid from './ProductVoid'

const queryString = require('query-string');

class Search extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			
			categories: [],
			products: [],
			colors: [],
			sizes: [],
						
			total_produtos: 0,
			show_price: false,
		}
	
				
	}

	quantidadePorPagina = 12;

	shouldUpdate = true;

	filters = {
		pagina: 1,
		search_query: '',
		activeSizes: [],
		activeColors: [],
		activeCategories: []
	};

	parseFilters(){

		let parsedQueryString = queryString.parse(this.props.location.search);

		let queryCategories = (parsedQueryString.categories ? parsedQueryString.categories.split(',') : [])
		let queryColors = (parsedQueryString.colors ? parsedQueryString.colors.split(',') : [])
		//let querySizes = (parsedQueryString.sizes ? parsedQueryString.sizes.split(',') : [])

		this.filters = {
			pagina: parsedQueryString.pagina ? parsedQueryString.pagina : 1,
			search_query: parsedQueryString.q ? parsedQueryString.q : '',
			//activeSizes: querySizes,
			activeColors: queryColors,
			activeCategories: queryCategories.map(c => parseInt(c))
		}

		this.updateResults();

	}

	componentDidMount() {
	
		AuthService.getUser()
		.then(() => {
			this.setState({show_price: true})
		},() => {
			this.setState({show_price: false})
		});

		ProductService.getCategories()
		.then((result) => {
			this.setState({ categories: result.data });
		},(error) => {
			console.log(error)
		});

		this.parseFilters();
	}

	componentDidUpdate(prevProps, prevState) {
						
		if (prevProps.location.search != this.props.location.search) {
			this.parseFilters();
		}
	}

	updateFilters = (keep_page) => {

		if(keep_page !== true){
			this.filters.pagina = 1;
		}

		let params = {
			search_query: this.filters.search_query,
			//sizes: this.filters.activeSizes,
			colors: this.filters.activeColors,
			categories: this.filters.activeCategories,
			pagina: this.filters.pagina
		}

		this.props.history.push({
			pathname: '/procurar',
			//search: `?q=${params.search_query}&colors=${params.colors.join(',')}&sizes=${params.sizes.join(',')}&categories=${params.categories.join(',')}&pagina=${this.filters.pagina}`
			search: `?q=${params.search_query}&colors=${params.colors.join(',')}&categories=${params.categories.join(',')}&pagina=${this.filters.pagina}`
		});

	}

	updateResults(){

		let params = {
			search_query: this.filters.search_query,
			//sizes: this.filters.activeSizes,
			colors: this.filters.activeColors,
			categories: this.filters.activeCategories,
			pagina: this.filters.pagina
		}

		ProductService.searchProducts(params)
		.then((result) => {

			// let products = result.data.products.filter(prd => {
			// 	return prd.stk === true;
			// });

			//console.log(result.data.products);
			//console.log(products);
			//this.setState({ products: products });

			this.setState({ products: result.data.products });
			this.setState({ colors: result.data.colors });
			//this.setState({ sizes: result.data.sizes });
			this.setState({ total_produtos: result.data.total });
		},(error) => {
			console.log(error)
		});

	}


	handleClickCategoryFilter = (category_id) => {

		return (e) => {

			let category_index = this.filters.activeCategories.indexOf(category_id);

			if(category_index === -1){
				this.filters.activeCategories.push(category_id);
			}else{
				this.filters.activeCategories.splice(category_index, 1);
			}

			this.updateFilters();

		}

	}

	handleClickSearchQueryFilter = (e) => {
		this.filters.search_query = '';
		this.updateFilters();
	}

	handleClickColorFilter = (color_id) => {

		return (e) => {

			let color_index = this.filters.activeColors.indexOf(color_id);

			if(color_index === -1){
				this.filters.activeColors.push(color_id);
			}else{
				this.filters.activeColors.splice(color_index, 1);
			}

			this.updateFilters();

		}
	}

	handleClickSizeFilter = (size_id) => {

		return (e) => {

			let size_index = this.filters.activeSizes.indexOf(size_id);

			if(size_index === -1){
				this.filters.activeSizes.push(size_id);
			}else{
				this.filters.activeSizes.splice(size_index, 1);
			}

			this.updateFilters();

		}
	}


	handlePageClick(page){
		return (e) => {
			this.filters.pagina = page;
			this.updateFilters(true);
		}
	}

	renderPages(){

		let pages = Math.ceil(this.state.total_produtos / this.quantidadePorPagina);

		let pagination = [];

		let startPage = Math.max(1 , this.filters.pagina-2);
		let endPage = Math.min(startPage+4 , pages);

		if(startPage > 1 && pages > 0){
			pagination.push(
				<li className={'page-item'} style={{marginRight: '15px'}}>
					<a class="page-link" onClick={this.handlePageClick(1).bind(this)} href="#">1</a>
				</li>
			);
		}

		for(let page = startPage;page <= endPage;page++){

			pagination.push(
				<li className={'page-item ' + ( page == this.filters.pagina ? 'active' : '')}>
					<a class="page-link" onClick={this.handlePageClick(page).bind(this)} href="#">{page}</a>
				</li>
			);
		}

		if(endPage != pages){
			pagination.push(
				<li className={'page-item'}  style={{marginLeft: '15px'}}>
					<a class="page-link" onClick={this.handlePageClick(pages).bind(this)} href="#">{pages}</a>
				</li>
			);
		}

		return pagination;
	}

	renderProductCards(){
		if(this.state.products.length == 0){
			return <div className="col-12" style={{ textAlign: 'center' }}>Nenhum Produto Localizado</div>
		}

		return this.state.products.map((product, i) => {
			return <SearchProductCard product={product} show_price={this.state.show_price} colors={this.filters.activeColors}/>
		});
	}

	render() {
		return (
			<section class="py-8">
				<div class="container">
					<div class="row">
						<div class="col-12 col-md-4 col-lg-3">


							<form class="mb-10 mb-md-0">
								<ul class="nav nav-vertical" id="filterNav">
									<li class="nav-item">


										<a class="nav-link dropdown-toggle font-size-lg text-reset border-bottom mb-6 collapsed" data-toggle="collapse" href="#categoryCollapse" aria-expanded="false">
											Categorias
                  						</a>


										<div class="collapse" id="categoryCollapse">
											<div class="form-group">
												<ul class="list-styled mb-0" id="productsNav">
													{this.state.categories.map((category) => {
														return (
															<li class="list-styled-item">
																<a className={"list-styled-link" + (this.filters.activeCategories.includes(category.id) ? ' bold' : '')}  onClick={this.handleClickCategoryFilter(category.id).bind(this)} href="#">
																	{category.name}
																</a>
															</li>)
													})}
												</ul>
											</div>
										</div>

									</li>

									<li class="nav-item">

										<a class="nav-link dropdown-toggle font-size-lg text-reset border-bottom mb-6 collapsed" data-toggle="collapse" href="#colorCollapse" aria-expanded="false">
											Cores
  										</a>

										  <div class="collapse" id="colorCollapse">
											<div class="form-group">
												<ul class="list-styled mb-0" id="productsNav">
													{this.state.colors.map((color) => {
														return (
															<li class="list-styled-item">
																<div class="custom-control custom-control-inline custom-control-color">
																	<a href="javascript:" onClick={this.handleClickColorFilter(color.id).bind(this)} className={'custom-control-label custom-control-label2 ' + (this.filters.activeColors.includes(color.id) ? 'custom-control-label-active' : '')} style={{background: color.css , color: 'transparent'}}></a>
																		<span style={{ marginLeft: '10px' }}>{color.name}</span>
																</div>
															</li>)
													})}
												</ul>
											</div>
										</div>

  									</li>

									{/*<li class="nav-item">

										<a class="nav-link dropdown-toggle font-size-lg text-reset border-bottom mb-6 collapsed" data-toggle="collapse" href="#sizeCollapse" aria-expanded="false">
											Tamanhos
										</a>

										<div class="collapse" id="sizeCollapse">
											<div class="form-group">
												<ul class="list-styled mb-0" id="productsNav">
													{this.state.sizes.map((size) => {
														return (<div class="custom-control custom-control-inline custom-control-size mb-2"><a href="javascript:;" onClick={this.handleClickSizeFilter(size.id).bind(this)} className={'custom-control-label ' + (this.filters.activeSizes.includes(size.id) ? 'active' : '')}>{size.name}</a></div>)
													})}
												</ul>
											</div>
										</div>

									</li>*/}


								</ul>
							</form>

						</div>
						<div class="col-12 col-md-8 col-lg-9">


							<div class="row align-items-center mb-7">
								<div class="col-12 col-md">


									<h3 class="mb-1">Resultados para "{this.filters.search_query}"</h3>


									{/*<ol class="breadcrumb mb-md-0 font-size-xs text-gray-400">
										<li class="breadcrumb-item">
											<a class="text-gray-400" href="index.html">Busca</a>
										</li>
										<li class="breadcrumb-item active">
											{this.filters.search_query}
                  						</li>
												</ol>*/}

								</div>
								{/*<div class="col-12 col-md-auto">


									<select class="custom-select custom-select-xs">
										<option selected="">Ordenar</option>
									</select>

								</div>*/}
							</div>


							<div class="row mb-7">
								<div class="col-12">

									{(() => {

										if(this.filters.search_query){
											return (
												<span class="btn btn-xs btn-light font-weight-normal text-muted mr-3 mb-3">
												Pesquisa: "{this.filters.search_query}"
												<a class="text-reset ml-2" href="#!" role="button" onClick={this.handleClickSearchQueryFilter}>
													<i class="fe fe-x"></i>
												</a>
											</span>
											)
										}

									})()}


									{this.state.categories.filter((category) => { return this.filters.activeCategories.includes(category.id)} ).map((category) => {
										return (
											<span class="btn btn-xs btn-light font-weight-normal text-muted mr-3 mb-3">
												Categoria: {category.name}<a class="text-reset ml-2" href="#!" role="button" onClick={this.handleClickCategoryFilter(category.id).bind(this)}>
												<i class="fe fe-x"></i>
											</a>
										</span>)

									})}

									{/*this.state.sizes.filter((size) => { return this.filters.activeSizes.includes(size.id)} ).map((size) => {
										return (
											<span class="btn btn-xs btn-light font-weight-normal text-muted mr-3 mb-3">
												Tamanho: {size.name}<a class="text-reset ml-2" href="#!" role="button" onClick={this.handleClickSizeFilter(size.id).bind(this)}>
												<i class="fe fe-x"></i>
											</a>
										</span>)

									})*/}

									{this.state.colors.filter((color) => { return this.filters.activeColors.includes(color.id)} ).map((color) => {
										return (
											<span class="btn btn-xs btn-light font-weight-normal text-muted mr-3 mb-3">
												Cor: {color.name}<a class="text-reset ml-2" href="#!" role="button" onClick={this.handleClickColorFilter(color.id).bind(this)}>
												<i class="fe fe-x"></i>
											</a>
										</span>)

									})}

								</div>
							</div>


							<div class="row">

								{this.renderProductCards()}
								

							</div>


							<nav class="d-flex justify-content-center justify-content-md-end">
								<ul class="pagination pagination-sm text-gray-400">
									<li class="page-item">
										<a class="page-link page-link-arrow" href="#">
											<i class="fa fa-caret-left"></i>
										</a>
									</li>
									{this.renderPages()}
									<li class="page-item">
										<a class="page-link page-link-arrow" href="#">
											<i class="fa fa-caret-right"></i>
										</a>
									</li>
								</ul>
							</nav>

						</div>
					</div>
				</div>
			</section>
		);
	}
}

export default withRouter(Search);

