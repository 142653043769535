import React from 'react';




class Cart extends React.Component {
  render(){
    return (
      <h1>Cart</h1>
    );
  }
}

export default Cart;

