import React from 'react';

import {
    BrowserRouter,
    Switch,
    Route,
    Link,
    withRouter
  } from "react-router-dom";


import AuthService from '../AuthService'

const queryString = require('query-string');

class RecoverPassword extends React.Component {


	constructor(props) {
        super(props);      
        
        

        this.token = null;
        this.code = null;
        this.email = null;
        this.password = null;
        this.password_confirm = null;

        this.state = {
            error_message: null
        }
    }

    parseQueryString(){
        let parsedQueryString = queryString.parse(this.props.location.search);

        this.token = parsedQueryString.t;
    }

    componentDidMount(){
        this.parseQueryString();  
    }

	componentDidUpdate(prevProps, prevState) {
						
		if (prevProps.location.search != this.props.location.search) {
			this.parseQueryString();
		}
	}

    handleChange = (p) => {
        return (e) => {
            this[p] = e.target.value;
        }
    }

    handleChangeEmail = (e) => {
        this.email = e.target.value;
    }

    handleChangeCode = (e) => {
        this.code = e.target.value;
    }

    handleSubmit = (e) => {

        e.preventDefault();

        var data = {
            token: this.token,
            code: this.code,
            email: this.email,
            password: this.password,
            password_confirm: this.password_confirm
        }

        AuthService.recoverPassword(data)
        .then((response) => {
            this.setState({success_message: 'Senha atualizada com sucesso!' , error_message: null});
        },(error) => {
            this.setState({error_message: error.response.data.message , success_message: null});
        });

    }

    render() {
        return (
            <section class="pt-7 pb-12" style={{height: '900px'}}>
                <div class="container">
                    <div class="row">
                        <div class="col-12 text-center">
                            <h3 class="mb-10">Recuperação de Senha</h3>
                        </div>
                    </div>
                    <div class="row">

                        <div class="col-12  col-md-6" style={{padding: '2em'}}>

                            <form onSubmit={this.handleSubmit}>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="accountEmail">
                                                Email *
                                            </label>
                                            <input class="form-control form-control-sm" id="accountEmail" type="email" onChange={this.handleChangeEmail} placeholder="Digite o seu email" />
                                        </div>
                                        <div class="form-group">
                                            <label for="accountEmail">
                                                Codigo *
                                            </label>
                                            <input class="form-control form-control-sm" id="accountEmail" type="text" onChange={this.handleChangeCode} placeholder="Código" />
                                        </div>
                                        <div class="form-group">
                                            <label for="accountEmail">
                                                Nova Senha *
                                            </label>
                                            <input class="form-control form-control-sm" id="accountEmail" type="password" onChange={this.handleChange('password')} placeholder="Senha" />
                                        </div>
                                        <div class="form-group">
                                            <label for="accountEmail">
                                                Confirmar Senha *
                                            </label>
                                            <input class="form-control form-control-sm" id="accountEmail" type="password" onChange={this.handleChange('password_confirm')} placeholder="Confirmar Senha"  />
                                        </div>

                                    </div>
                                                          
                                    <div class="col-12">
                                        <button class="btn btn-dark" type="submit">Recuperar Senha</button><br/>
                                        <span style={{color: 'red' , marginLeft: '10px'}}>{this.state.error_message}</span>
                                        <span style={{color: '#03b6fb' , marginLeft: '10px'}}>{this.state.success_message}</span>
                                    </div>

                                    
                                </div>
                            </form>

                        </div>

                        <div class="col-12  col-md-6" style={{borderLeft: '1px solid #e5e5e5' , padding: '3em'}}>
                            <h4>Recuperação de senha</h4>
                            <p>Instruções de como recuperar senha conta</p>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default withRouter(RecoverPassword);