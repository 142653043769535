import React from 'react';

import OrderService from '../Orders/OrderService'
import OrderCard from  '../Orders/OrderCard'

class Orders extends React.Component {

	constructor(props) {
        super(props);     
        
        this.state = {
			orders: []
		}		
	}

	componentWillMount(){

		OrderService.getOrders()
		.then((response) => {
			this.setState({orders: response.data})
		})
	}


  	render(){
    return (
		<div>
			{this.state.orders.map((order) =>{
				return <OrderCard order={order} />
			})}
		</div>
	);
  }
}

export default Orders;