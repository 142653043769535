import React from 'react';

import { Link, Redirect } from "react-router-dom";

import logoskenn from "./img/logo-skenn.png";
import logopargan from "./img/logo-pargan.png";

import { AiOutlineFacebook, AiOutlineInstagram } from 'react-icons/ai';

import ProductService from './ProductService';
import PageService from './PageService';

import environment from './environment';

const logo = environment({
	pargan: logopargan,
	skenn: logoskenn,
})

const title = environment({
	pargan: 'Pargan',
	skenn: 'Skenn',
})

const email = environment({
	pargan: 'contato@pargan.com.br',
	skenn: 'contato@skenn.com.br',
})

const config = environment({
	pargan: {
		email:'contato@pargan.com.br' , 
		title: 'Pargan' , 
		logo: logopargan , 
		facebook:'https://pt-br.facebook.com/Parganofficial/' , 
		instagram:'https://instagram.com/parganofficial/'
	},
	skenn: {
		email:'contato@skenn.com.br' , 
		title: 'Skenn' , 
		logo: logoskenn , 
		facebook:'https://pt-br.facebook.com/Skennofficial/', 
		instagram:'https://instagram.com/skennofficial/'
	},
})

class Footer extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			categories: [],
			pages: []
		}

	}

	componentDidMount(){

		ProductService.getCategoriesCached()
		.then((categories) => {
			console.log(categories)
			this.setState({categories: categories});
		})

		PageService.getPages()
		.then((response) => {
			this.setState({pages: response.data});
		})
	}


	render() {
		return (
			<footer class="bg-dark bg-cover @@classList" >
				<div class="py-12 border-bottom border-gray-700">
					<div class="container">

						<div class="row">
							<div class="col-12 col-md-3">

								<img src={config.logo} style={{/* width: '200px' */}}/>
								{/* <img src={process.env.REACT_APP_BASE_URL + "/img/logojs-dark.png"} style={{ width: '175px', height: '50px' }}/> */}

								<ul class="list-unstyled list-inline mb-7 mb-md-0">
									<li class="list-inline-item">
										<a href={config.facebook} class="text-gray-350">
											<AiOutlineFacebook  size="24" />
										</a>
									</li>
									<li class="list-inline-item">
										<a href={config.instagram} class="text-gray-350">
											<AiOutlineInstagram size="24" />
										</a>
									</li>
									{/* <li class="list-inline-item">
										<a href="#!" class="text-gray-350">
											<i class="fab fa-youtube"></i>
										</a>
									</li>
									<li class="list-inline-item">
										<a href="#!" class="text-gray-350">
											<i class="fab fa-twitter"></i>
										</a>
									</li>
									<li class="list-inline-item">
										<a href="#!" class="text-gray-350">
											<i class="fab fa-medium"></i>
										</a>
									</li> */}
								</ul>

							</div>

							<div class="col-6 col-sm">

								<h6 class="heading-xxs mb-4 text-white">
									A {title}
              					</h6>

								<ul class="list-unstyled mb-0">
									{this.state.pages.map(page => {
									return ( page.footer && 
										 <li>
											<a class="text-gray-300" href={`/page/${page.url}`}>{page.name}</a>
										</li>
									)
									})}
								</ul>

							</div>

							<div class="col-6 col-sm">

								<h6 class="heading-xxs mb-4 text-white">
									Contato
              					</h6>

								<ul class="list-unstyled mb-0">
									{/*
										<li>
											<a class="text-gray-300" href="#!">(11) 2692-4069</a>
										</li>
										<li>
											<a class="text-gray-300" href="#!">(11) 93230-5637</a>
										</li>
									*/}
									<li>
										<a class="text-gray-300" href={`mailto:${config.email}`}>{config.email}</a>
									</li>
								</ul>

							</div>

							{/*<div class="col-6 col-sm">

								<h6 class="heading-xxs mb-4 text-white">
									Departamentos
								</h6>

								<ul class="list-unstyled mb-0">
									{this.state.categories.map(category => {
										return (
											<li>
												<a class="text-gray-300" href={`/procurar?categories=${category.id}`}>{category.name}</a>
											</li>
										);
									})}

								</ul>

								</div>*/}

						</div>
					</div>
				</div>
				<div class="py-6">
					<div class="container">
						<div class="row">
							<div class="col">

								<p class="mb-3 mb-md-0 font-size-xxs text-muted">
									© 2022 Todos os direitos reservados.
              					</p>

							</div>
						</div>
					</div>
				</div>
			</footer>
		)
	};
}

export default Footer;

