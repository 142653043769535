import React from 'react';


class ClipboardClass{


	copy(text){

		let textarea = document.createElement("textarea");
		textarea.style.position = 'absolute';
		textarea.style.top = '-1000px';
		textarea.style.left = '0px';

		document.body.appendChild(textarea);

		textarea.value = text;
		textarea.select()
    	document.execCommand("copy")

	}

}

let Clipboard = new ClipboardClass;

export default Clipboard;

