import React from 'react';

import OrderService from '../Orders/OrderService'

import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
	useParams
} from "react-router-dom";

class OrderDetails extends React.Component {


	constructor(props) {

		super(props);

		this.state = {
			order: {
				items: [],
				subtotal: 0
			}
		}

	}

	componentWillMount(){
	
		console.log(this.props.location.order)

		if(this.props.location.order){
			this.setState({order : this.props.location.order});
		}else{

			
			OrderService.getOrderById( this.props.match.params.order_id )
			.then(
				(result) => {
					console.log(result.data)
					this.setState({order : result.data});
				},
				(error) => {
					console.log(error)
				}
			)

		}
	}
	

  render() {
    return (
		<div>


		<div class="card card-lg mb-5 border">
		  <div class="card-body pb-0">


			<div class="card card-sm">
			  <div class="card-body bg-light">
			  <div class="row">
              <div class="col-6 col-lg-3">


                <h6 class="heading-xxxs text-muted">Pedido:</h6>

				<p class="mb-lg-0 font-size-sm font-weight-bold">
                  Cód: {this.state.order.id}
                </p>

                <p class="mb-lg-0 font-size-sm font-weight-bold" style={{display: this.state.order.external_id ? 'block' : 'none'}}>
                  Transação: {this.state.order.external_id}
                </p>

              </div>
              <div class="col-6 col-lg-3">


                <h6 class="heading-xxxs text-muted">Data da Compra:</h6>


                <p class="mb-lg-0 font-size-sm font-weight-bold">{this.state.order.date_creation}</p>

              </div>
              <div class="col-6 col-lg-3">


                <h6 class="heading-xxxs text-muted">Status:</h6>


                <p class="mb-0 font-size-sm font-weight-bold">
					{this.state.order.status}
                </p>

              </div>
              <div class="col-6 col-lg-3">


                <h6 class="heading-xxxs text-muted">Valor da Compra:</h6>


                
					{ this.state.order.discount === null ?
					<p class="mb-0 font-size-sm font-weight-bold">
                  		R$ {this.state.order.subtotal.toFixed(2)}
					</p>
					:
					<p class="mb-o font-size-sm font-weight-bold">
						R$ {(this.state.order.subtotal - this.state.order.discount_value).toFixed(2)}
					</p>
					}

              </div>
            </div>
			  </div>
			</div>

		  </div>
		  <div class="card-footer">


			<h6 class="mb-7">Itens ({this.state.order.items.length})</h6>


			<hr class="my-5" />


			<ul class="list-group list-group-lg list-group-flush-y list-group-flush-x" style={{height: '800px' , overflowY: 'auto' , overflowX: 'hidden'}}>
				{this.state.order.items.map((item) => {

					return (
					<li class="list-group-item">
						<div class="row align-items-center">
						<div class="col-4 col-md-3 col-xl-2">


							<Link to={`/produto/${item.product_id}`}><img src={process.env.REACT_APP_BASE_URL + (item.grid.cover_file || '/img/no-picture.jpg')} alt="..." class="img-fluid"/></Link>

						</div>
						<div class="col">


							<p class="mb-4 font-size-sm font-weight-bold">
								<Link to={`/produto/${item.product_id}`} class="text-body">{item.name}</Link> <br/>
								<span class="text-muted">R$ {item.subtotal.toFixed(2)}</span>
							</p>


							<div class="font-size-sm text-muted">
							Ref: {item.grid.id} <br />
							Valor Unitário: R$ {item.price.toFixed(2)} <br />
							Quantidade: {item.quantity} <br />
							Tamanho: {item.grid.size_name} <br />
							Cor: {item.grid.color_name}
							</div>

						</div>
						</div>
					</li>)
			  })}
			</ul>

		  </div>
		</div>


		<div class="card card-lg mb-5 border">
		  <div class="card-body">


			<h6 class="mb-7">Total do Pedido</h6>


			<ul class="list-group list-group-sm list-group-flush-y list-group-flush-x">
			  <li class="list-group-item d-flex">
				<span>Subtotal</span>
				<span class="ml-auto">R$ {this.state.order.subtotal.toFixed(2)}</span>
			  </li>
			  <li class="list-group-item d-flex">
				<span>Frete</span>
				<span class="ml-auto">R$ 0.00</span>
			  </li>
			  { this.state.order.discount === null ?
			  	<li class="list-group-item d-flex font-size-lg font-weight-bold">
				<span>Total</span>
				<span class="ml-auto">R$ {this.state.order.subtotal.toFixed(2)}</span>
			  </li>
			  :
			  <>
				<li class="list-group-item d-flex">
					<span>Desconto</span>
					<span class="ml-auto"> - R$ {Number.parseFloat(this.state.order.discount_value).toFixed(2)}</span>
					
				</li>
			  	<li class="list-group-item d-flex font-size-lg font-weight-bold">
				<span>Total</span>
				<span class="ml-auto">R$ {(this.state.order.subtotal - this.state.order.discount_value).toFixed(2)}</span>				
			  </li>
			  </>
			  }

			</ul>

		  </div>
		</div>


	
	  </div>
    );
  }
}

export default OrderDetails;