import React from 'react';

import {
    BrowserRouter,
    Switch,
    Route,
    Link,
    withRouter
} from "react-router-dom";

import Orders from './Orders'
import Wishlist from './Wishlist'
import PersonalInfo from './PersonalInfo'
import Addresses from './Addresses'
import EditAddress from './EditAddress'
import AddAddress from './AddAddress'

import AuthService from '../AuthService'


class SigninNaturalPerson extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            cpf: props.cpfCnpj || '',
            rg: null,
            birth: null,
            gender: 'male',
            phone: ''
        }


    }

    componentWillMount(){
        this.updateOnChange();
    }

    handleChange = (field) => {
        return (e) => {
            let state = {};
            switch(field){
                //case 'nascimento':
                    //state[field] = e.target.value.toLocaleString('pt-BR');
                    //break;

                default:
                    state[field] = e.target.value;

            }
        
            console.log(state)

            this.setState(state , this.updateOnChange(field));
        }
    }

    updateOnChange = (field) => {

        return () => {
            this.props.onChange(this.state , field)
        }
        
    }

    render() {
        return (
            <div className="col-12 row">
                <div class="col-12 text-center">
                     <h2 class="mb-10">Dados Pessoa Física</h2>
                </div>
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label for="accountCPF">CPF *</label>
                        <input class="form-control form-control-sm" id="accountCPF" type="text" value={this.state.cpf} onChange={this.handleChange('cpf')} placeholder="CPF" required />
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label for="accountRG">RG *</label>
                        <input class="form-control form-control-sm" id="accountRG" type="text" value={this.state.rg} onChange={this.handleChange('rg')} placeholder="RG"  required />
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label for="accountPhone">Telefone*</label>
                        <input class="form-control form-control-sm" id="accountPhone" type="text" value={this.state.phone} onChange={this.handleChange('phone')} placeholder="Telefone" required />
                    </div>
                </div>
                <div class="col-12 col-lg-6">

                    <div class="form-group">
                        <label>Data de Nascimento</label>
                        <input class="form-control form-control-sm" id="accountRG" type="date" value={this.state.birth} onChange={this.handleChange('birth')} placeholder="RG" required />

                    </div>

                </div>
                <div class="col-12 col-lg-6">

                    <div class="form-group mb-8">
                        <label>Gênero</label>
                        <div class="btn-group-toggle" data-toggle="buttons">
                            <label class="btn btn-sm btn-outline-border active">
                                <input type="radio" name="gender" value="male" onClick={this.handleChange('gender')} checked={this.state.gender == 'male'} /> Masculino
                            </label>
                            <label class="btn btn-sm btn-outline-border">
                                <input type="radio" name="gender" value="female" onClick={this.handleChange('gender')} /> Feminino
                            </label>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default SigninNaturalPerson;