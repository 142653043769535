import axios from 'axios';

axios.defaults.headers = {
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '0',
};


//axios.defaults.baseURL = 'http://localhost:8080/api/v1/';
//axios.defaults.baseURL = 'https://b2b.justisecure.com.br/api/';
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
//axios.defaults.baseURL = 'https://b2b.pargan.com.br/api/';
//axios.defaults.headers.post['Content-Type'] = 'application/json';



let api = axios.create();


api.interceptors.response.use((response) => {
  //AuthService.setAuthToken(response.headers.Authorization);
  return response;
});

api.interceptors.request.use((request) => {
    request.headers.Authorization = `${localStorage.getItem('token') || null}`;
    return request;
});


function apiClass(){

  var baseURL = process.env.REACT_APP_API_URL;

  this.post = (url , data) => {

    return new Promise(function(resolve , reject){
      alert(baseURL + url)

      fetch(baseURL + url, {
        method: 'POST',
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(data => { resolve({data: data}) });

    })

  }

  this.get = (url) => {

    return new Promise(function(resolve , reject){

      fetch(baseURL + url)
      .then(response => response.json())
      .then(data => { resolve({data: data}) });

    })

  }

}

//api = new apiClass;



export default api;
