/* eslint-disable no-restricted-globals */
import React from 'react';

import { Link , Redirect } from "react-router-dom";

import logoskenn from "./img/logo-skenn.png";
import logopargan from "./img/logo-pargan.png";

import CartService from './CartService'
import PageEvent from './PageEvent'
import AuthService from './AuthService'
import BudgetService from './Budget/BudgetService'

import ModalWindow from './ModalWindow'
import FindCustomer from './Budget/FindCustomer'
import ShareCatalogWindow from './Catalog/ShareCatalogWindow';

import environment from './environment';

const logo = environment({
	pargan: logopargan,
	skenn: logoskenn,
})

class NavBar extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			cartProductCount: 0,
			usertype: false,
			username: false,
		}		

	}

	componentDidMount(){
		PageEvent.addEventListener(PageEvent.events.CART_UPDATE , (cart) => {
			this.setState({cartProductCount : cart.items.length});
		});

		PageEvent.addEventListener(PageEvent.events.BUDGET_UPDATE , (budget) => {
			this.setState({cartProductCount : budget.items.length});
		});

		AuthService.getUser()
		.then((user) => {
			this.setState({usertype: user.type , username: user.full_name});
		});
	}

	handleOpenCart = (e) => {
		AuthService.getUser()
		.then(() => {
			PageEvent.triggerEvent(PageEvent.events.CART_CLICK , true);
		})
		.catch(() => {
			AuthService.requestLogin();
		})
		
	}

	handleOpenCatalogs = e => {
		ModalWindow.open(ShareCatalogWindow, {...this.props});
	}


	handleFindCustomer = () => {
		ModalWindow.open(FindCustomer  , {})	
	}


	renderWishlistButton(){
		if(this.state.usertype == 'customer'){
			return (
				<li className="nav-item ml-lg-n4">
					<Link to="/account/wishlist" className="nav-link" >						
						<i className="fe fe-heart"></i>						
					</Link>
				</li>
			)
		}
	}

	welcome = () => {
		let first_name = this.state.username ? this.state.username.split(' ')[0] : '';

		return `Olá ${first_name}`;
	}

	renderCustomerFinder(){

		if(this.state.usertype != 'user'){
			return null;
		}

		return (
			<div className="line-height-fixed  mt-5 navbar-customer-finder" style={{ fontSize: '14px' }}>
				{/*<i class="fe fe-x"></i>*/}
				{
					BudgetService.getCurrentCustomer().external_id !== undefined && BudgetService.getCurrentCustomer().external_id !== null 
					?
					<div style={{ width: '280px' , maxWidth: '280px' , overflow: 'hidden' , height:'24px' , position: 'relative' , float: 'left' , whiteSpace: 'nowrap'}}>{BudgetService.getCurrentCustomer().external_id + " - " + BudgetService.getCurrentCustomer().fullname}</div>
					:
					'Selecione um Cliente'
				}
				<a onClick={this.handleFindCustomer} href="#" class="ml-5">
					<i class="fe fe-search"></i>
			  	</a>
			</div>
		)
	}

	render() {
		return (
			// <nav className="navbar navbar-expand-lg navbar-light @@classList" style={{backgroundColor: "#1f1f1f"}}>
			// <nav className="navbar navbar-expand-lg navbar-light @@classList" style={{backgroundColor: 'rgb(255 236 233)'}} id="navbar-main">
			<nav className="navbar navbar-expand-lg navbar-light @@classList"  id="navbar-main">

				<div className="container" >

					

					<Link to="/" className="navbar-brand d-lg-none">
						<img src={logo} />
						{/* <img src="/static/media/logojs-dark.png" /> */}
        			</Link>

					<ul className="navbar-nav flex-row d-lg-none" style={screen.width < 380 ? { marginRight: '5px' , marginTop: '15px' } : { marginRight: '15px' , marginTop: '15px' }}>
							<li className="nav-item" style={screen.width < 380 ? {fontSize: '0.8rem'} : {}}>
								<Link to="/account/" className="nav-link ml-auto">
									{this.welcome()}
									<i className="fe fe-user"></i>									
								</Link>
							</li>
							{this.renderWishlistButton()}
							<li className="nav-item ml-lg-n4">
								<a className="nav-link" onClick={this.handleOpenCart} href="javascript:">
									<span data-cart-items={this.state.cartProductCount}>
										<i className="fe fe-shopping-cart"></i>
									</span>
								</a>
							</li>
						</ul>
				
					{/*<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarFashionTopCollapse" aria-controls="navbarFashionTopCollapse" aria-expanded="false" aria-label="Toggle navigation">
						<span className="navbar-toggler-icon"></span>
					</button>*/}

					{this.renderCustomerFinder()}


					{/* <Link className="navbar-brand mx-auto d-none d-lg-block" to="/home" style={{position: 'absolute', left: 20, top: 20, zIndex: 99999}}> */}
					{/* <Link className="navbar-brand mx-auto d-none d-lg-block" to="/home"> */}
						{/* <img src={'/static/media/logo-skenn.223f478b.png'} ></img> */}
						{/* <img src={process.env.REACT_APP_BASE_URL + "/img/logojs-dark.png"} style={{ width: '315px', height: '90px' }}/> */}
					{/* </Link> */}

					<div className="collapse navbar-collapse" id="navbarFashionTopCollapse">

						{/*<ul className="navbar-nav">
							<li className="nav-item">
								<Link className="nav-link text-uppercase active" to="/item1">Item 1</Link>
							</li>
							<li className="nav-item">
								<Link className="nav-link text-uppercase" to="/item2">Item 2</Link>
							</li>
							<li className="nav-item">
								<Link className="nav-link text-uppercase" to="/item3">Item 3</Link>
							</li>
						</ul>*/}

					<Link className="navbar-brand mx-auto d-none d-lg-block" to="/home" >
						{/*<img src={'/static/media/logo-skenn.223f478b.png'} ></img>*/}
						<img src={logo} />
						{/* <img src={process.env.REACT_APP_BASE_URL + "/img/logojs-dark.png"} style={{ width: '315px', height: '90px' }}/> */}
					</Link>

						<ul className="navbar-nav flex-row" >
							<li className="nav-item">
								<Link to="/account/" className="nav-link mx-auto">
									{this.welcome()}
									<i className="fe fe-user"></i>									
								</Link>
							</li>
							{this.renderWishlistButton()}
							<li className="nav-item ml-lg-n4">
								<a className="nav-link" onClick={this.handleOpenCart} href="javascript:">
									<span data-cart-items={this.state.cartProductCount}>
										<i className="fe fe-shopping-cart"></i>
									</span>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</nav>
		)
	};
}

export default NavBar;

