import React from 'react';

import { Link, Redirect, withRouter } from "react-router-dom";



import CartService from './CartService'
import AuthService from './AuthService'

import ProductService from './ProductService';
import ProductDetails from './ProductDetails';
//import ProductDetailsTest from './ProductDetailsTest';

import HomeProductCard from './HomeProductCard'

import Look from './Look';
import ProductPageLook from './ProductPage/ProductPageLook';
const queryString = require('query-string');

class ProductPage extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			loaded: false,
			product: {},
			related: []
		}

	}

	renderCount = 0;

	productLoaded = false;
	relatedLoaded = false;

	componentWillMount() {
		this.setupProduct();
	}

	componentDidUpdate(prevProps, prevState) {

		if (this.props.location.product && this.props.location.product.id != prevState.product.id) {
			this.renderCount = 0;
			this.relatedLoaded = false;
			this.productLoaded = false;
			this.setupProduct();
		}

	}

	shouldComponentUpdate() {
		let _should_update = (this.relatedLoaded && this.productLoaded);
		if (_should_update) {
			//this.relatedLoaded = false;
			//this.productLoaded = false;
		}
		return _should_update;
	}

	setupProduct() {

		if (this.props.location.product) {
			this.productLoaded = true;
			this.setState({ product: this.props.location.product }, this.loadRelated);
		} else {
			// load stock
			ProductService.getStock(this.props.match.params.product_id, queryString.parse(window.location.search)).then((result) => {
				this.stockLoaded = true;
				let { data } = result;
				// get all stock with value > 0
				let stock = Object.keys(data).filter(key => data[key] > 0).reduce((obj, key) => {
					obj[key] = data[key];
					return obj;
				}, {});
				// console.log('stock', stock);
				//this.setState({ stock: stock });

				ProductService.getProductById(this.props.match.params.product_id).then((result) => {
					this.productLoaded = true;
					let { data } = result;

					let products = data;

					// get all products with stock (products.grid.id === stock object key)
					let productsWithStock = products.grid.filter((product) => {
						return stock.hasOwnProperty(product.id);
					});

					// substitute products.grid to productsWithStock
					products.grid = productsWithStock;

					this.setState({
						product: products
					}, this.loadRelated);
				}, (error) => {
					console.log(error)
				});
			}, (error) => {
				this.stockLoaded = true;
				this.forceUpdate();
			});
		}
	}

	loadRelated() {

		this.relatedLoaded = true;

		ProductService.getCategoryRelated(this.state.product.category_id)
			.then(
				(result) => {
					this.setState({ related: result.data });
				},
				(error) => {
					this.forceUpdate();
				}
			)
	}



	handleOpenCart = (e) => {
		if (AuthService.isAuthenticated()) {
			CartService.openSideNav();
		} else {
			AuthService.requestLogin();
		}
	}

	renderDetails() {
		if (this.productLoaded) {
			return <ProductDetails product={this.state.product} />
		}
	}

	/*renderLook(){
		if(this.props.location.look){
			return (<section class="pt-11 border-top">
			<div class="container">
				<div class="col-12">
					<h4 class="mb-10 text-center">Confira Nossos Looks</h4>
					<div class="row">
						<Look look={this.props.location.look} />
					</div>
				</div>
			</div>
			</section>)
		}
	}*/

	renderRelated() {

		return (<div class="container mb-11 pt-9 border-top">
			<h4 class="mb-10 text-center">Produtos Relacionados</h4>
			<div class="row">
				{this.state.related.map((product, i) => {
					return <HomeProductCard product={product} />
				})}
			</div>
		</div>)

	}

	renderLook() {

		if (this.props.location.look) {
			return <ProductPageLook look={this.props.location.look} />
		}

	}

	render() {

		if (this.relatedLoaded && this.productLoaded)
			this.renderCount++;

		return (
			<section class="product-page-section">
				<div class="container-fluid">
					{this.renderDetails()}
				</div>
				<section class="pt-11">
					<div class="container">
						<div class="col-12">
							<div class="nav nav-tabs nav-overflow justify-content-start justify-content-md-center border-bottom">
								<a class="nav-link active" data-toggle="tab" href="#descriptionTab">
									Descrição
								</a>
								{/* <a class="nav-link" data-toggle="tab" href="#sizeTab">
									Tamanho
								</a> */}
							</div>
							<div class="tab-content">
								<div class="tab-pane fade active show">
									<div class="row justify-content-center py-9">
										<div class="col-12 col-lg-10 col-xl-8">
											<div class="row">
												<div class="col-12">
													<p class="text-gray-500">{this.state.product.description}</p>
												</div>
												{/* <div class="col-12 col-md-6">
													<ul class="list list-unstyled mb-md-0 text-gray-500">
														<li>
															<strong class="text-body">Código</strong>: {this.state.product.code}
														</li>
													</ul>
												</div> */}
												{/* <div class="col-12 col-md-6">
													<ul class="list list-unstyled mb-md-0 text-gray-500">
														<li>
															<strong class="text-body">Material</strong>: 100% Algodão
														</li>
													</ul>
												</div> */}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<ProductPageLook look={this.props.location.look} />
				{this.renderRelated()}
			</section>
		)
	};
}

export default withRouter(ProductPage);

