import api from './api'

class GalleryServiceClass{


	getBanners(){
		return api.get("gallery/banners");
	}

}

const GalleryService = new GalleryServiceClass

export default GalleryService