import React from 'react';

import HomeProductCard from './HomeProductCard'
import ProductService from './ProductService'

class HomeTopSellers extends React.Component {

	constructor(props) {
        super(props);      
        
        this.state = {
            products: []
        }

    }

    componentDidMount(){

        ProductService.getProducts()
        .then(
			(result) => {            
                this.setState({products : result.data});
			},
			(error) => {
			  	console.log(error)
			}
        )
    }

    render() {
        return (
            <section class="py-9">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-12 col-md-10 col-lg-8 col-xl-6">

                            <h6 class="heading-xxs mb-3 text-center text-gray-400">
                                Top selling
                            </h6>

                            <h2 class="mb-10 text-center">Top weekend Sellers</h2>

                        </div>
                    </div>
                    <div class="row">
                        {this.state.products.map((product , i) => {
                            return <HomeProductCard product={product}/> 
                        })}                                 
                    </div>
                    <div class="row">
                        <div class="col-12">

                            <div class="mt-7 text-center">
                                <a class="link-underline" href="https://yevgenysim.github.io/shopper/shop.html">Discover more</a>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        )
    }

}


export default HomeTopSellers;