function ToastServiceClass(){

	this.cb = () => {};

	this.onReceiveMessage = function( cb ){
		this.cb = cb;
	}

	this.sendMessage = function(message , className , delay){
		this.cb(message , className , delay);
	}
}

const ToastService = new ToastServiceClass;

export default ToastService;

