/* eslint-disable no-restricted-globals */
import React from 'react';

import {
    BrowserRouter,
    Switch,
    Route,
    Link,
    withRouter
  } from "react-router-dom";
import api from '../api';


import AuthService from '../AuthService'

const queryString = require('query-string');

class CreatePass extends React.Component {


	constructor(props) {
        super(props);      
        
        

        this.token = null;
        this.name = null;
        this.email = null;
        this.password = null;
        this.password_confirm = null;

        this.state = {
            error_message: null,
            authFormOpen: true,
            email: '',
            name: '',
        }
    }

    parseQueryString(){
        let parsedQueryString = queryString.parse(this.props.location.search);

        if (parsedQueryString.t == null) {
            window.location.assign('/home');
        }

        this.token = parsedQueryString.t;
    }

    validateToken = async() => {
        AuthService.validateToken(this.token)
        .then((response) => {
            this.email = response.data.email;
            this.name = response.data.name;

            this.setState({email: response.data.email, name: response.data.name})
        }, (error) => {
            this.setState({error_message: 'Token Inválido' , success_message: null});
            window.location.assign('/home');
        });
    }

    componentDidMount(){
        this.parseQueryString();
        this.validateToken();
    }

	componentDidUpdate(prevProps, prevState) {
						
		if (prevProps.location.search != this.props.location.search) {
			this.parseQueryString();
		}
	}

    handleChange = (p) => {
        return (e) => {
            this[p] = e.target.value;
        }
    }

    handleChangeEmail = (e) => {
        const email = e.target.value;

        this.setState({email: email});
    }

    handleCloseAuthForm = (e) => {
		this.setState({authFormOpen : false});
	}

    handleSubmit = (e) => {

        e.preventDefault();

        var data = {
            email: this.state.email,
            token: this.token,
            password: this.password,
            password_confirm: this.password_confirm
        }

        AuthService.createPassword(data)
        .then((response) => {
            this.setState({success_message: 'Senha cadastrada com sucesso!' , error_message: null});

            localStorage.removeItem('token');
            console.log('response ' + response.data.email)
            this.email = response.data.email;

            AuthService.authenticate(this.email, this.password)
            .then(this.onAuthSucceed , this.onAuthFailed)
        },(error) => {
            this.setState({error_message: 'Falha ao cadastrar a nova senha' , success_message: null});
        });

    }

    onAuthSucceed = (result) =>{
		window.location.assign('/home');
	}

    onAuthFailed = (error) => {
		this.setState({error_message: 'Falha ao logar com a nova senha' , success_message: null});
	}

    render() {
        return (
            <section class="pt-7 pb-12" style={{height: '900px'}}>
                <div class="container">
                    <div class="row">
                        <div class="col-12 text-center">
                            <h3 class="mb-10">Cadastrar nova senha</h3>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12" style={{padding: '2em', paddingTop: 0}}>
                        Olá {this.state.name}, tudo bem? Estamos muito contentes em receber você no nosso canal online de vendas no atacado!<br />
                        •	O seu cadastro já foi realizado, precisamos apenas que você redefina uma nova senha para ter acesso ao site.<br />
                        •	O e-mail escolhido deverá ser utilizado para fazer seu login.
                        </div>
                    </div>
                    <div class="row">

                        <div class="col-12  col-md-6" style={{padding: '2em'}}>

                            <form onSubmit={this.handleSubmit}>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="accountEmail">
                                                Email *
                                            </label>
                                            <input class="form-control form-control-sm" id="accountEmail" type="text" onChange={() => this.handleChangeEmail} placeholder="Email" value={this.state.email} required="" />
                                        </div>
                                        <div class="form-group">
                                            <label for="accountPassword">
                                                Nova Senha *
                                            </label>
                                            <input class="form-control form-control-sm" id="accountPassword" type="password" onChange={this.handleChange('password')} placeholder="Senha"  required="" />
                                        </div>
                                        <div class="form-group">
                                            <label for="confirmPassword">
                                                Confirmar Senha *
                                            </label>
                                            <input class="form-control form-control-sm" id="confirmPassword" type="password" onChange={this.handleChange('password_confirm')} placeholder="Confirmar Senha"  required="" />
                                        </div>

                                    </div>
                                                          
                                    <div class="col-12" style={screen.width >= 1200 ? {paddingTop: '180px'} : {}}>
                                        <button class="btn btn-dark" type="submit">Cadastrar Nova Senha</button><br/>
                                        <span style={{color: 'red' , marginLeft: '10px'}}>{this.state.error_message}</span>
                                        <span style={{color: '#03b6fb' , marginLeft: '10px'}}>{this.state.success_message}</span>
                                    </div>

                                    
                                </div>
                            </form>

                        </div>
                        {
                            screen.width >= 1200 ?
                                <div class="col-12  col-md-6" style={{borderLeft: '1px solid #e5e5e5' , padding: '3em'}}>
                                    <img src="https://b2b.skenn.com.br/img//popup-welcome.png" alt="teste" style={{ width: '540px', height: '540px'}} />
                                    {/* <h4>Cadastrar nova senha</h4>
                                    <p>Instruções de como recuperar sua conta</p> */}
                                </div>

                            :
                                <div className={this.state.authFormOpen ? 'modal fade show' : 'modal fade'} id="modalAuthForm" tabindex="-1" role="dialog" style={{ display: this.state.authFormOpen ? 'block' : 'none', paddingRight: '17px', backgroundColor: 'transparent' }} aria-modal="true">
                                    <div className="modal-dialog modal-dialog-center" role="document" style={{height: '100%' , marginTop: '0px' , maxWidth: '600px'}}>

                                        <div className="modal-content  login-modal">

                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleCloseAuthForm}>
                                                <i className="fe fe-x" aria-hidden="true" style={{color: 'black'}}></i>
                                            </button>

                                            <div className="modal-body row" style={screen.width < 540 ? {paddingTop: '100px'} : {paddingLeft: '100px'}}>
                                                <img src="https://b2b.skenn.com.br/img//popup-welcome.png" alt="teste" style={{ minWidth: '280px', minHeight: '280px', maxWidth: '540px', maxHeight: '540px'}}  />
                                            </div>

                                        </div>

                                    </div>
                                </div>
                        }
                        
                    </div>
                </div>
            </section>
        );
    }
}

export default withRouter(CreatePass);
