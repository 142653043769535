import React from 'react';

import { Redirect, withRouter } from "react-router-dom";

import NavBarMenuCategory from './NavBarMenuCategory';
import ProductService from './ProductService';

class NavBarMenu extends React.Component {


	constructor(props) {
		super(props);

		this.state = {
			categories: [],
			search_query: ''
		}

		this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
	}

	handleSearchSubmit = (e) => {
		e.preventDefault();
		this.props.history.push({
			pathname: '/procurar',
			search: '?q=' + this.state.search_query
		});

	}

	componentDidMount() {

		ProductService.getCategories()
		.then(
			(result) => {
				this.setState({ categories: result.data });
			},
			(error) => {
				console.log(error)
			}
		)
	}



	render() {
		return (
			<div className="navbar navbar-fashion navbar-expand-lg p-3" id="navbar-fashion" style={{zIndex: 998}}>
				<div className="container">

					<button className="navbar-toggler" style={{outline: 'none'}} type="button" data-toggle="collapse" data-target="#navbarFashionBottomCollapse" aria-controls="navbarFashionBottomCollapse" aria-expanded="false" aria-label="Toggle navigation">
						<span className="navbar-toggler-icon"></span>
					</button>

					<form className="navbar-form mr-auto order-lg-1" onSubmit={this.handleSearchSubmit} style={{margin: 'auto', maxWidth: '400px', minWidth: '200px'}}>
						<div className="input-group" style={{backgroundColor: 'white' , padding: '0px 10px'}}>
							<input type="search" onChange={(e) => this.setState({ search_query: e.target.value })} placeholder="O que você procura?" style={{ height: '40px', textAlign: 'end', border: '0px' }}></input>
							<a onClick={this.handleSearchSubmit} href="#" className="ml-5">
								<i className="fe fe-search" style={{color: 'red', position: 'relative', padding: '10px', minWidth: '40px', zIndex: 9999}}></i>	
							</a>
							{/* <div className="input-group-append">
								<button className="btn btn-red" style={{ height: '50px' }}>
									
								</button>
							</div> */}
						</div>
					</form>



					<div className="collapse navbar-collapse" id="navbarFashionBottomCollapse">
						<ul className="navbar-nav" style={{marginRight: '5px'}}>
							<NavBarMenuCategory key={1} category={{id: 1, name: 'Página Inicial', subcategories: [], route: '/home' }}></NavBarMenuCategory>
							<NavBarMenuCategory key={2} category={{id: 2, name: 'Categorias', subcategories: this.state.categories, route: '/procurar' }}></NavBarMenuCategory>
							{/* <NavBarMenuCategory key={3} category={{id: 3, name: 'Novidades da Semana', subcategories: [], route: '/novidades' }}></NavBarMenuCategory> */}

							{/* {this.state.categories.map(function (category, index) {
								return <NavBarMenuCategory key={index} category={category}></NavBarMenuCategory>
							})} */}


							{/*<li className="nav-item">
								<a className="nav-link text-primary" href="https://yevgenysim.github.io/shopper/index-fashion.html#!">Promoções %</a>
							</li>*/}
						</ul>
					</div>

				</div>
			</div>
		)
	}
}

export default withRouter(NavBarMenu);
