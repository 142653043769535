import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash'

import { Redirect, withRouter } from "react-router-dom";

import ProductDetails from './ProductDetails'

import ModalWindow from './ModalWindow'

class ProductQuickView extends React.Component {


	constructor(props) {
		super(props);


	}


	componentDidMount() {

	}

	closeComponent() {
		//ModalWindow.close()
		this.props.modal_window.close();
		//var container = ReactDOM.findDOMNode(this).parentNode;
		//var container = document.getElementById('modal-container');
		//ReactDOM.unmountComponentAtNode(container);
	}

	componentWillUnmount() {
		console.log('Desmontei HUEHUEHUE');
	}

	render() {
		return (
			<div class="modal-dialog modal-dialog-centered modal-xl" role="document">
			  <div class="modal-content">	  
					<button type="button" class="close" onClick={this.closeComponent.bind(this)}>
					<i class="fe fe-x" aria-hidden="true"></i>
					</button>
					<ProductDetails product={this.props.product} hideImages={this.props.hideImages}/>
				</div>
			</div>)
	}
}

export default ProductQuickView;

