const environment = (conditions) => {

  if(conditions[process.env.REACT_APP_ENVIRONMENT]){
    return conditions[process.env.REACT_APP_ENVIRONMENT];
  }
  
  return null;
}

export default environment;
