import React from 'react';

import { Router , Link , Redirect } from "react-router-dom";

import CatalogService from './CatalogService'

class CatalogWindow extends React.Component {


	constructor(props) {
		super(props);

		this.state = {
			catalogs: []
		}
	}


	componentDidMount() {

		CatalogService.getCatalogs(this.props.product_id)
		.then(response => {

			this.selectedCatalogs = response.data.filter( catalog => catalog.has_item ).map(catalog => catalog.id);

			this.setState({catalogs: response.data})
		})

	}

	selectedCatalogs = []
	catalogName = ''

	handleAddClick(e){

		let data = {
			catalogs: this.selectedCatalogs,
			product_id: this.props.product_id
		}

		CatalogService.addProduct(data)
		.then(response => {
			this.props.modal_window.close(false);
		})

		console.log(data)
	}

	handleCloseWindow(e){
		this.props.modal_window.close(false);
	}

	handleCatalogCheckbox(e){

		let catalog_id = parseInt(e.target.value);
		let catalog_index = this.selectedCatalogs.indexOf(catalog_id);

		if(catalog_index === -1){
			this.selectedCatalogs.push(catalog_id);
		}else{
			this.selectedCatalogs.splice(catalog_index, 1);
		}

	}

	handleChangeCatalogName(e){
		this.catalogName = e.target.value;
	}

	handleCreateCatalogClick(){

		CatalogService.createCatalog(this.catalogName)
		.then(response => {
			this.setState({catalogs: response.data})
		});

	}

	render() {
		return (
			<div class="modal-dialog modal-dialog-centered modal-x2" role="document">
			  	<div class="modal-content catalog_modal p-7" style={{ overflowY: 'auto', scrollbarWidth: 'thin'}}>
				  <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleCloseWindow.bind(this)}>
						<i className="fe fe-x" aria-hidden="true"></i>
				</button>
				<h5 className="text-center">Adicionar ao Catálogo</h5>
				<div className="row">
				  	<ul className="catalog_list">
					  	{this.state.catalogs.map(catalog => {
						  	return (<li><input type="checkbox" value={catalog.id} defaultChecked={this.selectedCatalogs.includes(catalog.id)} onChange={this.handleCatalogCheckbox.bind(this)} />{catalog.name}</li>)
					  	})}					  
				  	</ul>
				</div>
				<div className="row mb-5" style={{marginTop: '1px solid black'}}>
					<input type="text" name="catalog_name" onChange={this.handleChangeCatalogName.bind(this)} placeHolder="Novo Catálogo" style={{flex: 1 , paddingLeft: '5px'}}/>
					<button class="btn btn-green" onClick={this.handleCreateCatalogClick.bind(this)}>
						Criar
					</button>
				</div>
				<div className="row">
				  	<button class="btn  btn-block mb-2 btn-green" onClick={this.handleAddClick.bind(this)}>
						Salvar <i class="fe fe-book-open ml-2"></i>
					</button>
				  </div>
				</div>
			</div>	
		)
	}
}

export default CatalogWindow;

