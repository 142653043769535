import React from 'react';

import HomeTopSellers from './HomeTopSellers';

import ProductService from './ProductService';
import HomeProductSection from './HomeProductSection';
import HomeSlider from './HomeSlider';
import HomeLooks from './HomeLooks';
import AuthService from './AuthService';

import './lgpd.css';
import HomeBanners from './HomeBanners';
import HomeCategories from './HomeCategories';
import HomeInstagram from './HomeInstagram';
import WeekNews from './WeekNews';


class Home extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      categories: [],
      looks: [],
      readPrivacyPoliticy: false,
      sections: []
    }
  }

  renderCount = 0;

  componentDidMount() {

    ProductService.getCategorySpotlight()
      .then((response) => {
        this.setState({ categories: response.data })
      })

    ProductService.getSections()
      .then((response) => {
        console.log('sections');
        console.log(response.data);
        this.setState({ sections: response.data });
      })

      AuthService.getUser()
      .then
      (
        () => {
          this.setState({show_price: true})
        },
        () => {
          this.setState({show_price: false})
        }
      )

  }


  render() {

    this.renderCount++;

    return (
      <>
        <HomeSlider />
        <HomeCategories />
        {/* <HomeInstagram /> */}
        <HomeLooks />
        {
          this.state.sections.map((section, index) => {
            return <HomeProductSection key={index} section={section} show_price={this.state.show_price} />;
          })
        }
        <WeekNews />
      </>
    );
  }
}

export default Home;

