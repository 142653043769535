import React from 'react';

import {
  BrowserRouter,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";

import OrderService from '../Orders/OrderService'

class OrderCard extends React.Component {

	constructor(props) {
      super(props);             
	}

  componentWillMount(){

  }

  	render(){
    return (
      <div class="card card-lg mb-5 border">
      <div class="card-body pb-0">


        <div class="card card-sm">
          <div class="card-body bg-light">
            <div class="row">
              <div class="col-6 col-lg-3">


                <h6 class="heading-xxxs text-muted">Pedido</h6>


                <p class="mb-lg-0 font-size-sm font-weight-bold">
                  Cód: {this.props.order.id}
                </p>

                <p class="mb-lg-0 font-size-sm font-weight-bold" style={{display: this.props.order.external_id ? 'block' : 'none'}}>
                  Transação: {this.props.order.external_id}
                </p>

              </div>
              <div class="col-6 col-lg-3">


                <h6 class="heading-xxxs text-muted">Data da Compra:</h6>


                <p class="mb-lg-0 font-size-sm font-weight-bold">{this.props.order.date_creation}</p>

              </div>
              <div class="col-6 col-lg-3">


                <h6 class="heading-xxxs text-muted">Status:</h6>


                <p class="mb-0 font-size-sm font-weight-bold">
                  {this.props.order.status}
                </p>

              </div>
              <div class="col-6 col-lg-3">


                <h6 class="heading-xxxs text-muted">Valor da Compra:</h6>

                { this.props.order.discount === null ?
                <p class="mb-0 font-size-sm font-weight-bold">
                  R$ {this.props.order.subtotal.toFixed(2)}
                </p>
                :
                <p class="mb-0 font-size-sm font-weight-bold">
                  R$ {(this.props.order.subtotal - this.props.order.discount_value).toFixed(2)}
                </p>
                }
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="card-footer">
        <div class="row align-items-center">
          <div class="col-12 col-lg-6">
            <div class="form-row mb-4 mb-lg-0">
              {this.props.order.items.filter((item , i) => { return i <= 2}).map((item) => {
                return(<div class="col-3">
                  <div class="embed-responsive embed-responsive-1by1 bg-cover" style={{backgroundImage: `url( ${process.env.REACT_APP_BASE_URL}${item.grid.cover_file || '/img/no-picture.jpg'}`}}></div>
                </div>)
              })}
              
              	{(() => {
					if(this.props.order.items.length <= 3){
						return;
					}

					return (
					<div class="col-3">
						<div class="embed-responsive embed-responsive-1by1 bg-light">
							<Link  class="embed-responsive-item embed-responsive-item-text text-reset" to={{pathname: `/account/compra/${this.props.order.id}` , order: this.props.order }}>
								<div class="font-size-xxs font-weight-bold">+{this.props.order.items.length-3}</div>
							</Link>
						</div>
					</div>)
              	})()}

            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="form-row">
              <div class="col-6"></div>
              <div class="col-6">


                <Link class="btn btn-sm btn-block btn-outline-dark"  to={{pathname: `/account/compra/${this.props.order.id}` , order: this.props.order }}>
                  Detalhes
                </Link>

              </div>
              {/*<div class="col-6">


                <a class="btn btn-sm btn-block btn-outline-dark" href="#!">
                  Rastrear Pedido
                </a>

              </div>*/}
            </div>

          </div>
        </div>
      </div>
    </div>
    );
  }
}

export default OrderCard;